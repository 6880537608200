import { ActionTypes } from "../constants/action-types";

export const setNft = (nft) => {
  return {
    type: ActionTypes.SET_NFT,
    payload: nft,
  };
};
export const set = (lazynft) => {
  return {
    type: ActionTypes.SET_LazyNFT,
    payload: lazynft,
  };
};
export const setLazyV2 = (lazynftV2) => {
  return {
    type: ActionTypes.lazynftV2,
    payload: lazynftV2,
  };
};
export const setUsdRate = (data) => {
  return {
    type: ActionTypes.SET_USD_RATE,
    payload: data,
  };
};

export const setBalance = (data) => {
  return {
    type: ActionTypes.SET_BALANCE,
    payload: data,
  };
};

export const setFNft = (fnft) => {
  return {
    type: ActionTypes.SET_FNFT,
    payload: fnft,
  };
};

export const selectedNft = (nft) => {
  return {
    type: ActionTypes.SELECTED_NFT,
    payload: nft,
  };
};

export const removeSelectedNft = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_NFT,
  };
};

export const selectedLazyNft = (lazynft) => {
  return {
    type: ActionTypes.SELECTED_LazyNFT,
    payload: lazynft,
  };
};

export const removeSelectedLazyNft = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_LazyNFT,
  };
};

export const selectedLazyNftV2 = (lazynftV2) => {
  return {
    type: ActionTypes.SELECTED_LazyNFTV2,
    payload: lazynftV2,
  };
};

export const removeSelectedLazyNftV2 = () => {
  return {
    type: ActionTypes.REMOVE_SELECTED_LazyNFTV2,
  };
};

export const selectedFNft = (nft) => {
  return {
    type: ActionTypes.SELECTED_FNFT,
    payload: nft,
  };
};

export const removeSelectedFNft = () => {
  return {
    type: ActionTypes.REMOVE_FSELECTED_NFT,
  };
};


export const setAccount = (account) => {
  return {
    type: ActionTypes.SET_ACCOUNT,
    payload: account,
  };
};

export const setIsConnected = (status) => {
  return {
    type: ActionTypes.SET_IS_CONNECTED,
    payload: status,
  };
};

export const setTokenContract = (tokenContract) => {
  return {
    type: ActionTypes.SET_TOKEN_COTRACT,
    payload: tokenContract,
  };
};

export const setMarketContract = (marketContract) => {
  return {
    type: ActionTypes.SET_MARKET_CONTRACT,
    payload: marketContract,
  };
};
export const setMarketContractGasLess = (marketLazyContract) => {
  return {
    type: ActionTypes.SET_MARKET_CONTRACT_GAS_LESS,
    payload: marketLazyContract,
  };
};
export const setMarketContractGasLessV2 = (marketLazyContractV2) => {
  return {
    type: ActionTypes.SET_MARKET_CONTRACT_GAS_LESSV2,
    payload: marketLazyContractV2,
  };
};
export const setfractionalizerContract = (fractionalizerContract) => {
  return {
    type: ActionTypes.SET_FRACTIONALIZER_CONTRACT,
    payload: fractionalizerContract,
  };
};
export const setfractionsImplContract = (fractionsImplContract) => {
  return {
    type: ActionTypes.SET_FRACTIONSIMPL_CONTRACT,
    payload: fractionsImplContract,
  };
};

export const setfractionalizeNFTContract = (FractionalizeNFTContract) => {
  return {
    type: ActionTypes.SET_FRACTIONALIZENFT_CONTRACT,
    payload: FractionalizeNFTContract,
  };
};