import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
//import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import { useStyles } from "./styles.js";
import { cssStyles } from "./styles.css";
import Web3 from "web3";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import DropZone from "../../components/DropZone";
import { ethers } from "ethers";
import { api, defaultNetworkCurrencySymbolIcon } from "../../services/api";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Loader from "../../components/Loader/Loader";
import PreViewNft from "../../components/PreviewNft/index";
import { InputGroup } from "react-bootstrap";
import successIco from "../../assets/success.png";
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Resizer from "react-image-file-resizer";
import $ from 'jquery';
import getWeb3 from "../../utils/getWeb3";
import { property } from "lodash";
import Select from 'react-select';
import { uuid } from 'uuidv4';
import Swal from 'sweetalert2'
const { LazyMinter } = require("../../lib");

const CreateGaslessNFT = () => {
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const classes = useStyles();
  const history = useHistory();
  const [tokenid, setTokenid] = useState([]);
  const [allCatagories, setAllCatagories] = useState([]);

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const account = sessionStorage.getItem('myAccount');

const artMarketplaceContract = useSelector(
    (state) => state.allNft.marketplaceContract
);
const marketLazyContract = useSelector(
    (state) => state.allNft.marketLazyContract
);
  const [selectedFile, setSelectedFile] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isload, setIsload] = useState(false);
  const [categoryID, setCategoryID] = useState('0');
  const [collectionSlug, setCollectionSlug] = useState('');
  
  const [processType, setProcessType] = useState('');
  const [isShowCreateFrom, setIsShowCreateFrom] = useState(false);
  const [isCollectable, setIsCollectable] = useState(false);
  const [myAllCollection, setMyAllCollections] = useState([]);
  const [propertyName, setPropertyName] = useState('');
  const [propertyValue, setPropertyValue] = useState('');
  const [propertyList, setPropertyList] = useState({});
  
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    address: "",
    categoryID: "0"
  });
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {

    const init = async () => {
      try {
        if (allCatagories.length === 0) {
          const responses = await api
            .get(`/getCategories/`)
            .catch((err) => {
              console.log("Err: ", err);
            });
          let allCat = Object.keys(responses.data).map((key) => responses.data[key]);
          
          let allCatData = [];
          if(Object.keys(allCat).length){
            Object.keys(allCat).map((key) => (
              allCatData.push({value: allCat[key].categoryID, label: allCat[key].category})
            ));
          }
          setAllCatagories(allCatData);
          
        }

        let myacc = account;
        if(account != ''){
            myacc = account;
        }else{
            const web3 = await getWeb3();
            let accounts = await web3.eth.getAccounts();
            myacc = accounts[0];
        }

        const collections = await api
                .get(`/getUserwiseCollection/${myacc}`)
                .catch((err) => {
                    console.log("Err: ", err);
                });
        let allCollection = Object.keys(collections.data).map((key) => collections.data[key]);
        console.log(allCollection);
        let collectionOptions = [];
        if(Object.keys(collections.data).length){
          Object.keys(allCollection).map((key) => (
            collectionOptions.push({value: allCollection[key].slag, label: allCollection[key].collectionName})
          ));
        }
        setMyAllCollections(collectionOptions);
        
      // const marketLazyContract = new web3.eth.Contract(
      //   LazyNFT.abi,
      //   LazyNFT.networks[networkId].address
      // );
      // console.log(marketLazyContract);
      // dispatch(setMarketContract(marketLazyContract));
      } catch (error) {
        setIsload(false);
        console.error("Error,single: ", error);
      }
    }

    init();

  }, []);

  async function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }

  const handleChangeCategory = (selectedOption) => {
    setCategoryID(selectedOption.value);
  };

  const handleChangeCollection = (selectedOption) => {
    
    setCollectionSlug(selectedOption.value);
  };

  

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  async function createNFTData(event) {
    setIsload(true);
    event.preventDefault();
    const { title, description, price } = formData;
    // const web3 = await getWeb3();
    // let accounts = await web3.eth.getAccounts();
    let myacc = account;

    console.log("title: " + title);
    //  let description = convertedContent;

    const data = new FormData();
    data.append("name", title);
    data.append("description", description);
    data.append("saleId", null);
    data.append("price", Web3.utils.toWei(String(price), "ether"));
    data.append("seller", myacc);
    data.append("owner", myacc);
    data.append("creator", myacc);
    data.append("isForSale", true);
    data.append("isSold", false);
    data.append("categoryID", categoryID);
    data.append("collectionName", collectionSlug);
    data.append("properties", JSON.stringify(propertyList));
    
    
    //data.append("processType", processType);


    if (selectedFile) {
      data.append('img', selectedFile);
      console.log("slectedFile: ", selectedFile);
    }
    try {

      // const totalSupply = await artMarketplaceContract.methods.GetItemsCreated().call();
      const allSupply = await api
        .get(`/tokens/`)
        .catch((err) => {
          console.log("Err: ", err);
        });

      let total = Object.keys(allSupply.data).length;

      let tokenIDUUID = uuid();

      data.append("tokenId", tokenIDUUID);

      // if (processType == "gasless") {
      //   data.append("isMinted", false);
      // } else if (processType == "traditional") {
      //   data.append("isMinted", true);
      // }

      let count = Number(total) + 1;
      setTokenid(tokenIDUUID);
      const response = await api.post("/gaslesstokens", data, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      });
      debugger;
      let message = {
        tokenId: response.data.tokenId,
        price: Web3.utils.toWei(String(price), "ether"),
        uri:response.data.message[0]
      
      }
      debugger;
      // const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
   
    const signer = provider.getSigner(account);
    // const signature = await signer._signTypedData(domain, types, voucher)
    // console.log(signature);

    // let factory = await ethers.getContractFactory("TheBitLab", signer)
    // const contractsign = await factory.deploy(minter.address)
    const contractsign=await marketLazyContract.methods.getLazyContractAddress().call();
  

      const lazyMinter = new LazyMinter({
        contract:marketLazyContract,
        signer: signer,
      });
      let minPrice = Web3.utils.toWei(String(price), "ether");
      const voucher = await lazyMinter.createVoucher(response.data.tokenId, response.data.message[0], minPrice)
      
      // const sig = await signMessage({
      
      // message: message
      // });
      debugger;
       let datademo = { tokenId: tokenIDUUID,voucher:voucher,signature:voucher.signature };
      const voucherStatusupdate = await api.post("/voucherUpdate", datademo, {
        headers: {
          "Content-Type": `application/json; boundary=${data._boundary}`,
        },
      });
      
      let updateMintingStatus = { tokenId: response.data.tokenId };
      const updateStatusAfterMinting = await api.post("/updateStatusAfterMinting", updateMintingStatus, {
        headers: {
          "Content-Type": `application/json; boundary=${data._boundary}`,
        },
      });
      console.log("updateStatusAfterMinting", updateStatusAfterMinting);
      
      console.log(response);
      //mint(response.data.message[0], description, response.data.message[1], title, Web3.utils.toWei(String(price), "ether"), response.data.tokenId, myacc, tokenIDUUID);
      // if (processType == "gasless") {
        setIsload(false);
        history.push('/');
      //   window.location.reload(true);
      // } else if (processType == "traditional") {
      //   mint(response.data.message[0], description, response.data.message[1], title, Web3.utils.toWei(String(price), "ether"), count);
      // }

    } catch (error) {
      console.log(error);
      // error.response.data

    }
  }

 


  
  
  // async function createVoucher(tokenId, uri, minPrice = 0) {
  //   debugger;
  //   const voucher = { tokenId, uri, minPrice }
  //     const totalItemsForSale = await marketLazyContract.methods
  //   .getcontractAddress()
  //   .call();
  //   const domain = {
  //     name: "The BITLAB",
  //     version: "2",
  //     verifyingContract: totalItemsForSale,
  //     chainId:"4",
  //   }
  //   const types = {
  //     NFTVoucher: [
  //       {name: "tokenId", type: "uint256"},
  //       {name: "minPrice", type: "uint256"},
  //       {name: "uri", type: "string"},  
  //     ]
  //   }
  //   const provider = new ethers.providers.Web3Provider(window.ethereum);
   
  //   const signer = provider.getSigner();
  //   const signature = await signer._signTypedData(domain, types, voucher)
  //   console.log(signature);

  //   let factory = await ethers.getContractFactory("TheBitLab", signer)
  //   const contract = await factory.deploy(minter.address)

  

  //   return {
  //     ...voucher,
  //     signature,
  //   }
  // }

//   const signMessage = async ({ setError, message }) => {
//   try {
//     console.log({ message });
//     if (!window.ethereum)
//       throw new Error("No crypto wallet found. Please install it.");
//     debugger;
//     await window.ethereum.send("eth_requestAccounts");
//     const provider = new ethers.providers.Web3Provider(window.ethereum);
//     let s = JSON.stringify(message)
//     //const m = new ethers.utils.hashMessage(s);
//     const signer = provider.getSigner();
//     const signature = await signer.signMessage(s);
//     const address = await signer.getAddress();

//     return {
//       message,
//       signature,

//       address
//     };
//   } catch (err) {
//     setError(err.message);
//   }
// };

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileUrl(URL.createObjectURL(event.target.files[0]));
    setIsSelected(true);
    // try {
    //   

    //     const file = event.target.files[0];
    //     const image = await resizeFile(file);
    //   

    //     setSelectedFile(image);
    //     setFileUrl(URL.createObjectURL(event.target.files[0]));
    //     setIsSelected(true);
    //     console.log(image);
    //   } catch (err) {
    //     console.log(err);
    //   }
  };

  const removeImage = () => {
    setIsSelected(false);
    setFileUrl('');
  }

  const tabActiveDeactive = (value) => {
    $('.ctab-heading').removeClass('active');
    $('.tabDetails-item').removeClass('active');

    if (value == "NFT") {
      $('.Ntab-heading').addClass('active');
      $('.NtabDetails-item').addClass('active');
    } else if (value == "FNFT") {
      $('.Ftab-heading').addClass('active');
      $('.FtabDetails-item').addClass('active');
    }
  }

  const processTypeChange = (type) => {

    $('.type-bx').removeClass('active');
    if (type == "gasless") {
      $('.gasless').addClass('active');
    } else if (type == "traditional") {
      $('.traditional').addClass('active');
    }
    setProcessType(type);
  }

  const continueProcess = () => {
    setIsShowCreateFrom(true);

  }

  const backtoProcess = () => {
    setIsShowCreateFrom(false);
    setProcessType("");
  }






  async function mint(tokenMetadataURL, description, images, name, price, tokenId, myacc, tokenIDUUID) {
    
    try {


      console.log(tokenMetadataURL);
      console.log(description);
      console.log(images);
      console.log(name);
      console.log(price);
      console.log(tokenId);
      const receipt = await artMarketplaceContract.methods

        .Createmint(tokenMetadataURL, description, images, name, price, tokenId)
        .send({ from: myacc, gas: 1200000 });
      console.log(receipt);
      console.log(receipt.events.Transfer.returnValues.tokenId);
      
      var receiptData = {
        tokenId: receipt.events.Transfer.returnValues.tokenId,
        blockhash: receipt.blockHash,
        from: receipt.from,
        to: receipt.to,
        transactionHash: receipt.transactionHash,
        action: "Minted"

      }
      const transactionHistory = await api.post("/nfttransactionhistory", receiptData, {
        headers: {
          "Content-Type": `application/json; boundary=${receiptData._boundary}`,
        },
      });
      console.log("transaction", transactionHistory);
      let data = { tokenId: tokenId };
      const updateStatusAfterMinting = await api.post("/updateStatusAfterMinting", data, {
        headers: {
          "Content-Type": `application/json; boundary=${data._boundary}`,
        },
      });
      console.log("updateStatusAfterMinting", updateStatusAfterMinting);


      setIsload(false);
      Swal.fire({
        title: '<strong>Mint successfully completed!</strong>',
        icon: 'success',
        showCloseButton: false,
        showCancelButton: false
      }).then((result) => {
          history.push('/mynft');
      });
      // window.location.reload();

    } catch (error) {
      // const totalSupply = await artMarketplaceContract.methods.GetItemsCreated().call();
      // let count = Number(totalSupply) + 1
      // let data = { tokenID: tokenIDUUID }
      // const deleteres = await api.post("/deleteNFT", data, {
      //   headers: {
      //     "Content-Type": `application/json; boundary=${data._boundary}`,
      //   },
      // });

      // console.log("deleted", deleteres);
      // console.error("Error, minting: ", error);

      alert("Error while minting!");

      setIsload(false);
    }
  }

  function changeDescription(data) {
    console.log(data);
  }

  function changeCollectable(event){
    
    let isChecked =  event.target.checked;
    setIsCollectable(isChecked);
  }
  function openPopertyDiv(){
    $('.properties-div').toggleClass('active');
    $('.toggle-div').toggleClass('active');
  }
  function propertyNameHandeler(event){
    let { value } = event.target;
    setPropertyName(value);
  }
  function propertyValHandeler(event){
    let { value } = event.target;
    setPropertyValue(value);
  }
  function addPrpperty(){
    if(propertyName != '' && propertyValue != ''){
      let obj = {};
      obj[propertyName] = propertyValue;
      let newPoperty = { ...propertyList, ...obj };
      setPropertyList(newPoperty);
      console.log(propertyList);
      setPropertyName('');
      setPropertyValue('');
    }
  }



  return (
    <div className={classes.pageCreateNft}>
      {isload ? <Loader /> : <span></span>}
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-7">
            <div className="customTab clearfix">
              <div className="customTab-heading">
                <span className="ctab-heading Ntab-heading active" onClick={() => tabActiveDeactive('NFT')}>
                  <img src={successIco} /> NFT
                </span>
                <span className="ctab-heading Ftab-heading" onClick={() => tabActiveDeactive('FNFT')}>
                  <Link to="/make-fnft"><img src={successIco} /> FNFT </Link>
                </span>
              </div>
              <div className="tab-details">
                <div className="tabDetails-item NtabDetails-item active">
                  {/* {!isShowCreateFrom ?
                  <div className="process">
                    <h2>Gasless or Traditional Minting?</h2>
                    <div className="processType">
                      <div className="type">
                        <div className="gasless type-bx" onClick={() => processTypeChange('gasless')}>
                          <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M5.5 48.5H8.5M41.5 48.5H38.5M8.5 48.5V10C8.5 7.79086 10.2909 6 12.5 6H34.5C36.7091 6 38.5 7.79086 38.5 10V48.5M8.5 48.5H38.5" stroke="white" stroke-width="2" stroke-linecap="round"/>
                          <path d="M14.5 22V15.5C14.5 14.3954 15.3954 13.5 16.5 13.5H30.5C31.6046 13.5 32.5 14.3954 32.5 15.5V22C32.5 23.1046 31.6046 24 30.5 24H16.5C15.3954 24 14.5 23.1046 14.5 22Z" stroke="white" stroke-width="2"/>
                          <path d="M38.5 33C38.5 40.5 49 41.5 49 33C49 27 49 25 49 18.5C49 15.5 48 15.5 43.5 12" stroke="white" stroke-width="2" stroke-linecap="round"/>
                          <line x1="10.0858" y1="45.5858" x2="46.0858" y2="9.58579" stroke="white" stroke-width="4"/>
                          <rect x="2.5" y="2" width="50" height="50" rx="25" stroke="white" stroke-width="2"/>
                          </svg>
                          <h3>Gasless</h3>
                          <p>(No transaction needed)</p>
                        </div>
                      </div>
                      <div className="type">
                        <div className="traditional type-bx" onClick={() => processTypeChange('traditional')}>
                          <svg width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.5 48.5H8.5M41.5 48.5H38.5M8.5 48.5V10C8.5 7.79086 10.2909 6 12.5 6H34.5C36.7091 6 38.5 7.79086 38.5 10V48.5M8.5 48.5H38.5" stroke="#2D2D2D" stroke-width="2" stroke-linecap="round"/>
                            <path d="M14.5 22V15.5C14.5 14.3954 15.3954 13.5 16.5 13.5H30.5C31.6046 13.5 32.5 14.3954 32.5 15.5V22C32.5 23.1046 31.6046 24 30.5 24H16.5C15.3954 24 14.5 23.1046 14.5 22Z" stroke="#2D2D2D" stroke-width="2"/>
                            <path d="M38.5 33C38.5 40.5 49 41.5 49 33C49 27 49 25 49 18.5C49 15.5 48 15.5 43.5 12" stroke="#2D2D2D" stroke-width="2" stroke-linecap="round"/>
                          </svg>
                          <h3>Traditional</h3>
                          <p>(Transaction needed)</p>
                        </div>
                      </div>
                    </div>
                    <div className="continue-btn">
                      <button onClick={continueProcess} className="default-btn" disabled={processType == ""} type="button">Continue &#8594;</button>
                    </div>
                  </div>
                  : <span></span>} */}
                  {/* {isShowCreateFrom ? */}
                  <form className="form-style-9" onSubmit={createNFTData}>
                    {/* <button onClick={backtoProcess} type="button" className="backto-process"> &#8592; Back to minting option</button> */}
                    <h3>Create Gasless Nft</h3>
                    <ul>
                      <li>
                        <div className={classes.dropzone}>
                          {/* <DropZone name="NFT image" onFileUploaded={setSelectedFile} /> */}
                          {!isSelected ? (
                            <div>
                              <input
                                type="file"
                                onChange={changeHandler}
                                accept="image/*"
                                style={{ display: 'none' }}
                                name="file"
                                id="contained-button-file"
                              />
                              <label htmlFor="contained-button-file" className="button-file">
                                <span>JPG, JPEG, PNG, GIF, WEBP Max 100mb.</span>
                                <p className="button-file-upload" style={{ marginBottom: '0', cursor: 'pointer' }}>
                                  Choose File
                                </p>
                              </label>
                            </div>
                          ) : (
                            <div className="uploaded-image">
                              <span onClick={removeImage} className="cross" >&times;</span>
                              <img style={{ height: '200px', width: '100%' }} src={fileUrl} />
                            </div>
                          )}
                        </div>
                      </li>
                      <li>
                        <input
                          name="title"
                          required
                          value={formData.title}
                          onChange={handleInputChange}
                          type="text" className="field-style" placeholder="Title" />
                      </li>
                      <li>
                        <textarea
                          id="outlined-multiline-static"
                          rows="4"
                          label="Description"
                          name="description"
                          required
                          value={formData.description}
                          onChange={handleInputChange} className="field-style" placeholder="Description"></textarea>
                      </li>
                      <li>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="basic-addon1">ETH</span>
                          </div>
                          <input label="price" name="price" value={formData.price} onChange={handleInputChange} type="number" className="field-style priceinput" placeholder="Price" aria-label="Price" aria-describedby="basic-addon1" />
                        </div>

                      </li>
                      {/* <li>
                        <label className="labelClass">Description</label>
                        <Editor editorClassName="editor-class" editorState={editorState} onEditorStateChange={handleEditorChange} />
                      </li> */}

                    <li>
                      {/* <label className="labelClass">Category</label> */}
                      {/* <select
                        className="field-style"
                        name="categoryID"
                        onChange={handleChangeCategory}
                      >
                        <option value="0">Select Category</option>

                        {Object.keys(allCatagories).map((key) => (
                          <option key={'cat' + key} value={allCatagories[key].categoryID}>{allCatagories[key].category}</option>
                        ))}
                      </select> */}
                      <Select
                        options={allCatagories}
                        onChange={handleChangeCategory}
                        placeholder="Select Category"
                      />

                    </li>

                    <li>
                      {/* <label className="collectableCheck">
                        <input
                          name="collectable"
                          type="checkbox"
                          onChange={changeCollectable} /> Is collectable?
                      </label>
                      { isCollectable ? (
                        
                      ):("")} */}
                      <Select
                        options={myAllCollection}
                        onChange={handleChangeCollection}
                        placeholder="Select Collection"
                      />
                        {/* <select
                          className="field-style"
                          name="categoryID"
                          onChange={handleChangeCollection}
                        >
                          <option value="0">Select Collection</option>

                          {Object.keys(myAllCollection).map((key) => (
                            <option key={'collec-' + key} value={myAllCollection[key].slag}>{myAllCollection[key].collectionName}</option>
                          ))}
                        </select> */}
                      </li>
                      <li>
                        <div className="details-toggle">
                          <div className="toggle-div" onClick={openPopertyDiv}>
                            Properties 
                            <div className="opener">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                          <div className="properties-div">
                            <div className="properties-list clearfix">
                              {Object.keys(propertyList).map((key) => (
                                <div key={'poperty-' + key} className="properties-li-item">
                                  <div className="properties-li-item-inner">
                                    <span>{key}</span>
                                    <p>{propertyList[key]}</p>
                                  </div>
                                </div>
                              ))}
                            </div>
                            
                            <input type="text" onChange={propertyNameHandeler} value={propertyName} name="propertyname" placeholder="Name" className="property-name" />
                            <input style={{margin: '0 5px'}} type="text" onChange={propertyValHandeler} value={propertyValue} name="propertyValue" placeholder="Value" className="property-name" />
                            <button type="button" className="btn" onClick={addPrpperty}>+ Add Property</button>
                          </div>
                        </div>
                      </li>
                      <li>
                        <button className="default-btn" type="submit">Submit</button>
                      </li>
                    </ul>
                  </form>
                  {/* // : <span></span>} */}
                </div>
                {/* <div className="tabDetails-item FtabDetails-item">
                  <form className="form-style-9" onSubmit={createNFTData}>
                    <h3>Create Fractional NFT</h3>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="previewSec">
              <h3>Preview NFT</h3>
              <PreViewNft owner={account} image={fileUrl} name={formData.title} price={formData.price}></PreViewNft>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
};

export default CreateGaslessNFT;
