import React from "react";
import { styles } from './styles.css';

const Careers = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="page-content">
                        <h1>Careers</h1>
                        <p>
                            Mail Us: <a href="mailto:jobs@metawirks.com">jobs@metawirks.com</a>
                        </p>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            
        </div>
    )
}
export default Careers;