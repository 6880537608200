import React, { Component }  from 'react';
import loaderIco from "../../assets/loader.gif";
import { mystyles } from "./styles.css";
function Loader() {
  return (
    <div className="loader">
      <div className="loader-inner">
        <img src={loaderIco} />
        <p>Please Wait...</p>
      </div>
    </div>
  );
}

export default Loader;
