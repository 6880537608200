import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  

  footerLink: {
    listStyle: 'none',
    padding: '0',
    '& li': {
        marginTop: '5px',
        fontSize: '16px',
        '& a': {
            color: '#fff',
            textDecoration: 'none',
        }
      },
    
  }
 
});

export { useStyles };