import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Fractionalizer from "../../contracts/Fractionalizer.json";

import getWeb3 from "../../utils/getWeb3";
import { api } from "../../services/api";


import nodatafound from '../../assets/nodatafound.png';

import NftCard from "../../components/NftCard";

import { useStyles } from "./styles.js";
import { Styles } from "./styles.css";






const CategoryWiseNFT = () => {
  
    

    const { categoryID } = useParams();
    const classes = useStyles();
    const [howManyLoad, sethowManyLoad] = useState(8);
    const [allNftData, setAllNftData] = useState([]);
    const [categoryData, setCategoryData] = useState("");

    

    
  
    const init = async () => {
        
        try {
            
            if(allNftData.length === 0){
                const responses = await api
                .get(`/categoryWise/`+ categoryID)
                .catch((err) => {
                    console.log("Err: ", err);
                });
                let allCatNft = Object.keys(responses.data).map((key)=> responses.data[key])
                setAllNftData(allCatNft);
                console.log("responses cat array Array2: ", allCatNft);
            }
            if(categoryData == ""){
                const getcategoyData = await api
                .get(`/singleCategory/`+ categoryID)
                .catch((err) => {
                    console.log("Err: ", err);
                });
                
                setCategoryData(getcategoyData.data);
                console.log(getcategoyData);
            }

            
        } catch (error) {
        
        }
    };
    
    init();

  async function sortByChange(event) {
    // setSortBy(event.target.value);
    
    // if(event.target.value === "price-asc"){
    //     let allNft = allNftData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
    //     setAllNftData(allNft);
    //     console.log(allNft);
    // }else if(event.target.value === "price-desc"){
    //     let allNft = allNftData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
    //     setAllNftData(allNft);
    //    console.log( allNft);
    // }
  };

  return (
    <section className="explore-section">
      <div className={classes.allNftPage}>
          <div className="filter-sec">
              <div className="page-header">
                  <div className="container">
                      <div className="row">
                          <div className="banner__Explore">
                              <h1>{categoryData.category} NFT<small>s</small></h1>
                          </div>
                      </div>
                  </div>
              </div>
              <div className="container">
                  {/* <div className="row" style={{ alignItems:'center', paddingBottom: "10px", borderBottom:'1px solid rgb(218 218 218)' }}>
                      <div className="col-md-3">
                          <select  value={sortBy} onChange={sortByChange} className="form-select" aria-label="Default select example">
                              <option value="all" defaultValue="all">Sort By</option>
                              <option value="price-asc">Price Asc.</option>
                              <option value="price-desc">Price Desc.</option>
                          </select>
                      </div>
                      <div className="col-md-6">
                          <div style={{ width: '100%' }}>
                              <div>
                                  <div>
                                  <form onSubmit={handleSubmit} className={classes.search}>
                                      <input value={name} onChange={e => setName(e.target.value)} type="text" className={classes.searchTerm} placeholder="Search By Title" />
                                      <button type="submit" className={classes.searchButton}>
                                          <svg style={{ height: '17px' }} className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g className="search-path" fill="none" stroke="#fff"><path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>
                                      </button>
                                  </form>
                                  
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-md-3">
                          <h3 className="counting">Total Items: {allNftData.length}</h3>
                      </div>
                  </div> */}
                  {/* <hr style={{margin: "0", marginTop: '10px'}} /> */}
              </div>
              
          </div>
          
          
        
        {Object.keys(allNftData).length > 0 ? (
          
              <div className="container">
                  {/* <div className="row">
                      <div className="col-12">
                          <div className="section-header">
                              <h3>NFT</h3>
                          </div>
                      </div>
                  </div> */}
      
                  <div className="row explore-content-section">
                        {allNftData.map((nft) => (
                            <NftCard item key={nft.tokenId} {...nft}></NftCard>
                        ))}

                        {/* {isLoadMoreBtnShow ? (
                          <div className="col-lg-12">
                            <span className="btn-main lead m-auto" onClick={loadMoreNft}>Load More</span>
                          </div>
                        ):(
                          <span></span>
                        )} */}
                  </div>
              </div>
          ):(
            <section className="explore-section">
              <div className="container">
                <img src={nodatafound} />
              </div>
            </section> 
          )}


      </div>
    </section>
  );
};

export default CategoryWiseNFT;


