import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Web3 from "web3";
import { selectedFNft } from "../../redux/actions/nftActions";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useStyles } from "./styles.js";
import { cssStyles } from "./styles.css";
import Loader from "../../components/Loader/Loader";
import getWeb3 from "../../utils/getWeb3";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import LatestNft from "../../components/LatestNft/LatestNft";
import { ethers } from 'ethers';
import Web3Modal from "web3modal"
import Market from '../../contracts/ArtMarketplace.json';
import axios from 'axios';
import userIco from '../../assets/user.png';
import ArtMarketplace from "../../contracts/ArtMarketplace.json";

import FractionalizeNFT from "../../contracts/FractionalizeNFT.json";

// import Fractionalizer from "../../contracts/Fractionalizer.json";
import { api, imgBaseUrl, defaultAccount } from "../../services/api"
import OwnerPercentage from '../../_pipe/ownerPercentage';
import ProfileImageGet from '../../_pipe/profileImageGet';
import $ from 'jquery';
import {
  setMarketContract
} from "../../redux/actions/nftActions";
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import DataTable from 'react-data-table-component'
import { CopyToClipboard } from "react-copy-to-clipboard";
import { MdContentCopy } from "react-icons/md";
import Swal from 'sweetalert2';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const ItemFNFT = () => {

  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded');
  const acc = sessionStorage.getItem('myAccount');
  let usdRate = useSelector((state) => state.allNft.usdRate);
  const myBalance = useSelector((state) => state.allNft.balance);

  window.scrollTo(0, 0);
  const classes = useStyles();
  const history = useHistory();
  const { nftId } = useParams();
  const { ownerAddress } = useParams();
  const [isCopied, setIsCopied] = useState(false);
  const [isload, setIsload] = useState(false);
  let [profileData, setProfileData] = useState(true);
  let [uri, setUri] = useState('');
  let [account, setAccount] = useState(acc);
  let [profileDataCreator, setprofileDataCreator] = useState(true);
  let [fnftItem, setFNftItemData] = useState({});
  const [formData, setFormData] = useState({
    fractionAmount: '0',
  });
  let [totalBalance, setTotalBalance] = useState(myBalance);
  let [fnftOwnerList, setFnftOwnerList] = useState([]);
  const [alertTitle, setalertTitle] = useState('');
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [fractionData, setFractionData] = useState({});

  const [userData, setuserData] = useState(false);
  let [thisNFTWoner, setThisNFTWoner] = React.useState([]);




  //const [nftItemState, setNftItemState] = useState({});
  const dispatch = useDispatch();




  //let nft = useSelector((state) => state.nft);
  //console.log("dsadasdasdasdasdasdasdasdasdsadsad0", nft);

  const {
    createDate,
    description,
    fractionPrice,
    fractionsCount,
    fractionsCountInitial,
    image,
    imageLocal,
    name_,
    owner,
    erc20Address,
    fnftId,
    symbol_,
    target,
    tokenId,
    ownerList
  } = fnftItem;



  useEffect(() => {

    const init = async () => {
      try {

        const responses = await api
          .get(`/singlefnft/` + nftId)
          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("responses Array: ", responses);
        dispatch(selectedFNft(responses.data));
        setFNftItemData(responses.data);
        let newOwnerList = [];
        if (responses.data) {
          newOwnerList = Object.keys(responses.data.ownerList).map((key) => responses.data.ownerList[key]);
          for (let i = 0; i < newOwnerList.length; i++) {
            if (ownerAddress == newOwnerList[i].to) {
              setFractionData(newOwnerList[i]);
            }
          }

          setFnftOwnerList(newOwnerList);
          console.log("newOwnerList");
          console.log(newOwnerList);
        }

        setUri(responses.request.responseURL);
        const web3 = await getWeb3();
        let myaccount = await web3.eth.getAccounts();
        setAccount(myaccount[0]);
        console.log("myaccount", myaccount);
        const networkId = await web3.eth.net.getId();
        // const marketplaceContract = new web3.eth.Contract(
        //   ArtMarketplace.abi,
        //   ArtMarketplace.networks[networkId].address
        // );
        // dispatch(setMarketContract(marketplaceContract));

        // const fractionalizerContract = new web3.eth.Contract(
        //   Fractionalizer.abi,
        //   Fractionalizer.networks[networkId].address
        // );

        setIsload(false);
      } catch (error) {
        console.error("Error: ", error);
        setIsload(false);

      }
      // if (nftId && nftId !== "" && nftItem.length){
      //   setIsload(false);
      //   dispatch(selectedNft(nftItem));
      // }
    }

    init();

    // 
    // setIsload(false);
    // if (nftId && nftId !== "" && nftItem.length){
    //   setIsload(false);
    //   dispatch(selectedNft(nftItem));
    // }


  }, [nftId]);

  useEffect(() => {

    const getOwnerProfile = async () => {
      try {
        const acc = fnftItem.owner;
        console.log("owner", acc)
        const response = await api
          .get(`/saveUser/${acc}`)

          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("ownerall", response)
        if (response.data != null) {
          let data = response.data;
          setProfileData(data);
        }




      } catch (error) {

      }
    };
    getOwnerProfile();

    const getCreatorProfile = async () => {
      try {
        // const acc = fnftItem.creator;
        const acc = fnftItem.owner;
        console.log("creator", acc)
        const response = await api
          .get(`/saveUser/${acc}`)

          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("creatoral", response)
        if (response.data != null) {
          let data = response.data;
          setprofileDataCreator(data);
        }




      } catch (error) {

      }
    };
    getCreatorProfile();

    const getHistory = async () => {
      const web3 = await getWeb3();
      const networkId = await web3.eth.net.getId();

      const fractionalizeNFTContract = new web3.eth.Contract(
        FractionalizeNFT.abi,
        FractionalizeNFT.networks[networkId].address
      );

      let historyObj = [];

      fractionalizeNFTContract.events.Transfer({
        filter: {},
        fromBlock: 0
      }, function (error, event) {
        if (error == null) {
          historyObj.push(event.returnValues);
        }

      });

      setThisNFTWoner(historyObj);
    }
    getHistory();

  }, [fnftItem]);


  useEffect(() => {
    const getHistory = async () => {
      const web3 = await getWeb3();
      const networkId = await web3.eth.net.getId();

      const fractionalizeNFTContract = new web3.eth.Contract(
        FractionalizeNFT.abi,
        FractionalizeNFT.networks[networkId].address
      );

      let historyObj = [];

      fractionalizeNFTContract.events.Transfer({
        filter: {},
        fromBlock: 10
      }, function (error, event) {
        if (error == null) {
          if (event.returnValues.erc20Address == erc20Address) {
            let dataObject = {
              from: event.returnValues._from,
              to: event.returnValues._to,
              amount: event.returnValues._amount,
            }
            historyObj.push(dataObject);
          }

        }

      });



      setThisNFTWoner(historyObj);
    }
    getHistory();

  }, [fnftItem]);


  useEffect(() => {
    const balanceInit = async () => {
      const web3 = await getWeb3();
      let myaccounts = await web3.eth.getAccounts();
      if (myaccounts.length) {
        let myAcc = myaccounts[0];
        web3.eth.getBalance(myAcc, (err, balance) => {
          let mybalance = balance;
          console.log("mybalance");
          console.log(mybalance);
          setTotalBalance(mybalance);
        });
      }

    }
    balanceInit();

  }, [account]);

  console.log('dsdssdsf1');
  console.log(thisNFTWoner);

  let latestNft = useSelector((state) => state.allNft.nft);
  // const fractionalizeNFTContract = useSelector(
  //   (state) => state.allNft.FractionalizeNFTContract
  // );


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };




  async function buy(e) {
    debugger;
    e.preventDefault();
    const gasLimit = 180000;
    
    // const web3 = await getWeb3();
    // const networkId = await web3.eth.net.getId();
    // const marketplaceContract = new web3.eth.Contract(
    //   ArtMarketplace.abi,
    //   ArtMarketplace.networks[networkId].address
    // );
    const web3 = await getWeb3();
    let myaccounts = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    let myBalanceTotal = totalBalance;

    const fractionalizeNFTContract = new web3.eth.Contract(
      FractionalizeNFT.abi,
      FractionalizeNFT.networks[networkId].address
    );
    let myAcc = myaccounts[0];


    await web3.eth.getBalance(myaccounts[0], (err, blnc) => {
      debugger;
      myBalanceTotal = blnc;
    });
    const myBalance = parseInt(myBalanceTotal);

    if (myBalance > gasLimit) {
      const { fractionAmount } = formData;
      let total = fnftItem.fractionPrice * formData.fractionAmount;

      let numberPrice = parseInt(total);

      let marketfee = numberPrice * 0.02;
      let marketPrice = Web3.utils.fromWei(String(marketfee));
      let newmarketprice = Web3.utils.toWei(marketPrice, "ether");

      let marketamount = (numberPrice + marketfee)
      let newAmount = marketamount.toString();

      setIsload(true);

      // let total = Web3.utils.fromWei(String(fnftItem.fractionPrice), "ether") * formData.fractionAmount;
      // setIsload(false);

      try {

        console.log(owner);
        // let allow = await fractionsImplContract.methods.increaseAllowance(owner, 111).call();
        // console.log("allow", allow);
        if (ownerAddress === owner) {
          const receipt = await fractionalizeNFTContract.methods
            .TransferToken(fnftId, formData.fractionAmount, defaultAccount, newmarketprice)
            .send({ gas: gasLimit, value: newAmount, from: myAcc });

          console.log(receipt);
          //   const id = receipt.events.itemSold.id; ///saleId
          //   console.log(saleId);
          //   console.log(id);
          let fcount = ownerList.owner1.ownFractionCount - formData.fractionAmount;
          let data = {
            'fnftId': fnftId,
            'ownerAdd': account,
            'from': ownerAddress,
            'counts': formData.fractionAmount,
            'fractionsCount': fcount
          }
          const response = await api.post("/updateFnft", data, {
            headers: {
              "Content-Type": `application/json; boundary=${data._boundary}`,
            },
          });

        } else {

          const receipt = await fractionalizeNFTContract.methods
            .TransferTokenResellFnft(fnftId, formData.fractionAmount, defaultAccount, newmarketprice, ownerAddress)
            .send({ gas: gasLimit, value: newAmount, from: myAcc });
          let objectLength = Object.keys(ownerList).length;
          let fromAccFractionAmount = 0;
          for (let i = 1; i <= objectLength; i++) {
            let ownerKey = "owner" + i;
            if (ownerAddress === ownerList[ownerKey].to) {
              fromAccFractionAmount = ownerList[ownerKey].ownFractionCount
            }
          }

          let fcount = fromAccFractionAmount - formData.fractionAmount;
          let data = {
            'fnftId': fnftId,
            'ownerAdd': account,
            'from': ownerAddress,
            'counts': formData.fractionAmount,
            'fractionsCount': fcount
          }
          const response = await api.post("/updateFnft", data, {
            headers: {
              "Content-Type": `application/json; boundary=${data._boundary}`,
            },
          });

        }
        setIsload(false);
        Swal.fire({
            title: 'Success!',
            text: "Buying Successfully!",
            icon: 'success',
            showCloseButton: false,
            showCancelButton: false
        }).then((result) => {
          history.push('/myfnft');
          window.location.reload();
        });

        
        //   let item = await marketplaceContract.methods
        //   .itemsForSale(saleId)
        //   .call();

        //   let active = await marketplaceContract.methods
        //   .activeItems(item.tokenId)
        //   .call();

        // history.push('/mynft');
        // window.location.reload();



      } catch (error) {
        console.error("Error, buying: ", error);
        setIsload(false);
        Swal.fire({
          title: 'Error',
          text: "Error while buying!",
          icon: 'danger',
          showCloseButton: false,
          showCancelButton: false
        });
        
      }
    } else {
      setIsload(false);
      setAlertMessage("You have no enough balance for this transaction!");
      setalertTitle('Sorry!')
      setOpen(true);
    }


  }

  console.log("fractionData");
  console.log(fractionData);
  console.log("owner");
  console.log(owner);

  console.log("nftItem saiful");
  console.log(fnftItem);

  async function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }



  // async function loadNFTs() {    
  //   const provider = new ethers.providers.JsonRpcProvider()
  //   const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider)
  //   const marketContract = new ethers.Contract(nftmarketaddress, Market.abi, provider)
  //   const data = await marketContract.fetchMarketItems()

  //   const items = await Promise.all(data.map(async i => {
  //     const tokenUri = await tokenContract.tokenURI(i.tokenId)
  //     const meta = await axios.get(tokenUri)
  //     let price = ethers.utils.formatUnits(i.price.toString(), 'ether')
  //     let item = {
  //       price,
  //       tokenId: i.tokenId.toNumber(),
  //       seller: i.seller,
  //       owner: i.owner,
  //       image: meta.data.image,
  //       name: meta.data.name,
  //       description: meta.data.description,
  //     }
  //     return item
  //   }))
  //   setNfts(items)
  //   setLoadingState('loaded') 
  // }

  function openBuyModal() {
    $('.modal').addClass('active');
  }
  function closeBuyModal() {
    $('.modal').removeClass('active');
  }

  const columns = [
    {
      name: 'Event',
      selector: row => row.action,
      sortable: true,
    },
    {
      name: 'From',
      selector: row => row.from.slice(0, 7) + "..." + row.from.slice(-4),
      sortable: true,
    },
    {
      name: 'To',
      selector: row => row.to.slice(0, 7) + "..." + row.to.slice(-4),
    },
    {
      name: 'Date',
      selector: row => row.createDate.slice(0, 15),
      sortable: true,
    }

  ]
  let result = Object.keys(fnftOwnerList).map((key) => fnftOwnerList[key]);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <div>
      {/* <section className="banner">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="banner-content text-center">
                            <h2>{name_}</h2>
                        </div>
                        <div className="banner-tags d-flex justify-content-center align-items-center">
                            <a href="#">
                                <img src={imgBaseUrl + image} alt="" />
                                <span>Zora</span>
                                <svg height="14" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    data-v-1d58ccb5="" className="">
                                    <path
                                        d="M3.64359 28.0207L4.14359 27.1547L3.64359 28.0207L13.5 33.7113C15.047 34.6045 16.953 34.6045 18.5 33.7113L28.3564 28.0207C29.9034 27.1276 30.8564 25.4769 30.8564 23.6906V12.3094C30.8564 10.5231 29.9034 8.87244 28.3564 7.97927L18.5 2.28868C16.953 1.39551 15.047 1.39551 13.5 2.28867L3.64359 7.97927C2.09659 8.87244 1.14359 10.5231 1.14359 12.3094V23.6906C1.14359 25.4769 2.09659 27.1276 3.64359 28.0207Z"
                                        fill="#45B26B" stroke="#FCFCFD" strokeWidth="2"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M22.7071 13.2929C23.0976 13.6834 23.0976 14.3166 22.7071 14.7071L14.7071 22.7071C14.3166 23.0976 13.6834 23.0976 13.2929 22.7071L9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929C9.68342 16.9024 10.3166 16.9024 10.7071 17.2929L14 20.5858L21.2929 13.2929C21.6834 12.9024 22.3166 12.9024 22.7071 13.2929Z"
                                        fill="white"></path>
                                </svg>
                            </a>
                            <div className="banner-nft"> 1 NFT</div>
                            <div className="banner-verified">
                                <svg height="14" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg"
                                    data-v-1d58ccb5="" className="">
                                    <path
                                        d="M3.64359 28.0207L4.14359 27.1547L3.64359 28.0207L13.5 33.7113C15.047 34.6045 16.953 34.6045 18.5 33.7113L28.3564 28.0207C29.9034 27.1276 30.8564 25.4769 30.8564 23.6906V12.3094C30.8564 10.5231 29.9034 8.87244 28.3564 7.97927L18.5 2.28868C16.953 1.39551 15.047 1.39551 13.5 2.28867L3.64359 7.97927C2.09659 8.87244 1.14359 10.5231 1.14359 12.3094V23.6906C1.14359 25.4769 2.09659 27.1276 3.64359 28.0207Z"
                                        fill="#45B26B" stroke="#FCFCFD" strokeWidth="2"></path>
                                    <path fillRule="evenodd" clipRule="evenodd"
                                        d="M22.7071 13.2929C23.0976 13.6834 23.0976 14.3166 22.7071 14.7071L14.7071 22.7071C14.3166 23.0976 13.6834 23.0976 13.2929 22.7071L9.29289 18.7071C8.90237 18.3166 8.90237 17.6834 9.29289 17.2929C9.68342 16.9024 10.3166 16.9024 10.7071 17.2929L14 20.5858L21.2929 13.2929C21.6834 12.9024 22.3166 12.9024 22.7071 13.2929Z"
                                        fill="white"></path>
                                </svg>
                                <span>verified</span>
                            </div>
                            <div className="banner-Fraction">
                                <div className="fraction-dot"></div>
                                <span className="fraction-text">Fraction sale live</span>
                            </div>
                        </div>
                        <div className="banner-img text-center">
                          <img src={imgBaseUrl + image} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="description">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="description-content">
                            <h3 className="mb-3">DESCRIPTION</h3>
                            <p>{description}</p>
                        </div>
                        <div className="row gx-3 mt-5 gy-4">
                            <div className="col-lg-7">
                                <div className="supply d-flex justify-content-start align-items-center mb-3 gap-2">
                                    <h2>
                                        Collectable Supply
                                    </h2>
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                        <svg height="16px" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg" data-v-44822b80="">
                                            <circle cx="8" cy="8" r="7" stroke="#E4E6E9" strokeWidth="2"></circle>
                                            <path
                                                d="M6.8892 9.86222H8.07173V9.76989C8.07884 9.01705 8.32386 8.67259 8.9027 8.31747C9.55611 7.92685 9.96449 7.39418 9.96449 6.58097C9.96449 5.38068 9.03054 4.62784 7.67401 4.62784C6.43111 4.62784 5.42259 5.31676 5.38707 6.66619H6.65128C6.68324 5.99858 7.16619 5.67898 7.6669 5.67898C8.21023 5.67898 8.64702 6.04119 8.64702 6.59872C8.64702 7.09588 8.31676 7.43679 7.88707 7.70668C7.25852 8.0973 6.89631 8.49148 6.8892 9.76989V9.86222ZM7.51065 12.0781C7.92969 12.0781 8.28835 11.7301 8.2919 11.2969C8.28835 10.8707 7.92969 10.5227 7.51065 10.5227C7.07741 10.5227 6.72585 10.8707 6.7294 11.2969C6.72585 11.7301 7.07741 12.0781 7.51065 12.0781Z"
                                                fill="#8F97A3"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="placeholder-slot">
                                    <p className="percentage mb-2">
                                      
                                    </p>
                                    <p className="equal">
                                        = 1,448,751,951.176 DOG
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="supply d-flex justify-content-start align-items-center mb-3 gap-2">
                                    <h2>
                                        IMPLIED VALUATION
                                    </h2>
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                        <svg height="16px" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg" data-v-44822b80="">
                                            <circle cx="8" cy="8" r="7" stroke="#E4E6E9" strokeWidth="2"></circle>
                                            <path
                                                d="M6.8892 9.86222H8.07173V9.76989C8.07884 9.01705 8.32386 8.67259 8.9027 8.31747C9.55611 7.92685 9.96449 7.39418 9.96449 6.58097C9.96449 5.38068 9.03054 4.62784 7.67401 4.62784C6.43111 4.62784 5.42259 5.31676 5.38707 6.66619H6.65128C6.68324 5.99858 7.16619 5.67898 7.6669 5.67898C8.21023 5.67898 8.64702 6.04119 8.64702 6.59872C8.64702 7.09588 8.31676 7.43679 7.88707 7.70668C7.25852 8.0973 6.89631 8.49148 6.8892 9.76989V9.86222ZM7.51065 12.0781C7.92969 12.0781 8.28835 11.7301 8.2919 11.2969C8.28835 10.8707 7.92969 10.5227 7.51065 10.5227C7.07741 10.5227 6.72585 10.8707 6.7294 11.2969C6.72585 11.7301 7.07741 12.0781 7.51065 12.0781Z"
                                                fill="#8F97A3"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="placeholder-slot">
                                    <p className="percentage mb-2">
                                        Ξ 24,269.537
                                    </p>
                                    <p className="equal">
                                        ≈ $79,102,325
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="supply d-flex justify-content-start align-items-center mb-3 gap-2">
                                    <h2>
                                        FRACTIONS
                                    </h2>
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                        <svg height="16px" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg" data-v-44822b80="">
                                            <circle cx="8" cy="8" r="7" stroke="#E4E6E9" strokeWidth="2"></circle>
                                            <path
                                                d="M6.8892 9.86222H8.07173V9.76989C8.07884 9.01705 8.32386 8.67259 8.9027 8.31747C9.55611 7.92685 9.96449 7.39418 9.96449 6.58097C9.96449 5.38068 9.03054 4.62784 7.67401 4.62784C6.43111 4.62784 5.42259 5.31676 5.38707 6.66619H6.65128C6.68324 5.99858 7.16619 5.67898 7.6669 5.67898C8.21023 5.67898 8.64702 6.04119 8.64702 6.59872C8.64702 7.09588 8.31676 7.43679 7.88707 7.70668C7.25852 8.0973 6.89631 8.49148 6.8892 9.76989V9.86222ZM7.51065 12.0781C7.92969 12.0781 8.28835 11.7301 8.2919 11.2969C8.28835 10.8707 7.92969 10.5227 7.51065 10.5227C7.07741 10.5227 6.72585 10.8707 6.7294 11.2969C6.72585 11.7301 7.07741 12.0781 7.51065 12.0781Z"
                                                fill="#8F97A3"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="placeholder-slot">
                                    <p className="percentage mb-2">
                                        16,969,696,969 DOG
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="supply d-flex justify-content-start align-items-center mb-3 gap-2">
                                    <h2>
                                        CURATOR FEE
                                    </h2>
                                    <span data-bs-toggle="tooltip" data-bs-placement="top" title="Tooltip on top">
                                        <svg height="16px" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg" data-v-44822b80="">
                                            <circle cx="8" cy="8" r="7" stroke="#E4E6E9" strokeWidth="2"></circle>
                                            <path
                                                d="M6.8892 9.86222H8.07173V9.76989C8.07884 9.01705 8.32386 8.67259 8.9027 8.31747C9.55611 7.92685 9.96449 7.39418 9.96449 6.58097C9.96449 5.38068 9.03054 4.62784 7.67401 4.62784C6.43111 4.62784 5.42259 5.31676 5.38707 6.66619H6.65128C6.68324 5.99858 7.16619 5.67898 7.6669 5.67898C8.21023 5.67898 8.64702 6.04119 8.64702 6.59872C8.64702 7.09588 8.31676 7.43679 7.88707 7.70668C7.25852 8.0973 6.89631 8.49148 6.8892 9.76989V9.86222ZM7.51065 12.0781C7.92969 12.0781 8.28835 11.7301 8.2919 11.2969C8.28835 10.8707 7.92969 10.5227 7.51065 10.5227C7.07741 10.5227 6.72585 10.8707 6.7294 11.2969C6.72585 11.7301 7.07741 12.0781 7.51065 12.0781Z"
                                                fill="#8F97A3"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="placeholder-slot">
                                    <p className="percentage mb-2">
                                        0%
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="tab-section">
                            <div className="tab-title">
                                <p>Auction / Bidding</p>
                                <p>Trade Fractions</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> */}

      <div className={classes.pageItem}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>
        {isload ? <Loader /> : <span></span>}
        {Object.keys(fnftItem).length > 0 ? (
          <div className={classes.detailsPage}>
            <section className="container">
              <header className={classes.pageHeader} style={{ margin: '20px 0' }}>
                <Link to="/">
                  <KeyboardBackspaceIcon fontSize="large" /> Back to list
                </Link>
              </header>
            </section>
            <section className="dateils">
              <div className="modal">
                <div className="modal-content">
                  <span className="close-button" onClick={closeBuyModal}>×</span>
                  <div className="modal-form">
                    <form className="form-style-9" onSubmit={buy}>
                      <h3>Buy Fractions</h3>
                      <ul>
                        <li>
                          <input
                            name="fractionAmount"
                            required
                            value={formData.fractionAmount}
                            onChange={handleInputChange}
                            type="number" className="field-style" placeholder="Number Of Fraction" />
                        </li>
                        <li>
                          <h5>
                            Total: {Web3.utils.fromWei(String(fnftItem.fractionPrice), "ether")}<sub style={{ fontWidth: 'normal', fontSize: '8px' }}>ETH</sub> * {formData.fractionAmount} =
                            {Web3.utils.fromWei(String(fnftItem.fractionPrice), "ether") * formData.fractionAmount}<sub style={{ fontWidth: 'normal', fontSize: '8px' }}>ETH </sub>+ {owner !== account && (<span style={{ color: "red", fontSize: "10px" }}>  (2% MarketFees Will be Applicable)</span>)}
                          </h5>
                        </li>
                        <li>
                          <button className="default-btn" type="submit">Submit</button>
                        </li>
                      </ul>
                    </form>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-md-8">
                    <img className="ui fluid image" src={imageLocal} />
                  </div>
                  <div className="col-md-4">
                    <div className={classes.detailTitle}>
                      <h1 className="detailTitleHeading">{name_}</h1>
                      {/* <div className={classes.priceDiv} style={{fontSize:'15px'}}>
                      <span>
                        <strong>
                        <SvgIcon
                                component={EthereumLogo}
                                viewBox="0 0 400 426.6"
                                titleAccess="ETH"
                        />{Web3.utils.fromWei(String(fnftItem.fractionPrice), "ether")}  </strong>
                      </span>
                    </div> */}
                    </div>
                    <div className="fraction-details">
                      <div className="row">
                        <div className="col">
                          <div className="box-col">

                            <h6>
                              <div style={{ marginBottom: '5px' }}>Available supply</div>
                              <strong style={{ color: '#2c98f6' }}> {fractionData.ownFractionCount} {symbol_}</strong>
                            </h6>
                          </div>
                        </div>
                        <div className="col">
                          <div className="box-col">
                            <h6>
                              <div style={{ marginBottom: '5px' }}>Contract: </div>
                              <div>
                                <CopyToClipboard text={erc20Address} onCopy={onCopyText}>
                                  <span style={{ cursor: "pointer", display:'flex', marginLeft: '10px' }}>{erc20Address.slice(0, 14) + "..." + erc20Address.slice(-4)} {isCopied ? "" : <MdContentCopy />}</span>
                                </CopyToClipboard>
                              </div>
                              <div>
                              </div>
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="row" style={{ marginTop: "10px" }}>
                        <div className="col">
                          <div className="box-col">
                            <div className={classes.priceDiv}>
                              <div style={{ fontSize: '14px' }}>Per fraction price</div>
                              <span>
                                <strong style={{ color: '#2c98f6', width: '50px' }}>
                                  <SvgIcon
                                    style={{ width: '30px' }}
                                    component={EthereumLogo}
                                    viewBox="0 0 400 426.6"
                                    titleAccess="ETH"
                                  />{Web3.utils.fromWei(String(fnftItem.fractionPrice), "ether")} <small style={{ fontSize: '10px', color: '#6e6969' }}>ETH</small> </strong>
                              </span>
                              <div style={{ color: '#2c98f6' }}>≈ {'$' + (Web3.utils.fromWei(String(fractionPrice), "ether") * usdRate).toFixed(3)}</div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>




                    <div className={classes.detailDes}>
                      <div className="panel panel-default">
                        <div className="panel-heading">Description</div>
                        <div className="panel-body">
                          <p>{description}</p>
                        </div>
                      </div>
                    </div>

                    <div className={classes.buttonDetail}>

                      {ownerAddress !== account && (
                        <Button
                          className="cnBtn"
                          disableElevation
                          variant="contained"
                          onClick={openBuyModal}
                        // onClick={() => buy('1', fnftItem.fractionPrice)}
                        >
                          Buy
                        </Button>
                      )}
                      {/* {ownerAddress === account && !fractionData.isForSale && (
                      <Button
                        className="cnBtn"
                        disableElevation
                        variant="contained"
                        onClick={openBuyModal}
                      >
                        Sell
                      </Button>
                    )} */}
                    </div>

                  </div>
                </div>
              </div>
              <section className="top-owners">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="panel panel-default">
                        <div className="panel-heading">Transaction history</div>
                        <div className="panel-body">
                          <DataTable columns={columns} data={result} filter={true} pagination />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="panel panel-default">
                        <div className="panel-heading">Info</div>
                        <div className="panel-body">
                          <Tabs>
                            <TabList>
                              <Tab>Owner</Tab>
                              <Tab>Creator</Tab>
                              <Tab>Details</Tab>
                            </TabList>

                            <TabPanel>
                              {/* <p>{owner}</p> */}
                              <div className="section-wrapper" style={{ marginTop: '0' }}>
                                <div className="nft-sell-wrapper">
                                  <div className="row gx-4 gy-3">
                                    <div className="col-12 slideMain">
                                      <div className="swiper-slide" >
                                        <div className="nft-item">
                                          <div className="fnft-inner">
                                            <ul>
                                              {Object.keys(ownerList).map((keyItem, index) => (
                                                <li key={'owner' + ownerList[keyItem].to + "one"}>
                                                  <Link to={`/view-profile/${ownerList[keyItem].to}`}>
                                                    <div className="row" style={{ alignItems: 'center' }}>
                                                      <div className="col-sm-6">
                                                        <div className="nft-content">
                                                          <div className="author-thumb">
                                                            <div className="veryfied">
                                                              <ProfileImageGet address={ownerList[keyItem].to} userIco={userIco} />
                                                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                                <path d="M13.9144 12.7646C14.0077 13.533 13.3419 14.1783 12.5768 14.0608L10.9348 13.8087C10.5173 13.7446 10.0978 13.9107 9.83735 14.2432L8.81298 15.551C8.33567 16.1604 7.40855 16.1458 6.95061 15.5218L5.96782 14.1824C5.71796 13.8419 5.30382 13.6627 4.88456 13.7137L3.23548 13.9141C2.46708 14.0075 1.82181 13.3416 1.93927 12.5765L2.19137 10.9345C2.25547 10.5171 2.08935 10.0975 1.75686 9.83707L0.449081 8.8127C-0.160286 8.33538 -0.145722 7.40826 0.478337 6.95033L1.81765 5.96754C2.15815 5.71768 2.33737 5.30354 2.28642 4.88427L2.08602 3.2352C1.99264 2.4668 2.65851 1.82152 3.4236 1.93899L5.06557 2.19109C5.48303 2.25518 5.90259 2.08907 6.16303 1.75658L7.1874 0.448798C7.66471 -0.160569 8.59183 -0.146005 9.04976 0.478054L10.0326 1.81736C10.2824 2.15787 10.6966 2.33708 11.1158 2.28613L12.7649 2.08574C13.5333 1.99236 14.1786 2.65823 14.0611 3.42332L13.809 5.06528C13.7449 5.48274 13.911 5.9023 14.2435 6.16274L15.5513 7.18711C16.1607 7.66442 16.1461 8.59154 15.522 9.04948L14.1827 10.0323C13.8422 10.2821 13.663 10.6963 13.714 11.1155L13.9144 12.7646Z" fill="url(#paint0_linear)" />
                                                                <path d="M6.93353 8.62215L5.15575 7.55549L4.44464 8.2666L6.93353 11.111L11.5558 6.48882L10.8446 5.77771L6.93353 8.62215Z" fill="white" />
                                                                <defs>
                                                                  <linearGradient id="paint0_linear" x1="21.1974" y1="7.44855" x2="3.34965" y2="-1.26427" gradientUnits="userSpaceOnUse">
                                                                    <stop stopColor="#2CF9F9" />
                                                                    <stop offset="0.354167" stopColor="#397DFF" />
                                                                    <stop offset="0.557292" stopColor="#3858FF" />
                                                                    <stop offset="0.791667" stopColor="#B14FFF" />
                                                                    <stop offset="1" stopColor="#FFA0D9" />
                                                                    <stop offset="1" stopColor="#FFBEE5" />
                                                                  </linearGradient>
                                                                </defs>
                                                              </svg>
                                                            </div>
                                                          </div>
                                                          <div className="author-details d-flex flex-wrap align-items-center gap-15">
                                                            <div className="author-det-info">
                                                              {ownerList[keyItem].to.slice(0, 7) + "..." + ownerList[keyItem].to.slice(-4)}
                                                            </div>

                                                          </div>
                                                        </div>
                                                      </div>
                                                      <div className="col-sm-6 percent">
                                                        {(parseInt(ownerList[keyItem].ownFractionCount) / parseInt(fractionsCountInitial)) * 100} %
                                                        {/* <OwnerPercentage singleOwnerdata={singleOwner} fractionsCountInitial={fractionsCountInitial} /> */}

                                                      </div>
                                                    </div>


                                                  </Link>

                                                </li>
                                              ))}
                                            </ul>
                                            {/* <Link to={`/view-profile/${owner}`}>

                                                <div className="nft-content">
                                                  <div className="author-thumb">
                                                    <div className="veryfied">
                                                      {profileData.image ? (
                                                        <img src={profileData.image} />
                                                      ) : (
                                                        <img src={userIco} />
                                                      )}
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                        <path d="M13.9144 12.7646C14.0077 13.533 13.3419 14.1783 12.5768 14.0608L10.9348 13.8087C10.5173 13.7446 10.0978 13.9107 9.83735 14.2432L8.81298 15.551C8.33567 16.1604 7.40855 16.1458 6.95061 15.5218L5.96782 14.1824C5.71796 13.8419 5.30382 13.6627 4.88456 13.7137L3.23548 13.9141C2.46708 14.0075 1.82181 13.3416 1.93927 12.5765L2.19137 10.9345C2.25547 10.5171 2.08935 10.0975 1.75686 9.83707L0.449081 8.8127C-0.160286 8.33538 -0.145722 7.40826 0.478337 6.95033L1.81765 5.96754C2.15815 5.71768 2.33737 5.30354 2.28642 4.88427L2.08602 3.2352C1.99264 2.4668 2.65851 1.82152 3.4236 1.93899L5.06557 2.19109C5.48303 2.25518 5.90259 2.08907 6.16303 1.75658L7.1874 0.448798C7.66471 -0.160569 8.59183 -0.146005 9.04976 0.478054L10.0326 1.81736C10.2824 2.15787 10.6966 2.33708 11.1158 2.28613L12.7649 2.08574C13.5333 1.99236 14.1786 2.65823 14.0611 3.42332L13.809 5.06528C13.7449 5.48274 13.911 5.9023 14.2435 6.16274L15.5513 7.18711C16.1607 7.66442 16.1461 8.59154 15.522 9.04948L14.1827 10.0323C13.8422 10.2821 13.663 10.6963 13.714 11.1155L13.9144 12.7646Z" fill="url(#paint0_linear)" />
                                                        <path d="M6.93353 8.62215L5.15575 7.55549L4.44464 8.2666L6.93353 11.111L11.5558 6.48882L10.8446 5.77771L6.93353 8.62215Z" fill="white" />
                                                        <defs>
                                                          <linearGradient id="paint0_linear" x1="21.1974" y1="7.44855" x2="3.34965" y2="-1.26427" gradientUnits="userSpaceOnUse">
                                                            <stop stopColor="#2CF9F9" />
                                                            <stop offset="0.354167" stopColor="#397DFF" />
                                                            <stop offset="0.557292" stopColor="#3858FF" />
                                                            <stop offset="0.791667" stopColor="#B14FFF" />
                                                            <stop offset="1" stopColor="#FFA0D9" />
                                                            <stop offset="1" stopColor="#FFBEE5" />
                                                          </linearGradient>
                                                        </defs>
                                                      </svg>
                                                    </div>
                                                  </div>
                                                  <div className="author-details d-flex flex-wrap align-items-center gap-15">

                                                    <div className="author-det-info">
                                                      <h5>{profileData.userName}</h5>
                                                      <p className="nft-price yellow-color">{owner.slice(0, 7) + "..." + owner.slice(-4)}</p>
                                                    </div>

                                                  </div>
                                                </div>
                                              </Link> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <p>{profileData.userName}</p>
                                <img className="ui fluid image" src={profileData.image} /> */}
                            </TabPanel>
                            <TabPanel>
                              {/* <p>{creator}</p> */}
                              <div className="section-wrapper" style={{ marginTop: '0' }}>
                                <div className="nft-sell-wrapper">
                                  <div className="row gx-4 gy-3">
                                    <div className="col-12 slideMain">
                                      <div className="swiper-slide" >
                                        <div className="nft-item">
                                          <div className="nft-inner">
                                            <Link to={`/view-profile/${owner}`}>
                                              <div className="nft-content">
                                                <div className="author-thumb">
                                                  <div className="veryfied">
                                                    {profileDataCreator.image ? (
                                                      <img src={profileDataCreator.image} />
                                                    ) : (
                                                      <img src={userIco} />
                                                    )}

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                      <path d="M13.9144 12.7646C14.0077 13.533 13.3419 14.1783 12.5768 14.0608L10.9348 13.8087C10.5173 13.7446 10.0978 13.9107 9.83735 14.2432L8.81298 15.551C8.33567 16.1604 7.40855 16.1458 6.95061 15.5218L5.96782 14.1824C5.71796 13.8419 5.30382 13.6627 4.88456 13.7137L3.23548 13.9141C2.46708 14.0075 1.82181 13.3416 1.93927 12.5765L2.19137 10.9345C2.25547 10.5171 2.08935 10.0975 1.75686 9.83707L0.449081 8.8127C-0.160286 8.33538 -0.145722 7.40826 0.478337 6.95033L1.81765 5.96754C2.15815 5.71768 2.33737 5.30354 2.28642 4.88427L2.08602 3.2352C1.99264 2.4668 2.65851 1.82152 3.4236 1.93899L5.06557 2.19109C5.48303 2.25518 5.90259 2.08907 6.16303 1.75658L7.1874 0.448798C7.66471 -0.160569 8.59183 -0.146005 9.04976 0.478054L10.0326 1.81736C10.2824 2.15787 10.6966 2.33708 11.1158 2.28613L12.7649 2.08574C13.5333 1.99236 14.1786 2.65823 14.0611 3.42332L13.809 5.06528C13.7449 5.48274 13.911 5.9023 14.2435 6.16274L15.5513 7.18711C16.1607 7.66442 16.1461 8.59154 15.522 9.04948L14.1827 10.0323C13.8422 10.2821 13.663 10.6963 13.714 11.1155L13.9144 12.7646Z" fill="url(#paint0_linear)" />
                                                      <path d="M6.93353 8.62215L5.15575 7.55549L4.44464 8.2666L6.93353 11.111L11.5558 6.48882L10.8446 5.77771L6.93353 8.62215Z" fill="white" />
                                                      <defs>
                                                        <linearGradient id="paint0_linear" x1="21.1974" y1="7.44855" x2="3.34965" y2="-1.26427" gradientUnits="userSpaceOnUse">
                                                          <stop stopColor="#2CF9F9" />
                                                          <stop offset="0.354167" stopColor="#397DFF" />
                                                          <stop offset="0.557292" stopColor="#3858FF" />
                                                          <stop offset="0.791667" stopColor="#B14FFF" />
                                                          <stop offset="1" stopColor="#FFA0D9" />
                                                          <stop offset="1" stopColor="#FFBEE5" />
                                                        </linearGradient>
                                                      </defs>
                                                    </svg>
                                                  </div>
                                                </div>
                                                <div className="author-details d-flex flex-wrap align-items-center gap-15">
                                                  <div className="author-det-info">
                                                    <h5>{profileDataCreator.userName}</h5>
                                                    <p className="nft-price yellow-color">{owner.slice(0, 7) + "..." + owner.slice(-4)}</p>
                                                  </div>
                                                </div>
                                              </div>
                                            </Link>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                            <TabPanel>
                              <div className="details-row">
                                <div className="panel panel-default">
                                  <div className="panel-body">
                                    {/* <div className="row">
                                      <div className="col">
                                        Contract Address

                                      </div>
                                      <div className="col">
                                        {defaultAccount.slice(0, 7) + "..." + defaultAccount.slice(-4)}

                                      </div>

                                    </div> */}
                                    <div className="row">
                                      <div className="col">
                                        Token ID

                                      </div>
                                      <div className="col">
                                        {nftId}

                                      </div>

                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        Token Standard

                                      </div>
                                      <div className="col">
                                        ERC-20

                                      </div>

                                    </div>
                                    <div className="row">
                                      <div className="col">
                                        Blockchain

                                      </div>
                                      <div className="col">
                                        Ethereum

                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </TabPanel>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <h2 className="owner-title">Top 10 Owners</h2>
                      <div className="owner-div">
                        <div className="header">
                          <div className="row">
                            <div className="col-lg-1 text-center">
                              Rank
                            </div>
                            <div className="col-lg-4">
                              Wallet Address
                            </div>
                            <div className="col-lg-2">
                              Quantity
                            </div>
                            <div className="col-lg-3">
                              Percentage
                            </div>
                            <div className="col-lg-2">

                            </div>
                          </div>
                        </div>

                        <div className="owner-body">
                          {Object.keys(ownerList).map((keyItem, index) => (
                            <div className="owner-body-section" key={'owner' + ownerList[keyItem].to + "top"}>

                              <div className="row align-items-center">
                                <div className="col-lg-1 rank">
                                  #{index + 1}
                                </div>
                                <div className="col-lg-4">
                                  <div className="wallet d-flex justify-content-start gap-5 align-items-center">

                                    <ProfileImageGet address={ownerList[keyItem].to} userIco={userIco} />
                                    <Link to={`/view-profile/${ownerList[keyItem].to}`}>

                                      <div>
                                        <p className="heading">Owner</p>
                                        <p className="txt">{ownerList[keyItem].to.slice(0, 7) + "..." + ownerList[keyItem].to.slice(-4)}</p>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-lg-2 quantity">
                                  <p>
                                    {ownerList[keyItem].ownFractionCount} {symbol_}
                                  </p>
                                </div>
                                <div className="col-lg-3 percent">
                                  <OwnerPercentage singleOwnerdata={ownerList[keyItem]} fractionsCountInitial={fractionsCountInitial} />
                                </div>
                                <div className="col-lg-2 value">
                                  {/* <p className="font-semibold">
                                              Ξ 17,774.036
                                          </p>
                                          <p className="text-px12">
                                              ≈ $58,021,395.612
                                          </p> */}
                                  <Link to={`/view-profile/${ownerList[keyItem].to}`}>View Profile</Link>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <div className="container">
                <LatestNft latestNft={latestNft} title="Related NFTs" coloumn="col-md-3"></LatestNft>
              </div>
            </section>

          </div >

        ) : (
          <Loader />
        )}
      </div >

    </div >
  );
};

export default ItemFNFT;
