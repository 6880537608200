import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Card from "../../../components/Card";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStyles } from "./styles.js";
import { cssStyles } from "./styles.css";
import nodatafound from '../../../assets/nodatafound.png';
import loaderPlaceholder from "../../../assets/placeholder-loading.gif";
import angleIco from "../../../assets/right-arrows.png";
import $ from 'jquery';

import { api } from "../../../services/api";
import getWeb3 from "../../../utils/getWeb3";
// import FractionalizeNFT from "../../../contracts/FractionalizeNFT.json";
import FnftCardOwner from "../../../components/FnftCardOwner";

const ViewProfile = () => {
  
  const classes = useStyles();
  let [isLoading, setIsLoading] = useState(true);
  let [profileData, setProfileData] = useState(true);
  let [userFnftData, setUserFnft] = useState([]);

  
  
  const { account } = useParams();
  let [accountId, setAccount] = useState(account);
  
  const dispatch = useDispatch();
  let nftItem = useSelector((state) => state.allNft.nft);
  console.log("nftItem");
  console.log(nftItem);
  // if(nftItem.length > 0){
  //   setIsLoading(false);
  // }


  useEffect(() => {
    const init = async () => {
      const acc = accountId;
      try {
        const response = await api
        .get(`/saveUser/${acc}`)
        .catch((err) => {
            console.log("Err: ", err);
        });

        if(response.statusText = "OK"){
            if(response.data != null){
                let data = response.data;
                setProfileData(data);
            }
        }
        
        
      } catch (error) {
        
      }


      try {
        const responseFnft = await api
        .get(`/searchfnft/${acc}`)
        .catch((err) => {
            console.log("Err: ", err);
        });

        if(responseFnft.statusText = "OK"){
            if(responseFnft.data != null){
                let data = responseFnft.data;
                setUserFnft(data);
            }
        }
        
        
      } catch (error) {
        
      }

      const web3 = await getWeb3();
      const networkId = await web3.eth.net.getId();
      // const fractionalizeNFTContract = new web3.eth.Contract(
      //   FractionalizeNFT.abi,
      //   FractionalizeNFT.networks[networkId].address
      // );
      // let userFnft = [];
      // fractionalizeNFTContract.events.Transfer({
      //   filter: {},
      //   fromBlock: 10
      // }, async function(error, event){
        
      //   if(error == null){
      //     if(event.returnValues._to == acc){
      //       let dataObject = {
      //         erc20Address: event.returnValues.erc20Address,
      //         from: event.returnValues._from,
      //         to: event.returnValues._to,
      //         amount: event.returnValues._amount,
      //         fnftData: null,
      //       } 

      //       try {
      //         const response2 = await api
      //         .get(`/searchfnftErc20/${event.returnValues.erc20Address}`)
      //         .catch((err) => {
      //             console.log("Err: ", err);
      //         });
      
      //         if(response2.statusText = "OK"){
      //             if(response2.data != null){
      //               let data = response2.data;
      //               dataObject.fnftData = data
      //             }
      //         }
              
              
      //       } catch (error) {
              
      //       }
      //       userFnft.push(dataObject);
      //     }
          
      //   }
        
      // });

      // console.log("event 1");
      // console.log(userFnft);
      // setUserFnft(userFnft);

      // if(error == null){
      //   if(event.returnValues.erc20Address == erc20Address){
      //     let dataObject = {
      //       from: event.returnValues._from,
      //       to: event.returnValues._to,
      //       amount: event.returnValues._amount,
      //     } 
      //     historyObj.push(dataObject);
      //   }
        
      // }
    };
    init();
    
  }, [dispatch]);

  console.log("userFnftData");
  console.log(userFnftData);

  function tabToggle(type){
    $('.stoggle-nemu').removeClass('active');
    $('.list-data').removeClass('active');
    $('.'+type+'-toggle').addClass('active');
    $('.list-'+type).addClass('active');
    
  }
  

  return (
    
    <div className={classes.homepage}>
        <section className={classes.allNfts}>
          
          <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                        <div className="d-flex flex-column align-items-center text-center">
                            {profileData.image != "" ? <img style={{ width: '110px'}} src={profileData.image} /> : <span></span> }
                            
                            <div className="mt-3">
                                <h4 style={{marginBottom: '5px'}}>{profileData.userName}</h4>
                                <p style={{marginBottom: '10px'}} className="text-muted font-size-sm">{profileData.bio}</p>
                            </div>
                        </div>
                        <ul className="soical-ico">
                            <li>
                                <a href={profileData.fbLink} target="_blank" className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-10"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook me-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></h6>
                                    
                                </a>
                            </li>
                            <li>
                                <a href={profileData.twitterLink} target="_blank" className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-10"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter me-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg></h6>
                                    
                                </a>
                            </li>
                            <li>
                                <a href={profileData.instagramLink} className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-10"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram me-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></h6>
                                    
                                </a>
                            </li>
                            
                        </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="nft-fnft clearfix">
                  <div className="pro-left">
                    <span onClick={() => tabToggle('nft')} className="stoggle-nemu nft-toggle active">NFT <img src={angleIco} /></span>
                    <span onClick={() => tabToggle('fnft')} className="stoggle-nemu fnft-toggle">FNFT <img src={angleIco} /></span>
                  </div>
                  <div className="pro-right">
                    <div className="list-data list-nft active">
                      <Tabs>
                        <TabList>
                          <Tab>All Collections</Tab>
                          <Tab>For Sale</Tab>
                          <Tab>Brought</Tab>
                        </TabList>

                        {/* all */}
                        <TabPanel>
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            {nftItem.map((nft) => (
                              nft.owner==account ?
                              <Grid item key={nft.tokenId}>
                                <Card {...nft} />
                              </Grid>
                              :
                              <span key={nft.tokenId}></span>
                              
                            ))}
                          </Grid>
                        </TabPanel>

                        {/* for sale */}
                        <TabPanel>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            {nftItem.map((nft) => (
                              nft.owner == account && nft.isForSale ?
                              <Grid item key={nft.tokenId+1}>
                                <Card {...nft} />
                              </Grid>
                              :
                              <span key={nft.tokenId+1}></span>
                              
                            ))}
                          </Grid>
                        </TabPanel>

                        {/* brought */}
                        <TabPanel>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                          >
                            {nftItem.map((nft) => (
                              nft.owner==account && nft.isSold && !nft.isForSale ?
                              <Grid item key={1+nft.tokenId}>
                                <Card {...nft} />
                              </Grid>
                              :
                              <span key={1+nft.tokenId}></span>
                              
                            ))}
                          </Grid>
                        </TabPanel>
                      </Tabs>
                    </div>
                    <div className="list-data list-fnft">
                      <div className="row">
                        {userFnftData.map((nft) => (
                          <FnftCardOwner item key={nft.tokenId+"fnft"} {...nft}></FnftCardOwner>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      

       
    </div>
  );
};

export default ViewProfile;
