import React from "react";
import { styles } from './style.css';

const BGshape = () => {
    return(
        <div className="shapes">

                  <svg className="abstract-svg-1" viewBox="0 0 491.858 491.858">

                      <path
                          d="M491.858,442.461c0,13.931-11.293,25.224-25.224,25.224L245.93,373.097L25.224,467.686
  C11.292,467.686,0,456.392,0,442.461L227.011,32.58c0,0,18.918-18.918,37.834,0C283.764,51.499,491.858,442.461,491.858,442.461z">
                      </path>

                  </svg>

                  <svg className="abstract-svg-2" viewBox="0 0 512 512">

                      <path d="M511.003,496.262l-33.019-72.64c-42.012-92.429-42.012-242.82,0-335.245l33.019-72.641
  c1.92-4.224,1.018-9.196-2.262-12.477c-3.279-3.28-8.249-4.179-12.477-2.262l-72.641,33.019
  c-92.431,42.013-242.82,42.012-335.245,0L15.737,0.997C11.514-0.919,6.543-0.021,3.26,3.259c-3.28,3.281-4.182,8.253-2.262,12.477
  l33.019,72.641c42.012,92.428,42.012,242.817,0,335.245l-33.019,72.64c-1.92,4.224-1.018,9.196,2.262,12.477
  c3.283,3.283,8.257,4.182,12.477,2.262l72.64-33.019c92.425-42.012,242.817-42.015,335.245,0l72.641,33.019
  c1.478,0.671,3.046,0.997,4.603,0.997c2.893,0,5.74-1.128,7.874-3.26C512.02,505.458,512.922,500.487,511.003,496.262z
  M432.834,457.716C384.08,435.556,320.041,424.477,256,424.477s-128.081,11.079-176.835,33.239l-45.618,20.736l20.736-45.618
  c44.319-97.507,44.319-256.163,0-353.669L33.547,33.546l45.618,20.736c97.505,44.319,256.161,44.322,353.669,0l45.619-20.736
  l-20.736,45.619c-44.32,97.505-44.32,256.16,0,353.669l20.736,45.618L432.834,457.716z"></path>

                  </svg>

                  <svg className="abstract-svg-3" viewBox="0 0 510 510">

                      <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,459
  c-112.2,0-204-91.8-204-204S142.8,51,255,51s204,91.8,204,204S367.2,459,255,459z"></path>

                  </svg>

                  <svg className="abstract-svg-4" viewBox="0 0 184.58 184.58">

                      <path d="M182.004,146.234L108.745,19.345c-3.435-5.949-9.586-9.5-16.455-9.5s-13.021,3.551-16.455,9.5L2.576,146.234
    c-3.435,5.948-3.435,13.051,0,19c3.435,5.949,9.586,9.5,16.455,9.5h146.518c6.869,0,13.021-3.552,16.455-9.5
    C185.438,159.285,185.438,152.182,182.004,146.234z M169.88,158.234c-0.435,0.751-1.725,2.5-4.331,2.5H19.031
    c-2.606,0-3.896-1.749-4.331-2.5c-0.434-0.752-1.302-2.744,0.001-5L87.96,26.345c1.303-2.256,3.462-2.5,4.33-2.5
    s3.027,0.244,4.33,2.5l73.259,126.889C171.181,155.49,170.313,157.482,169.88,158.234z"></path>

                  </svg>


            </div> 
    )


}
export default BGshape;