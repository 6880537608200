import React, { useState, useEffect } from "react";
import AllCollection from "../../components/AllCollections/AllCollection";

const AllCollectionsPage = () => {
    return (
        <>
            <section className="my-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="collection-div">
                                <h1>All Collections</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <AllCollection howManyLoad={'all'}></AllCollection>
        </>
    )
}

export default AllCollectionsPage;