import Web3 from 'web3';
const environmentData = process.env;
const HDWalletProvider = require("@truffle/hdwallet-provider");
const privKeys = [environmentData.REACT_APP_HD_WALLET_PRIVATE_KEY];

const getWeb3 = () =>
  new Promise ( async (resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
  // window.addEventListener("load", async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
      try {
        
        if(window.ethereum._state != undefined){
          if(window.ethereum._state.isUnlocked){
            const web3 = new Web3(window.ethereum);
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            resolve(web3);
          }else{
            const provider = new HDWalletProvider(privKeys, `${environmentData.REACT_APP_HD_WALLET_PROVIDER_URL}`);
            const web3 = new Web3(provider);
            resolve(web3);
          }
          
        }else if(window.ethereum._metamask != undefined){
          let isUnlockCheck = await window.ethereum._metamask.isUnlocked();
          if(isUnlockCheck){
            const web3 = new Web3(window.ethereum);
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            resolve(web3);
          }else{
            const provider = new HDWalletProvider(privKeys, `${environmentData.REACT_APP_HD_WALLET_PROVIDER_URL}`);
            const web3 = new Web3(provider);
            resolve(web3);
          }
        }
        else{
          const provider = new HDWalletProvider(privKeys, `${environmentData.REACT_APP_HD_WALLET_PROVIDER_URL}`);
          const web3 = new Web3(provider);
          resolve(web3);
        }
        
        
        // Accounts now exposed
        
        
      } catch (error) {
        reject("User denied account access. " + error);
        console.log("User denied account access. " + error)
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      console.log("Injected web3 detected.");
      resolve(web3);
    }
    // Fallback to localhost; use dev console port by default...
    else {
      // const provider = new HDWalletProvider(privKeys, "https://ropsten.infura.io/v3/8dcd468a078e4afab5579ffabc2a11f1");
      
      // const web3 = new Web3(provider);

      const provider = new HDWalletProvider(privKeys, `${environmentData.REACT_APP_DEFAULT_NETWORK_RPC_URL}`);
      
      const web3 = new Web3(provider);
      // const provider = new Web3.providers.HttpProvider(
      //   "http://127.0.0.1:8545"
      // );
      // const web3 = new Web3(provider);
      console.log("No web3 instance injected, using Local web3.");
      resolve(web3);
    }
  // });

});

export default getWeb3;