export const ActionTypes = {
  SET_NFT: "SET_NFT",
   SET_LazyNFT: "SET_LazyNFT",
   SET_LazyNFTV2: "SET_LazyNFTV2",
  SET_FNFT: "SET_FNFT",
  SET_USD_RATE: "SET_USD_RATE",
  SELECTED_NFT: "SELECTED_NFT",
  SELECTED_LazyNFT: "SELECTED_LazyNFT",
  SELECTED_LazyNFTV2: "SELECTED_LazyNFTV2",
  REMOVE_SELECTED_NFT: "REMOVE_SELECTED_NFT",
    REMOVE_SELECTED_LazyNFT: "REMOVE_SELECTED_LazyNFT",
    REMOVE_SELECTED_LazyNFTV2: "REMOVE_SELECTED_LazyNFTV2",
  SELECTED_FNFT: "SELECTED_FNFT",
  REMOVE_SELECTED_FNFT: "REMOVE_SELECTED_FNFT",
  SET_ACCOUNT: "SET_ACCOUNT",

  SET_MARKET_CONTRACT: "SET_MARKET_CONTRACT",
  SET_MARKET_CONTRACT_GAS_LESS: "SET_MARKET_CONTRACT_GAS_LESS",
  SET_MARKET_CONTRACT_GAS_LESSV2: "SET_MARKET_CONTRACT_GAS_LESSV2",

  
  SET_FRACTIONALIZER_CONTRACT: "SET_FRACTIONALIZER_CONTRACT",
  SET_FRACTIONSIMPL_CONTRACT: "SET_FRACTIONSIMPL_CONTRACT",
  
  SET_FRACTIONALIZENFT_CONTRACT: "SET_FRACTIONALIZENFT_CONTRACT",
  SET_BALANCE: "SET_BALANCE",
  SET_IS_CONNECTED: "SET_IS_CONNECTED"
};
