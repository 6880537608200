import React from "react";
import { Styles } from "./styles.css";

const HelpCenter = () => {
  return (

    <div className="container">
      <section className="section knowledge-base">
        <section className="categories">
          <ul className="blocks-list">
            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  Getting Started
                </span>
                <span className="blocks-item-description">Learn how to create an account, set up your wallet, and what you can do on MetaWirks</span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  Buying
                </span>
                <span className="blocks-item-description">Learn how to purchase your first NFT and understand gas fees and what's gas free on MetaWirks</span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  Selling
                </span>
                <span className="blocks-item-description">Learn how list your NFTs for sale and understand the different ways to list your NFTs</span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  Creating
                </span>
                <span className="blocks-item-description">Learn how to create your very first NFT and how to create your NFT collections so you can begin selling and sharing</span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  FAQ
                </span>
                <span className="blocks-item-description">Learn answers to frequently asked questions on MetaWirks</span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  User Safety
                </span>
                <span className="blocks-item-description">Learn more about anti-fraud and user safety processes on MetaWirks </span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  Partners
                </span>
                <span className="blocks-item-description">Learn how you can partner with us to showcase your NFT drops </span>
              </a>
            </li>

            <li className="blocks-item">
              <a href="#" className="blocks-item-link">
                <span className="blocks-item-title">
                  Developers
                </span>
                <span className="blocks-item-description">Learn how you can develop with MetaWirks</span>
              </a>
            </li>



          </ul>

        </section>


        <section className="articles">
          <h2>Promoted articles</h2>
          <ul className="article-list promoted-articles">

            <li className="promoted-articles-item">
              <a href="#">
                How do I create an MetaWirks account?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                Which blockchains does MetaWirks support?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How do royalties work on MetaWirks?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How do I sell an NFT?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How do I create an NFT?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How do I find my funds on Ethereum?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                Where can I find NFT sales?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How can I revoke access to delisted items?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How can I stay safe and protect my NFTs?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                What can I do if I think my account is compromised?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How do I stay safe on Discord?


              </a>
            </li>

            <li className="promoted-articles-item">
              <a href="#">
                How can I report fraudulent content on MetaWirks?


              </a>
            </li>

          </ul>
        </section>

      </section>



    </div>

  )
}
export default HelpCenter;