import React from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";

import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { styles } from "./styles.css";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
import image from "../../assets/cover-img4.jpg"
import starIco from "../../assets/2.png"


import { imgBaseUrl } from "../../services/api";

const Featured = ({ latestNft }) => {     
    latestNft = latestNft.slice(0, 10);
    return (
        
        <section className="exclusive-drops fea">
            <div className="container">
                <div className="topartist_slider">
                    <div className="swiper exclusiveDrops">
                        <div className="swiper-wrapper">
                        <Swiper
                        // install Swiper modules
                        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                        spaceBetween={15}
                        slidesPerView={1}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        autoplay= {true}
                        >
                        {latestNft.map((nft) => (
                            <SwiperSlide key={'feaa'+nft.tokenId}>
                            <div>
                            <Link to={`/nft/${nft.tokenKey}`}>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">{nft.name.slice(0, 14)}
                                                {nft.name.length > 14 ? (
                                                    <small>...</small>
                                                ):(
                                                    <small></small>
                                                )}</span>
                                                {/* <Typography className="price" variant="h6">
                                                <SvgIcon
                                                    component={EthereumLogo}
                                                    viewBox="0 0 400 426.6"
                                                    titleAccess="ETH"
                                                    color="white"
                                                />
                                                <span>
                                                
                                            
                                                    {Web3.utils.fromWei(String(nft.price), "ether")} 
                                                </span>
                                            </Typography> */}
                                        </span>
                                    <div className="nft_pic_wrap">
                                        <img src={nft.imagelistLocal?.image_1} className="lazy img-fluid" alt=""/>
                                    </div>
                                    </div>
                            </div>
                            </Link>
                            </div>
                            </SwiperSlide>
                            ))}
                            {/* <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div> */}
                            </Swiper>

                            </div>
                            
                    </div>
                        
                            
                            
                </div>   
            </div>
        </section>
        
    );
};

export default Featured;
