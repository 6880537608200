import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { defaultAccount } from "./services/api";
import { useSelector } from "react-redux";
const ProtectedRoute = props => {
    
    let account = useSelector((state) => state.allNft.account);
    if(defaultAccount != account){
        return <Redirect to="/" />
    }

    return <Route {...props} />
}

export default ProtectedRoute;