import React, { useEffect, useState, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, 
  Route,
  Routes,
  Switch
} from "react-router-dom";
import getWeb3 from "./utils/getWeb3";
import Header from "./components/Header/index";
import Home from "./pages/Home/index";
import CreateNFT from "./pages/CreateNFT/index";
import CreateGaslessNFT from "./pages/CreateGaslessNFT/index";
import CreateSupport from "./pages/Support/CreateSupport/createSupport";
import SupportTicketDetails from "./pages/Support/SupportTicketDetails/SupportTicketDetails";

import MakeFNFT from "./pages/MakeFNFT/index";
import MyNFT from "./pages/MyNFT/index";
import MyFNFT from "./pages/MyFNFT/index";
import AllNft from "./pages/AllNft/index";
import About from "./pages/About/index";
import Layer from "./pages/CreatenftLayer/index";
import FAQ from "./pages/FAQ/index";
import Careers from "./pages/Careers/index";
import AllFNft from "./pages/AllFNft/index";
import Item from "./pages/Item/index";
import GaslessItem from "./pages/GaslessNftItem/index";
import ItemFNFT from "./pages/ItemFNFT/index";
import CategoryWiseNFT from "./pages/CategoryWiseNFT/index";
import CreateCollection from "./pages/CreateCollection/createCollection";
import MyCollection from "./pages/MyCollection/MyCollection";
import CollectionWiseNft from "./pages/CollectionWiseNft/CollectionWiseNft";
import AllCollectionsPage from "./pages/AllCollectionsPage/AllCollectionsPage";

import HomeGenerator from './NFT-Generator/Components/Home/Home';
import Generator from './NFT-Generator/Components/Generator/Generator';
import Login from './NFT-Generator/Components/Authentication/Login';
import SignUp from './NFT-Generator/Components/Authentication/SignUp';
import { LoginContext } from './NFT-Generator/Contexts/LoginContext';
import loaderCircle from './assets/loader-circle.gif';
import Underconstruction from "./pages/UnderConstruction/index";
import Footer from "./components/Footer/index";
import SearchNFT from "./pages/SearchNFT/index";
import EditProfile from "./pages/User/EditProfile/EditProfile";
import ViewProfile from "./pages/User/ViewProfile/index";
import Web3 from "web3";
import Terms from "./pages/Terms/index"
import HelpCenter from "./pages/HelpCenter/index"
import ProtectedRoute from "./protectedRoute";
import FractionalizeNFT from "./contracts/FractionalizeNFT.json";
import ArtMarketplace from "./contracts/ArtMarketplace.json";
import ArtMarketplaceWithRoyalityV2 from "./contracts/ArtMarketplaceWithRoyalityV2.json";
import LazyNFT from "./contracts/LazyNFT.json";
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import "./App.css";
import { UserContext } from "./UserContext";
import {
  defaultNetworkRPC_URL,
  defaultNetworkBlockExploreUrl,
  defaultNetworkCurrencySymbol,
  api,
  defaultAccount,
  usdRate,
  defaultChainId,
  defaultNetworkType,
  defaultNetworkName
} from "./services/api";
import {
  setNft,
  setFNft,
  setAccount,
  setIsConnected,
  // setfractionalizerContract,
  setfractionalizeNFTContract,
  setUsdRate,
  setBalance,
  setMarketContract,
  // setfractionsImplContract,
  //setMarketContractGasLessV2,
  setMarketContractGasLess,
} from "./redux/actions/nftActions";

function App() {
  
  const dispatch = useDispatch();
  const [defaultChain_ID, setDefaultChainId] = useState(defaultChainId);
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const [networkTypeName, setNetworkType] = useState('');
  const [totalBalance, setTotalBalance] = useState('0');
  const [myAccount, setMyAccount] = useState('');
  const [defaultNetworkTypeName, setDefaultNetworkType] = useState(defaultNetworkType);
  let [isConnectNetwork, setIsConnectNetwork] = useState(false);
  let itemsList = [];
  const [isNetworkChangeError, setIsNetworkChangeError] = useState(false);
  
  const [isConnected, setIsConnectedAccount] = useState(false);

   // if change account then reload page
   if (window.ethereum != undefined) {
      window.ethereum.on('chainChanged', async () => {
        
        sessionStorage.setItem('isConnected', false);
        await checkNetworkConnection();
        window.location.reload(true);
      })
      window.ethereum.on('accountsChanged', async function (accounts) {
        
        sessionStorage.setItem('isConnected', false);
        await checkNetworkConnection();
        
        window.location.reload(true);
      })
    }


    // need to change something on publish different network
  const connectToNetwork = async () => {

    const ethereum = window.ethereum;
    try {
      let data = await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: defaultChain_ID }],
      });

    } catch (switchError) {
      if (switchError.code == 4902) {
        let param = {
          chainId: defaultChainId,
          chainName: defaultNetworkName,
          nativeCurrency: {
            name: defaultNetworkName,
            symbol: defaultNetworkCurrencySymbol,
            decimals: 18
          },
          rpcUrls: [defaultNetworkRPC_URL],
          blockExplorerUrls: [defaultNetworkBlockExploreUrl]
        }
        try {
          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [param],
          });
        } catch (addError) {
          setIsNetworkChangeError(true);
        }


      }

    }
  }

  useEffect(() => {
    const getDataFromDatabase = async () => {
      const allNft = await api
          .get(`/tokens/`)
          .catch((err) => {
          });

        itemsList = Object.values(allNft.data);

        const allfNft = await api
          .get(`/getAllfnft/`)
          .catch((err) => {
          });

        let fnftItemsObject = Object.values(allfNft.data);

        // remove if Nft to fnft
        for (let i = 0; i < fnftItemsObject.length; i++) {
          itemsList = itemsList.filter(function (obj) {
            return obj.tokenId !== fnftItemsObject[i].tokenId;
          });
        }

        dispatch(setNft(itemsList));
        dispatch(setFNft(fnftItemsObject));
    }

    getDataFromDatabase();


    const getUsdRate = async () => {
      try {
        let response = await usdRate;
        setUsdRate(response);
        dispatch(setUsdRate(response.data.USD));
      } catch (error) {
      }
    };
    getUsdRate();


  }, []);




  useEffect(() => {
    async function fn(){
      const web3 = await getWeb3();
      let accounts = await web3.eth.getAccounts();
      console.log(accounts)
    }

    fn();
    setIsConnectedAccount(isConnected)
  }, [isConnected]);

  useEffect(() => {
    setIsWrongNetwork(isWrongNetwork)
  }, [isWrongNetwork]);

  

  const checkNetworkConnection = async () => {
    
    console.log('got this function');
    const web3 = await getWeb3();
    let acc = defaultAccount;
    sessionStorage.setItem('myAccount', defaultAccount);
    if (window.ethereum != undefined) {
      let isUnlock = await window.ethereum._metamask.isUnlocked();
      if (isUnlock) {
        let networkType = await web3.eth.net.getNetworkType();
        let currentNetworkId = await web3.eth.net.getId();
        let currentNetworkIdHex = "0x" + currentNetworkId.toString(16);
        console.log("network typeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
        console.log(networkType);
        if (currentNetworkIdHex != defaultChain_ID) {
          setIsWrongNetwork(true);
        }
        setNetworkType(networkType);
        let accounts = await web3.eth.getAccounts();
        if (accounts[0] != defaultAccount && currentNetworkIdHex === defaultChain_ID) {
          acc = accounts[0];
          setMyAccount(accounts[0]);
          sessionStorage.setItem('myAccount', accounts[0]);
          dispatch(setIsConnected(true));
          setIsConnectNetwork(true);
          setIsConnectedAccount(true);
          sessionStorage.setItem('isConnected', true);
          
        } else {
          sessionStorage.setItem('isConnected', false);
          sessionStorage.setItem('myAccount', '');
          dispatch(setIsConnected(false));
          setIsConnectedAccount(false);
          setIsConnectNetwork(false);
          setMyAccount(defaultAccount);
        }
      }else{
        sessionStorage.setItem('isConnected', false);
        sessionStorage.setItem('myAccount', '');
      }
    }else{
      sessionStorage.setItem('isConnected', false);
    }
    const networkId = await web3.eth.net.getId();
    
    dispatch(setAccount(acc));
    
  }

  const getAbiData = async () => {
    const web3 = await getWeb3();
    let currentNetworkId = await web3.eth.net.getId();
    const marketplaceContract = new web3.eth.Contract(
      ArtMarketplaceWithRoyalityV2.abi,
      ArtMarketplaceWithRoyalityV2.networks[currentNetworkId].address
    );

    const totalItemsForSale = await marketplaceContract.methods
      .GetSaleItem()
      .call();

    const marketLazyContract = new web3.eth.Contract(
      LazyNFT.abi,
      LazyNFT.networks[currentNetworkId].address
    );
    const fractionalizeNFTContract = new web3.eth.Contract(
      FractionalizeNFT.abi,
      FractionalizeNFT.networks[currentNetworkId].address
    );
    dispatch(setfractionalizeNFTContract(fractionalizeNFTContract));
    dispatch(setMarketContract(marketplaceContract));
        dispatch(setMarketContractGasLess(marketLazyContract));
    await web3.eth.getBalance(sessionStorage.getItem('myAccount'), (err, balance) => {
      dispatch(setBalance(balance));
      sessionStorage.setItem('balance', JSON.stringify(balance));
    });

    
  }








  return (
    <div className="App">
      <Router>
        <Header isConnected={isConnected} checkNetworkConnection={checkNetworkConnection} getAbiData={getAbiData} />
        <main>
          <Switch>
          <Route path="/" exact component={Home} />
          {/* <ProtectedRoute path="/create-nft" component={CreateNFT} /> */}
            <Route path="/create-nft" exact component={CreateNFT} getAbiData={getAbiData} />
          <Route path="/create-Gaslessnft" exact component={CreateGaslessNFT} />
            
          <Route path="/create-Support-ticket" exact component={CreateSupport} />
          <Route path="/support/:ticketId" exact component={SupportTicketDetails} />
          
          <Route path="/make-fnft" exact component={MakeFNFT} />
          <Route path="/search/:searchString" exact component={SearchNFT} />
          <Route path="/edit-profile" exact component={EditProfile} />
          <Route path="/view-profile/:account" exact component={ViewProfile} />
            <Route path="/nft/:nftId" exact component={Item} getAbiData={getAbiData} />
          <Route path="/Gaslessnft/:nftId" exact component={GaslessItem} />
            
          <Route path="/fnft/:nftId/:ownerAddress" exact component={ItemFNFT} />
          <Route path="/nft-by-category/:categoryID" exact component={CategoryWiseNFT} />
          <Route path="/mynft" exact component={MyNFT} />
          <Route path="/myfnft" exact component={MyFNFT} />
          <Route path="/all-nft" exact component={AllNft} />
          <Route path="/all-fnft" exact component={AllFNft} />
          <Route path="/underconstruction" exact component={Underconstruction} />
          <Route path="/terms" exact component={Terms} />
          <Route path="/helpcenter" exact component={HelpCenter} />
            <Route path="/About" exact component={About} />
          <Route path="/Layer" exact component={Layer} />
            
          <Route path="/faq" exact component={FAQ} />
          <Route path="/Careers" exact component={Careers} />
          <Route path="/CreateCollection" exact component={CreateCollection} />
          <Route path="/myCollection" exact component={MyCollection} />
          <Route path="/collection/:slug" exact component={CollectionWiseNft} />
          <Route path="/all-collection" exact component={AllCollectionsPage} />
          
          
          <Route path='/generator-home' exact component={HomeGenerator} />
          <Route path='/generate/:generatorId' exact component={Generator} />
          <Route path='/login' exact component={Login} />
          <Route path='/signup' exact component={SignUp} />
          <Route>404 Not Found!</Route>
          </Switch>
        </main>
        <Footer isConnected={isConnected} checkNetworkConnection={checkNetworkConnection} getAbiData={getAbiData} />
      </Router>


      {isWrongNetwork ? (
        <div className="modal active">
          <div className="modal-content">
            {!isNetworkChangeError ? (
              <div className="wrong-alart">
                <img style={{ height: '50px', width: '50px', marginBottom: '10px' }} src={loaderCircle} />
                <Alert severity="warning">Sorry You are in wrong network</Alert>

                <Button style={{ marginTop: "10px" }} onClick={connectToNetwork} variant="contained" disableElevation>
                  Click to connect right network
                </Button>
              </div>
            ) : (
              <div className="wrong-alart">
                <Alert severity="error">
                  <h6>Sorry! you do not configure {defaultNetworkName} network in metamask. </h6>
                  <p>Please configure right network first.</p>
                </Alert>
              </div>
            )}
          </div>
        </div>
      ) : ("")}

      {/* <LoginContext.Provider value={{user, setUser}}>
          <Router>
            <Switch>
              <Route path='/generator' element={<HomeGenerator />} />
              <Route path='generate/:generatorId' element={<Generator />} />
              <Route path='login' element={<Login />} />
              <Route path='signup' element={<SignUp />} />
            </Switch>
          </Router>
      </LoginContext.Provider> */}
    </div>
  );
}

export default App;
