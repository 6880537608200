import React from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";

import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { styles } from "./styles.css";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
import image from "../../assets/cover-img4.jpg"
import starIco from "../../assets/2.png"
import { imgBaseUrl } from "../../services/api";



const LatestNft = ({ latestNft, title, coloumn }) => {
    const params = {
        // pagination: {
        //   el: '.swiper-pagination',
        //   type: 'bullets',
        //   clickable: true
        // },
        
        autoplay: true,
        slidesPerView:4,
        spaceBetween: 10,

        breakpoints: {
            // when window width is >= 320px
            320: {
              slidesPerView: 1
            },
            // when window width is >= 480px
            576: {
              slidesPerView: 1
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 4
            }
          }
      }
      SwiperCore.use([Autoplay]);
      
    
    return (
        
        <section className="exclusive-drops">
                <div className="container">
                    {/* <div className="row">
                        <div className="col-12">
                            <div className="section-flex">
                                <div className="section-header">
                                    <h3>🔥 {title}</h3>
                                </div>
                               
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="drop-second" style={{overflow: 'hidden'}}>
                        <div className="row">
                        <Swiper  {...params}>
                        {latestNft.map((nft) => (
                            <div className={coloumn} key={nft.tokenId}>
                                <Link to={`/nft/${nft.tokenId}`}>
                                    <div className="drop-section">
                                        <div className="drop-img">
                                            <img src={nft.image} alt="" />
                                        </div>
                                        <div className="drop-content">
                                            <h4 className="title-name">
                                                {nft.name.slice(0, 14)}
                                                {nft.name.length > 14 ? (
                                                    <small>...</small>
                                                ):(
                                                    <small></small>
                                                )}
                                            </h4>
                                            <Typography className="price" variant="h6">
                                                <SvgIcon
                                                    component={EthereumLogo}
                                                    viewBox="0 0 400 426.6"
                                                    titleAccess="ETH"
                                                />
                                                <span>
                                                
                                               
                                                    {Web3.utils.fromWei(String(nft.price), "ether")} 
                                                </span>
                                            </Typography>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            ))}

                        </Swiper>
                        </div>
                        
                    </div> */}
                    <div className="row">
                    <div className="section-title">
                        <div className="title-img">
                            <img src={starIco} />
                        </div>
                        <div className="title" >
                            <h2> {title}</h2>
                        </div>
                    </div>
                </div>
                
                <div className="topartist_slider ">
                    <div className="swiper exclusiveDrops pt45">
                        <div className="swiper-wrapper">
                        <Swiper  modules={[Navigation, Autoplay]}
                        // spaceBetween={15}
                        // slidesPerView={4}
                        navigation
                        // autoplay= {true}
                        { ...params }
                        >
                        {latestNft.map((nft) => (
                            <SwiperSlide key={'latest'+nft.tokenId}>
                                <div>
                                <Link to={`/nft/${nft.tokenKey}`}>
                                <div className="swiper-slide">
                                    <div className="nft_pic">
                                        
                                            <span className="nft_pic_info">
                                                <span className="nft_pic_title">{nft.name.slice(0, 14)}
                                                    {nft.name.length > 14 ? (
                                                        <small>...</small>
                                                    ):(
                                                        <small></small>
                                                    )}</span>
                                                    {/* <Typography className="price" variant="h6">
                                                    <SvgIcon
                                                        component={EthereumLogo}
                                                        viewBox="0 0 400 426.6"
                                                        titleAccess="ETH"
                                                        color="white"
                                                    />
                                                    <span>
                                                    
                                                
                                                        {Web3.utils.fromWei(String(nft.price), "ether")} 
                                                    </span>
                                                </Typography> */}
                                            </span>
                                        <div className="nft_pic_wrap">
                                            <img src={nft.imagelistLocal?.image_2} className="lazy img-fluid" alt=""/>
                                        </div>
                                        </div>
                                </div>
                                </Link>
                                </div>
                            </SwiperSlide>
                            ))}
                            {/* <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div>
                            <div className="swiper-slide">
                                <div className="nft_pic">
                                    <a href="item-details.html">
                                        <span className="nft_pic_info">
                                            <span className="nft_pic_title">Glass Cube</span>
                                        </span>
                                    </a>
                                    <div className="nft_pic_wrap">
                                        <img src={image} className="lazy img-fluid" alt=""/>
                                    </div>
                            </div>
                            </div> */}
                            </Swiper>

                            </div>
                            
                            </div>
                            <div style={{display: 'none'}} className="swiper-button-prev sliderBtn sliderBtn-prev"><i className="fas fa-angle-left"></i></div>
                            <div  style={{display: 'none'}} className="swiper-button-next sliderBtn sliderBtn-next"><i className="fas fa-angle-right"></i></div>
                            
                            
                            </div>
                           
                         </div> 
          
            </section>
        
    );
};

export default LatestNft;
