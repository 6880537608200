import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CancelOutlinedIcon  from "@material-ui/icons/CancelOutlined";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';

import DropZone from "../../../components/DropZone";

import { api } from "../../../services/api";
import { useStyles } from "./styles.js";
import { mystyle } from "./styles.css";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import getWeb3 from "../../../utils/getWeb3";
import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
} from "../../../redux/actions/nftActions";
const EditProfile = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const account = sessionStorage.getItem('myAccount');
  
 

  const [selectedFile, setSelectedFile] = useState();
  const [message, setMessage] = useState("");
  const [isLoaderShow, setIsLoaderShow] = useState(false);
  const [classOfStatus, setClassOfStatus] = useState("");
  const [userImage, setUserImage] = useState("");

  const [formData, setFormData] = useState({
    userName: "",
    bio: "",
    fbLink: "",
    twitterLink: "",
    instagramLink: "",
  });


  useEffect(() => {
    const init = async () => {
      try {
        let getAcc = sessionStorage.getItem('myAccount');
        if(getAcc == ''){
          const web3 = await getWeb3();
          let accounts = await web3.eth.getAccounts();
          getAcc = accounts[0];
        }
        if ( getAcc == '') {
          alert("Please login with Metamask!");
          console.log("login to metamask");
        }else{
          const response = await api
            .get(`/saveUser/${getAcc}`)
            .catch((err) => {
              console.log("Err: ", err);
            });

            if(response.statusText = "OK"){
              if(response.data != null){
                let data = response.data;
                setUserImage(data.image);
                setFormData(data);
              }
            }
          
        }
        
      } catch (error) {
        
      }
    };
    init();
    
  }, []);

  function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  async function editProfile(event) {
    event.preventDefault();
    setIsLoaderShow(true);
    const { userName, bio, fbLink, twitterLink, instagramLink } = formData;

    // console.log("bio: " + bio);
    // console.log("fbLink: " + fbLink);
    // console.log("twitterLink: " + twitterLink);
    // console.log("instagramLink: " + instagramLink);
    const data = new FormData();
    data.append("userName", userName);
    data.append("address", account);
    data.append("bio", bio);
    
    data.append("fbLink", fbLink);
    data.append("twitterLink", twitterLink);
    data.append("instagramLink", instagramLink);
    if(selectedFile){
      data.append('img', selectedFile);
      console.log("slectedFile: ", selectedFile);
    }else{
      let message = "Please Add Profile Picture";
      setMessage(message);
      setClassOfStatus("warning");
      setIsLoaderShow(false);
      return;
    }

    try {
      const response = await api.post("/saveUser", data, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      });
      console.log(response);
      if(response.status){
        setIsLoaderShow(false);
        if(response.statusText = "Created"){
          let message = "Created successfully!";
          setMessage(message);
          setClassOfStatus("success");
        }else{
          let message = "Error to created profile!";
          setMessage(message);
          setClassOfStatus("error");

        }
        window.location.reload();
      }
    } catch (error) {
      setIsLoaderShow(false);
      console.log(error);
      // error.response.data
    }
  }



  return (
    <div className={classes.pageCreateNft}>
      {/* <div className="container">
        
        <form onSubmit={editProfile}>
          
          <div className={classes.content}>
            <div className="container rounded bg-white mt-5">
                <div className="row">
                    <div className="col-md-2 border-right">
                        <div className={classes.dropzone}>
                        {userImage != "" ? <img style={{maxWidth: '100%', width: '100%'}} src={userImage} /> : <span></span> }
                        {userImage != "" ? <DropZone name="Change Image" onFileUploaded={setSelectedFile} /> : <DropZone name="Upload Profile Image" onFileUploaded={setSelectedFile} /> }
                         
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="p-3">
                            <div className="d-flex justify-content-between align-items-center mb-3">
                                <h6 className="text-right">Edit Profile</h6>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 mb-2">
                                  
                                <TextField
                                    label="User Name"
                                    name="userName"
                                    variant="filled"
                                    required
                                    value={formData.userName}
                                    onChange={handleInputChange}
                                    
                                />
                                </div>
                                <div className="col-md-12 mb-2">
                                    <TextField
                                        id="outlined-multiline-static"
                                        multiline
                                        rows={4}
                                        label="Bio"
                                        name="bio"
                                        variant="filled"
                                        required
                                        value={formData.bio}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 mb-2">
                                    Social Links
                                </div>
                                <div className="col-md-4 mb-2">
                                    <TextField
                                        label="Facebook Link"
                                        name="fbLink"
                                        variant="filled"
                                        
                                        value={formData.fbLink}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <TextField
                                        label="Twitter Link"
                                        name="twitterLink"
                                        variant="filled"
                                        
                                        value={formData.twitterLink}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </div>
                                <div className="col-md-4 mb-2">
                                    <TextField
                                        label="Instagram Link"
                                        name="instagramLink"
                                        variant="filled"
                                        
                                        value={formData.instagramLink}
                                        onChange={handleInputChange}
                                        fullWidth
                                    />
                                </div>
                                
                            </div>

                           
                            <div className="mt-5 text-right">
                            <Button variant="contained" color="primary" type="submit">
                                Submit
                            </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </form>
      </div> */}


      <div className="container" style={{marginTop: "20px"}}>
        
        {isLoaderShow ? <Loader/> : <span></span> }
        <div className="main-body" style={{ margin: '50px 0' }}>
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  <div className="d-flex flex-column align-items-center text-center">
                    {userImage != "" ? <img style={{ width: '110px'}} src={userImage} /> : <span></span> }
                    
                    <div className="mt-3">
                      <h4>{formData.userName}</h4>
                      <p className="text-muted font-size-sm">{formData.bio}</p>
                    </div>
                  </div>
                  <hr className="my-4" />
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item justify-content-between flex-wrap">
                      <a href={formData.fbLink} className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-10"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-facebook me-2 icon-inline text-primary"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>Facebook</h6>
                        <span className="text-secondary">{formData.fbLink}</span>
                      </a>
                    </li>
                    <li className="list-group-item justify-content-between flex-wrap">
                      <a href={formData.twitterLink} className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-10"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-twitter me-2 icon-inline text-info"><path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path></svg>Twitter</h6>
                        <span className="text-secondary">{formData.twitterLink}</span>
                      </a>
                    </li>
                    <li className="list-group-item justify-content-between flex-wrap">
                      <a href={formData.instagramLink} className="d-flex justify-content-between align-items-center">
                        <h6 className="mb-10"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-instagram me-2 icon-inline text-danger"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>Instagram</h6>
                        <span className="text-secondary">{formData.instagramLink}</span>
                      </a>
                    </li>
                    
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              {message != "" ?  <Alert severity={classOfStatus}>{message}</Alert> : <span></span> }
              <form style={{marginTop: '0'}} onSubmit={editProfile}>
                <div className="card">
                  <div className="card-body myProfile">
                    
                    <div className="row mb-3">
                      <div className="col-sm-9 text-secondary">
                        <div className="mb-20">
                          <h6 className="mb-10">User Name :</h6>
                          <input type="text"  onChange={handleInputChange} name="userName" required className="form-control" value={formData.userName} />
                        </div>
                        <div className="mb-20">
                          <h6 className="mb-10">Bio: </h6>
                          <textarea rows="6" type="text" name="bio" className="form-control" required value={formData.bio} onChange={handleInputChange}></textarea>
                        </div>
                      </div>
                      <div className="col-sm-3 text-secondary">
                        <div style={{marginBottom: '15px', marginTop: '24px'}}>
                          {userImage ? <DropZone required name="Change Image" onFileUploaded={setSelectedFile} /> : <DropZone required name="Upload Profile Image" onFileUploaded={setSelectedFile} /> }
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-sm-3">
                        <h6 className="mb-10">Social Links</h6>
                      </div>
                      <div className="col-sm-12 text-secondary mt-10">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Facebook Link</label>
                            <input className="form-control" name="fbLink" value={formData.fbLink} onChange={handleInputChange} />
                          </div>
                          <div className="col-md-4">
                            <label>Twitter Link</label>
                            <input className="form-control" name="twitterLink" value={formData.twitterLink} onChange={handleInputChange} />
                          </div>
                          <div className="col-md-4">
                            <label>Instagram Link</label>
                            <input className="form-control" name="instagramLink" value={formData.instagramLink} onChange={handleInputChange} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 text-secondary" style={{textAlign: 'right'}}>
                        <input type="submit" className="btn btn-primary px-4" value="Save Changes" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default EditProfile;
