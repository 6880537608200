import React, { useEffect, useState } from "react";
import Swiper from 'react-id-swiper';
import { useDispatch, useSelector } from "react-redux";
import { Card as MuiCard } from "@material-ui/core";
import veterans from "../../assets/arts/Sparse-Ahmed-Mostafa-vetarans-2.jpg";
import { CardContent } from 'semantic-ui-react';
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardActionArea from "@material-ui/core/CardActionArea";
import { swiperstyles } from './swiper-slider.min.css';
import { styles } from './styles.css';
import { api } from "../../services/api";
import { Link, useHistory } from "react-router-dom";
import topSellerIcon from "../../assets/1.png";
import Bgshape2 from "../BGShape2/index";

const Slider = (nftData) => {
  const params = {
    // pagination: {
    //   el: '.swiper-pagination',
    //   type: 'bullets',
    //   clickable: true
    // },
    autoplay: true,
    slidesPerView: 5,
    spaceBetween: 15
  }
  const dispatch = useDispatch();



    let nftItems = nftData;
    const [topSallerData, setTopSallerData] = useState([]);
    useEffect(() => {
        
        const init = async () => {
            
            let topseller=[];
            if(nftData.nftData.length > 0){
            //     
                for (let i = 0; i < nftData.nftData.length; i++) {
                    if(nftData.nftData[i].isSold){
                        let ownerId = nftData.nftData[i].owner;
                        try {
                            const userInfo = await api
                            .get(`/saveUser/${ownerId}`)
                            .catch((err) => {
                                console.log("Err: ", err);
                            });

                            if (typeof userInfo != undefined) {
                                if(userInfo.statusText == "OK" && userInfo.data != ""){
                                    topseller.push(userInfo.data); 
                                }
                            }
                            
                            
                            
                        } catch (error) {
                            console.log(error);
                        }
                    }
                    
                }
            }
            setTopSallerData(topseller);
         
        }
    
        init();

    }, [dispatch]);
  
  
  

  return(
    // <div className="my-swiper">
    //     {Object.keys(topSallerData).length > 0 ? (
    //     <section className="topseller-section padding-bottom">
    //         <div className="container">
    //             <div className="row">
    //                 <div className="col-12">
    //                     <div className="section-header">
    //                         <h3>🌈 Top Seller</h3>
    //                     </div>
    //                 </div>
    //             </div>

    //             <div className="section-wrapper">
    //                 <div className="nft-sell-wrapper">
    //                     <div className="row gx-4 gy-3">
    //                         <div className="col-12 slideMain">
    //                             <Swiper {...params}>
                                
    //                                 {topSallerData.map((seller, i) => (
    //                                     <div className="swiper-slide" key={seller.address}>
    //                                         <div className="nft-item">
    //                                             <div className="nft-inner">
    //                                                 <Link to={`/view-profile/${seller.address}`}>
    //                                                     <span className="topNumber">{i+1}</span>
    //                                                     <div className="nft-content">
    //                                                         <div className="author-thumb">
    //                                                             <div className="veryfied">
    //                                                                 <img src={seller.image} alt="author-img" />
    //                                                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    //                                                                     <path d="M13.9144 12.7646C14.0077 13.533 13.3419 14.1783 12.5768 14.0608L10.9348 13.8087C10.5173 13.7446 10.0978 13.9107 9.83735 14.2432L8.81298 15.551C8.33567 16.1604 7.40855 16.1458 6.95061 15.5218L5.96782 14.1824C5.71796 13.8419 5.30382 13.6627 4.88456 13.7137L3.23548 13.9141C2.46708 14.0075 1.82181 13.3416 1.93927 12.5765L2.19137 10.9345C2.25547 10.5171 2.08935 10.0975 1.75686 9.83707L0.449081 8.8127C-0.160286 8.33538 -0.145722 7.40826 0.478337 6.95033L1.81765 5.96754C2.15815 5.71768 2.33737 5.30354 2.28642 4.88427L2.08602 3.2352C1.99264 2.4668 2.65851 1.82152 3.4236 1.93899L5.06557 2.19109C5.48303 2.25518 5.90259 2.08907 6.16303 1.75658L7.1874 0.448798C7.66471 -0.160569 8.59183 -0.146005 9.04976 0.478054L10.0326 1.81736C10.2824 2.15787 10.6966 2.33708 11.1158 2.28613L12.7649 2.08574C13.5333 1.99236 14.1786 2.65823 14.0611 3.42332L13.809 5.06528C13.7449 5.48274 13.911 5.9023 14.2435 6.16274L15.5513 7.18711C16.1607 7.66442 16.1461 8.59154 15.522 9.04948L14.1827 10.0323C13.8422 10.2821 13.663 10.6963 13.714 11.1155L13.9144 12.7646Z" fill="url(#paint0_linear)"/>
    //                                                                     <path d="M6.93353 8.62215L5.15575 7.55549L4.44464 8.2666L6.93353 11.111L11.5558 6.48882L10.8446 5.77771L6.93353 8.62215Z" fill="white"/>
    //                                                                     <defs>
    //                                                                         <linearGradient id="paint0_linear" x1="21.1974" y1="7.44855" x2="3.34965" y2="-1.26427" gradientUnits="userSpaceOnUse">
    //                                                                             <stop stopColor="#2CF9F9"/>
    //                                                                             <stop offset="0.354167" stopColor="#397DFF"/>
    //                                                                             <stop offset="0.557292" stopColor="#3858FF"/>
    //                                                                             <stop offset="0.791667" stopColor="#B14FFF"/>
    //                                                                             <stop offset="1" stopColor="#FFA0D9"/>
    //                                                                             <stop offset="1" stopColor="#FFBEE5"/>
    //                                                                         </linearGradient>
    //                                                                     </defs>
    //                                                                 </svg>
    //                                                             </div>
    //                                                         </div>
    //                                                         <div className="author-details d-flex flex-wrap align-items-center gap-15">
                                                                
    //                                                             <div className="author-det-info">
    //                                                                 <h5>{seller.userName}</h5>
    //                                                                 {/* <p className="nft-price yellow-color">$23,002.98</p> */}
    //                                                             </div>

    //                                                         </div>
    //                                                     </div>
    //                                                 </Link>
    //                                             </div>
    //                                         </div>
    //                                     </div>
    //                                 ))}
    //                             </Swiper>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </section>
    //     ):(
    //         <span></span>
    //       )}
    // </div>
    <div>
    {Object.keys(topSallerData).length > 0 ? (
    <section className="top-artists">
        <Bgshape2/>
            <div className="container">
            <div className="row">
                    <div className="section-title">
                        <div className="title-img">
                            <img src={topSellerIcon} />
                        </div>
                        <div className="title">
                            <h2>Top Seller</h2>
                        </div>
                    </div>
                </div>
                <div className="topartist_slider">
                    <div className="swiper topArtists pt45">
                        <div className="swiper-wrapper">
                        <div className="swiper-slide">
                        <Swiper {...params}>
                        {topSallerData.map((seller, i) =>(
                            <Link to={`/view-profile/${seller.address}`} key={i+1}>
                                <div className="top-sellers-item">
                                    
                                    <div className="number">{i+1}</div>
                                    <div className="top-sellers-img">
                                        <span>
                                            <img src={seller.image} alt="Images"/>
                                        </span>
                                        
                                    </div>
                                    <div className="content">
                                        <h3>{seller.userName}</h3>
                                        {/* <span>129.301 ETH</span> */}
                                    </div>
                                    
                                </div>
                                </Link>
                        ))}
                        </Swiper>
                            </div>
                       
                        </div>
                    </div>
                </div>
            </div>
    </section>
    ):(
        <span></span>
      )}
    </div>

  )
}

export default Slider;