import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../services/api";
import Loader from "../../components/Loader/Loader";
import $ from 'jquery';
import { cssStyles } from "./styles.css";
import getWeb3 from "../../utils/getWeb3";

const MyCollection = () => {
    const account = sessionStorage.getItem('myAccount');
    
    const [isload, setIsload] = useState(false);
    const [myAccount, setMyAccount] = useState(account);
    const [myAllCollection, setMyAllCollections] = useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        
        const init = async () => {
          try {
            
            
            let myacc = account;
            // if(account != ''){
            //     myacc = account;
            // }else{
            //     const web3 = await getWeb3();
            //     let accounts = await web3.eth.getAccounts();
            //     myacc = accounts[0];
            // }
           
            setMyAccount(myacc);
             const responses = await api
                .get(`/getUserwiseCollection/${myacc}`)
                .catch((err) => {
                    console.log("Err: ", err);
                });
                let allCollection = Object.keys(responses.data).map((key) => responses.data[key]);
                setMyAllCollections(allCollection);
                console.log(allCollection);
                setIsload(false);
            
            
          } catch (error) {
            setIsload(false);
            console.error("Error,single: ", error);
          }
        }
    
        init();
    
    }, [dispatch]);

    return (
        <>
            {isload ? <Loader /> : <span></span>}
            <section className="my-4">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="collection-div">
                                <h1>My Collections</h1>
                                <div className="manage d-flex align-items-center">
                                    <p style={{marginBottom: '0'}}>
                                        Create, curate, and manage collections of unique NFTs to share and sell.
                                    </p>
                                </div>
                                <div className="create_collection d-flex align-items-center">
                                    <a href="/CreateCollection">Create a Collection</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="trending-collection">
                <div className="container-fluid">
                    <div className="row g-4">
                    {Object.keys(myAllCollection).map((key) => (
                        <div key={'my-collection' + key} className="col-xl-3 col-lg-4">
                            <a style={{display: "block"}} href={`/collection/${myAllCollection[key].slag}`}>
                                <div className="collection-item">
                                    <div className="collection-item-img" style={{height: "200px", width: "100%"}}>
                                        <img src={myAllCollection[key].featureImage} alt="" className="img-fluid" />
                                    </div>
                                    <div className="collection-content">
                                        <div className="content-img">
                                            <img src={myAllCollection[key].logoImage} alt="" />
                                        </div>
                                        <div className="collection-content-h4">
                                            <h4>{myAllCollection[key].collectionName}</h4>
                                        </div>
                                        <div className="collection-content-h6">
                                            <h6>by 
                                                {/* <Link to={`/view-profile/${myAllCollection[key].address}`}>{myAllCollection[key].address.slice(0, 7) + "..." + myAllCollection[key].address.slice(-4)}</Link> */}
                                                {myAllCollection[key].address.slice(0, 7) + "..." + myAllCollection[key].address.slice(-4)}
                                            </h6>
                                        </div>
                                        <div className="collection-content-p text-center">
                                            <p>{myAllCollection[key].Description.slice(0, 40) + "..." }</p>
                                        </div>
                                        {/* <div className="ethereun-div">
                                            <button type="button" className="">
                                                <svg fill="gray" viewBox="-38.39985 -104.22675 332.7987 625.3605" style={{width: '12px'}}><path d="M125.166 285.168l2.795 2.79 127.962-75.638L127.961 0l-2.795 9.5z"></path><path d="M127.962 287.959V0L0 212.32z"></path><path d="M126.386 412.306l1.575 4.6L256 236.587l-128.038 75.6-1.575 1.92z"></path><path d="M0 236.585l127.962 180.32v-104.72z"></path><path d="M127.961 154.159v133.799l127.96-75.637z"></path><path d="M127.96 154.159L0 212.32l127.96 75.637z"></path>
                                                </svg>
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default MyCollection;