import React, { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Web3 from "web3";
import Button from '@material-ui/core/Button';
import SearchGlobal from './../Search/index';
import SimpleDialogDemo from './../AccountPopUp/index';
import Alert from '@material-ui/lab/Alert';
import { useStyles } from './styles.js';
import { headerStyles } from './styles.css'
import logo from '../../assets/theBITLabs-Logo/metawirks.png';
import graphIco from '../../assets/graph.png';
import allnftsLight from '../../assets/allnfts-light.svg';
import walletIco from '../../assets/wallet.png';
import { CopyToClipboard } from "react-copy-to-clipboard";
import nftIco from '../../assets/symbol.png';
// import FractionsImpl from "../../contracts/FractionsImpl.json";
import getWeb3 from "../../utils/getWeb3";
// import FractionalizeNFT from "../../contracts/FractionalizeNFT.json";
// import Fractionalizer from "../../contracts/Fractionalizer.json";
// import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import { api, defaultAccount, usdRate, defaultChainId, defaultNetworkType } from "../../services/api";
import {
  setBalance,
} from "../../redux/actions/nftActions";
import { Dropdown } from 'react-bootstrap';
import { MdContentCopy } from "react-icons/md";

const Header = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [userImage, setUserImage] = useState("");
  const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [defaultChain_ID, setDefaultChainId] = useState(defaultChainId);
  const [isWrongNetwork, setIsWrongNetwork] = useState(false);
  const [networkTypeName, setNetworkType] = useState('');
  const [balance, setMyBalance] = useState('0');

  const [defaultNetworkTypeName, setDefaultNetworkType] = useState(defaultNetworkType);
  let account = useSelector((state) => state.allNft.account);
  const [myAccount, setMyAccount] = useState(account);
  const [allCatagories, setAllCatagories] = useState([]);
  const [isConnected, setIsConnectedAccount] = useState(props.isConnected);

  let itemsList = [];
  const [isCopied, setIsCopied] = useState(false);
  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };


  useEffect(() => { 
    

    
    
    const initConnetion = async () => {
        let isCon = JSON.parse(sessionStorage.getItem('isConnected'));

        if(!isCon){
          let test = await props.checkNetworkConnection();
          isCon = JSON.parse(sessionStorage.getItem('isConnected'));
          setIsConnectedAccount(isCon);
        }else{
          setIsConnectedAccount(isCon);
          await props.getAbiData();
          
        }
        
    }
    initConnetion();
    
    

   }, []);

   useEffect(() => {
    setMyBalance(JSON.parse(sessionStorage.getItem('balance')));
   }, [balance]);

   
 


  useEffect(() => {
    
    const init = async () => {
      let getAcc = sessionStorage.getItem('myAccount');
      
      if(getAcc == ''){
        const web3 = await getWeb3();
        let accounts = await web3.eth.getAccounts();
        getAcc = accounts[0];
      }
      setMyAccount(getAcc);
      const userProfileData = await api
        .get(`/saveUser/${getAcc}`)
        .catch((err) => {
          console.log("Err: ", err);
        });
      if (userProfileData != undefined) {
        if (userProfileData.statusText = "OK") {
          if (userProfileData.data != null) {
            let data = userProfileData.data;
            
            setUserImage(data.image);
          }
        }
      }

      try {
        if (allCatagories.length === 0) {
          const responses = await api
            .get(`/getCategories/`)
            .catch((err) => {
              console.log("Err: ", err);
            });;
          let allCat = Object.keys(responses.data).map((key) => responses.data[key])
          setAllCatagories(allCat);
        }
      } catch (error) {
        console.error("Error,single: ", error);
      }

    };
    init();
  }, []);






  function toggleMobileMenu() {
    if (isOpenMobileMenu) {
      setIsOpenMobileMenu(false);
    } else {
      setIsOpenMobileMenu(true);
    }

  }





  return (
    <React.Fragment>
      <CssBaseline />


      <div className="header-main">
        <div className="top-acc">
          <div className="container" style={{ marginTop: "0" }}>
            <div><CopyToClipboard text={defaultAccount} onCopy={onCopyText}>
              <span style={{ cursor: "pointer", display: 'flex' }}>Contract address:{defaultAccount.slice(0, 7) + "..." + defaultAccount.slice(-4)} {isCopied ? "" : <MdContentCopy />}</span>
            </CopyToClipboard></div>
            {/* <span>AIRT Contract: {defaultAccount.slice(0, 7) + "..." + defaultAccount.slice(-4)}</span> */}

          </div>
        </div>
        {/* Desktop Header */}
        <div className="showOnDesktop">
          <div className="container" style={{ marginTop: '10px' }}>
            <div className="row" style={{ alignItems: 'center' }}>
              <div className="col-md-12 col-lg-2">
                <Link to="/">
                  <img src={logo} alt="Galerie" className={classes.logo} />
                </Link>
              </div>
              <div className="col-md-12 col-lg-4">
                <SearchGlobal></SearchGlobal>
              </div>
              <div className="col-md-12 col-lg-6">
                <div className="headerRight">
                  <div className="main-menu">
                    <div className="dropdown">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary btn-sm"
                          id="dropdown-basic">
                          Explore
                        </Dropdown.Toggle>

                        <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
                          <Dropdown.Item>
                            <Link to="/all-nft" style={{ textDecoration: 'none' }}>
                              <div style={{display:'flex', alignItems: 'center'}}>
                                <img style={{ width: '25px', height: '25px' }} src={allnftsLight} /> All NFT
                              </div>
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link to="/all-collection" style={{ textDecoration: 'none' }}>
                              <div style={{display:'flex', alignItems: 'center'}}>
                                <img style={{ width: '25px', height: '25px' }} src={allnftsLight} /> Collectable
                              </div>
                            </Link>
                          </Dropdown.Item>
                          
                          <Dropdown.Item href="/all-fnft" >
                            <div style={{display:'flex', alignItems: 'center'}}>
                              <img style={{ width: '25px', height: '25px' }} src={allnftsLight} /> All FNFT
                            </div>
                          </Dropdown.Item>
                          {Object.keys(allCatagories).map((key) => (
                            <Dropdown.Item href={`/nft-by-category/${allCatagories[key].categoryID}`} key={'cat-menu' + key}>
                              <div style={{display:'flex', alignItems: 'center'}}>
                                <img style={{ width: '25px', height: '25px' }} src={allCatagories[key].image} />
                                {allCatagories[key].category}
                              </div>
                            </Dropdown.Item>
                          ))}

                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  {isConnected ? (
                    <>
                      <Link to="/create-nft" style={{ textDecoration: 'none' }}>
                        <Button variant="contained" className="cnBtn" disableElevation>
                          <img style={{ height: '24px', marginRight: '5px' }} src={nftIco} /> Create NFT
                        </Button>
                      </Link>
                      <Link to="/make-fnft" style={{ textDecoration: 'none', marginLeft: '5px' }}>
                        <Button variant="contained" className="cnBtn" disableElevation>
                          <img style={{ height: '24px', marginRight: '5px' }} src={graphIco} /> Fractionalize
                        </Button>
                      </Link>
                    </>
                  ) : (
                    <span></span>
                  )}
                  <ul className={classes.listStyle}>
                    <li>
                      <SimpleDialogDemo isconnected={isConnected} myaccount={myAccount} accImage={userImage} />
                    </li>
                    {/* <li>
                      {isConnected ? (
                        <div className="dropdown">
                          <Dropdown>
                            <Dropdown.Toggle
                              variant="secondary btn-sm"
                              id="dropdown-basic2">
                              <img style={{ width: '30px', height: '30px', }} src={walletIco} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
                              <Dropdown.Item href="/"> 
                              
                                {
                                  balance > 0 ? (
                                    Web3.utils.fromWei(balance, "ether") + " ETH"
                                  ):(
                                    "0 ETH"
                                  )
                                }
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <span></span>
                      )}
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>



        {/* Mobile Device Header  */}
        <div className="showOnMobile">
          <div className="container" style={{ marginTop: '0' }}>
            <div className="row">
              <div className="col-sm-5 logoMobile" >
                <Link to="/">
                  <img src={logo} alt="Galerie" className={classes.logo} />
                </Link>
              </div>
              <div className="col-sm-7">
                <div className="header-right-m">
                  <div className="menu-openner" onClick={toggleMobileMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                  {/* {isConnected ? (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary btn-sm"
                        id="dropdown-basic2">
                        <img style={{ width: '30px', height: '30px', }} src={walletIco} />
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ backgroundColor: '#73a47' }}>
                        <Dropdown.Item href="/" > 
                          {
                            balance > 0 ? (
                              Web3.utils.fromWei(balance, "ether") + " ETH"
                            ):(
                              "0 ETH"
                            )
                          }
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <span></span>
                  )} */}
                  {isConnected ? (
                    <div style={{ marginLeft: '10px' }}>
                      <SimpleDialogDemo myaccount={account} accImage={userImage} />
                    </div>

                  ) : (
                    <span></span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <SearchGlobal></SearchGlobal>
              </div>
            </div>
          </div>
          {isOpenMobileMenu ? (
            <div className="mobile-menu">
              <span className="crossBtn" onClick={toggleMobileMenu}>
                &times;
              </span>
              {isConnected ? (
                <div className="createMenu">
                  <Link to="/Layer" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" className="cnBtn" disableElevation>
                      <img style={{ height: '24px', marginRight: '5px' }} src={nftIco} /> Create NFT
                    </Button>
                  </Link>
                  <Link to="/make-fnft" style={{ textDecoration: 'none', marginLeft: '5px' }}>
                    <Button variant="contained" className="cnBtn" disableElevation>
                      <img style={{ height: '24px', marginRight: '5px' }} src={graphIco} /> Fractionalize
                    </Button>
                  </Link>
                </div>
              ) : (
                <span></span>
              )}
              <div className="mobile-menu-exp">
                <Link to="/all-nft" style={{ textDecoration: 'none' }}><img src={allnftsLight} />All NFT</Link>
                <Link to="/all-fnft" style={{ textDecoration: 'none', marginLeft: '5px' }}><img src={allnftsLight} />All FNFT</Link>
                <Link to="/all-collection" style={{ textDecoration: 'none' }}>
                  <img style={{ width: '25px', height: '25px' }} src={allnftsLight} /> Collectable
                </Link>
                {Object.keys(allCatagories).map((key) => (
                  <Link to={`/nft-by-category/${allCatagories[key].categoryID}`} style={{ textDecoration: 'none', marginLeft: '5px' }}><img style={{ width: '25px', height: '25px' }} src={allCatagories[key].image} /> {allCatagories[key].category}</Link>
                ))}

              </div>
            </div>
          ) : ('')}
        </div>

      </div>

    </React.Fragment>
  );
};

export default Header;
