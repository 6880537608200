import React, { useState } from "react";
import {useStyles} from './styles.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, Redirect } from "react-router-dom";
const SearchGlobal = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const handleSubmit = (evt) => {
    evt.preventDefault();
    return <Redirect push to={`/search/${name}`} />
  }
  
  


  return (
    <div style={{ width: '100%' }}>
        <div>
            <div>
              <form onSubmit={handleSubmit} className={classes.search}>
                <input value={name} onChange={e => setName(e.target.value)} type="text" className={classes.searchTerm} placeholder="What are you looking for?" />
                
                <Link className={classes.linkClass} to={`/search/${name}`}>
                  <button type="submit" className={classes.searchButton}>
                    <svg style={{ height: '17px', margin: '0 auto' }} className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g className="search-path" fill="none" stroke="#fff"><path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>
                  
                  </button>
                </Link>
              
              </form>
             
            </div>
        </div>
    </div>
  )
};

export default SearchGlobal;
