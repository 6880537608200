import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { api } from "../../services/api";
import { cssStyles } from "./styles.css";

const AllCollection = ({howManyLoad}) => {
    const [isload, setIsload] = useState(false);
    const [allCollectionData, setAllCollectionData] = useState([]);

    useEffect(() => {
        
        const init = async () => {
          try {
             const responses = await api
                .get(`/getallCollection/`)
                .catch((err) => {
                    console.log("Err: ", err);
                });
                
                let allCollection = Object.keys(responses.data).map((key) => responses.data[key]);
                if(howManyLoad != 'all'){
                    allCollection = allCollection.slice(0, howManyLoad);
                }
                setAllCollectionData(allCollection);
                
            
          } catch (error) {
            setIsload(false);
            console.error("Error,single: ", error);
          }
        }
    
        init();
    
    }, []);

    return (
        <>
            <section className="trending-collection">
                <div className="container-fluid">
                    <div className="row g-4">
                    {Object.keys(allCollectionData).map((key) => (
                        <div key={'my-collection' + key} className="col-xl-3 col-lg-3">
                            
                                <div className="collection-item">
                                    <div className="collection-item-img" style={{height: "200px", width: "100%"}}>
                                        <a style={{display: "block"}} href={`/collection/${allCollectionData[key].slag}`}>
                                            <img src={allCollectionData[key].featureImage} alt="" className="img-fluid" />
                                        </a>
                                    </div>
                                    <div className="collection-content">
                                        <div className="content-img">
                                            <img src={allCollectionData[key].logoImage} alt="" />
                                        </div>
                                        <div className="collection-content-h4">
                                            <a style={{display: "block"}} href={`/collection/${allCollectionData[key].slag}`}>
                                                <h4>{allCollectionData[key].collectionName}</h4>
                                            </a>
                                        </div>
                                        <div className="collection-content-h6">
                                            <h6>by <Link to={`/view-profile/${allCollectionData[key].address}`}>{allCollectionData[key].address.slice(0, 7) + "..." + allCollectionData[key].address.slice(-4)}</Link></h6>
                                        </div>
                                        <div className="collection-content-p text-center">
                                            <p>{allCollectionData[key].Description.slice(0, 40) + "..." }</p>
                                        </div>
                                        
                                    </div>
                                </div>
                            
                        </div>
                    ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default AllCollection;