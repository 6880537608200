import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Web3 from "web3";
import { selectedNft, removeSelectedNft } from "../../redux/actions/nftActions";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { useStyles } from "./styles.js";
import { cssStyles } from "./styles.css";
import Loader from "../../components/Loader/Loader";
import getWeb3 from "../../utils/getWeb3";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
import successGif from "../../assets/success-img.gif";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import SvgIcon from "@material-ui/core/SvgIcon";
import LatestNft from "../../components/LatestNft/LatestNft";
import PriceChart from "../../components/PriceChart/PriceChart";

import { ethers } from 'ethers';
import Web3Modal from "web3modal"
import Market from '../../contracts/ArtMarketplace.json';
import axios from 'axios';
import userIco from '../../assets/user.png';
import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import LazyNFT from "../../contracts/LazyNFT.json";
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import { api, imgBaseUrl, defaultAccount, defaultChainId } from "../../services/api"
import $ from 'jquery';
import DataTable from 'react-data-table-component'

import {
  setMarketContractGasLess,
  
} from "../../redux/actions/nftActions";
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const GaslessItem = () => {

  const [nfts, setNfts] = useState([])
  const [loadingState, setLoadingState] = useState('not-loaded');
  const acc = sessionStorage.getItem('myAccount');
  const myBalance = useSelector((state) => state.allNft.balance);
  window.scrollTo(0, 0);
  const classes = useStyles();
  const history = useHistory();
  const { nftId } = useParams();
  const [isload, setIsload] = useState(false);
  let [profileData, setProfileData] = useState(true);
  let [uri, setUri] = useState('');
  let [account, setAccount] = useState(acc);
  let [profileDataCreator, setprofileDataCreator] = useState(true);
  let [nftItem, setNftItemData] = useState({});
  let [relatedNfts, setRelatedNfts] = useState({});
  let [totalBalance, setTotalBalance] = useState(myBalance);
  const [open, setOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertTitle, setalertTitle] = useState('');
  const [allTransactionHistory, setTransactionHistory] = useState([]);
  const [propertiesData, setPropertiesData] = useState({});



const marketplaceContract = useSelector(
    (state) => state.allNft.marketplaceContract
  );



  //const [nftItemState, setNftItemState] = useState({});
  const marketLazyContract = useSelector(
    (state) => state.allNft.marketLazyContract
  );

  const artMarketplaceContractGasLess = useSelector(
    (state) => state.allNft.marketplaceContractGasLess
  );

 


  const dispatch = useDispatch();
  //let nft = useSelector((state) => state.nft);
  //console.log("dsadasdasdasdasdasdasdasdasdsadsad0", nft);

  const {
    image,
    imageLocal,
    tokenId,
    tokenKey,
    name,
    price,
    owner,
    creator,
    description,
    saleId,
    isSold,
    isForSale,
    categoryID,
    processType,
    transectionHistory,
    priceHistory,
    properties,
    isMinted,
    
    signature
  } = nftItem;

  let [updatedPrice, setUpdatePrice] = useState(price);

  function handleInputChange(event) {
    
    let { name, value } = event.target;
    setUpdatePrice(value);
  }

  useEffect(() => {
    const initAccountAndNetwork = async () => {
      const web3 = await getWeb3();
      let myaccount = await web3.eth.getAccounts();
      setAccount(myaccount[0]);
      // await web3.eth.getBalance(myaccount[0], (err, balance) => {
      //   let mybalance = balance;
      //   console.log("mybalance");
      //   console.log(mybalance);
      //   setTotalBalance(mybalance);
      // });
      console.log("myaccount", myaccount);
      const networkId = await web3.eth.net.getId();
      const marketplaceContract = new web3.eth.Contract(
        ArtMarketplace.abi,
        ArtMarketplace.networks[networkId].address
      );
      const marketLazyContract = new web3.eth.Contract(
        LazyNFT.abi,
        LazyNFT.networks[networkId].address
      );
      dispatch(setMarketContractGasLess(marketLazyContract));
      setIsload(false);
    }
    initAccountAndNetwork();
  }, []);

  useEffect(() => {
    
    
    const init = async () => {
      
      try {
        const responses = await api
          .get(`/gaslesstokens/` + nftId)
          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("responses Array: ", responses);
        let transactionHistory = null;
        
        if (responses.data !== "") {
          if(responses.data.properties != ''){
            setPropertiesData(JSON.parse(responses.data.properties))
          }
          let priceGet = 0;
          for (let i = 0; i < Object.keys(responses.data.priceHistory).length; i++) {
            if (responses.data.priceHistory[i].owner == responses.data.owner) {
              priceGet = responses.data.priceHistory[i].price;
            }
          }
          let nftPrice = Web3.utils.fromWei(String(priceGet), "ether");
          setUpdatePrice(nftPrice);

          transactionHistory = await api
            .get(`/nfthistory/` + responses.data.tokenId)
            .catch((err) => {
              console.log("Err: ", err);
            });
          setTransactionHistory(transactionHistory.data);
        }
        dispatch(selectedNft(responses.data));
        setNftItemData(responses.data);
        setUri(responses.request.responseURL);
        
      } catch (error) {
        setIsload(false);
        console.error("Error,single: ", error);
      }


      // if (nftId && nftId !== "" && nftItem.length){
      //   setIsload(false);
      //   dispatch(selectedNft(nftItem));
      // }


    }

    init();

    // 
    // setIsload(false);
    // if (nftId && nftId !== "" && nftItem.length){
    //   setIsload(false);
    //   dispatch(selectedNft(nftItem));
    // }




  }, [nftId]);

  console.log("transactionHistory");
  console.log(allTransactionHistory);

  const columns = [
    {
      name: 'Event',
      selector: row => row.action,
      sortable: true,
    },
    {
      name: 'From',
      selector: row => row.from.slice(0, 7) + "..." + row.from.slice(-4),
      sortable: true,
    },
    {
      name: 'To',
      selector: row => row.to.slice(0, 7) + "..." + row.to.slice(-4),
    },
    {
      name: 'Date',
      selector: row => row.createDate.slice(0, 15),
      sortable: true,
    }

  ]
  let result = Object.keys(allTransactionHistory).map((key) => allTransactionHistory[key]);


  useEffect(() => {
    const balanceInit = async () => {
      const web3 = await getWeb3();
      let myaccounts = await web3.eth.getAccounts();
      if (myaccounts.length) {
        let myAcc = myaccounts[0];
        await web3.eth.getBalance(myAcc, (err, balance) => {
          let mybalance = balance;
          console.log("mybalance");
          console.log(mybalance);
          setTotalBalance(mybalance);
        });
      }

    }
    balanceInit();

  }, [])


  useEffect(() => {
    const initRelatedData = async () => {
      const responsesCatagoryWise = await api
        .get(`/categoryWise/` + categoryID)
        .catch((err) => {
          console.log("Err: ", err);
        });
      let related = responsesCatagoryWise.data;
      console.log("related");
      console.log(responsesCatagoryWise);
      setRelatedNfts(related);
    }
    initRelatedData();
  }, [categoryID]);

  useEffect(() => {
    
    const getOwnerProfile = async () => {
      try {
        const acc = nftItem.owner;
        console.log("owner", acc)
        const response = await api
          .get(`/saveUser/${acc}`)

          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("ownerall", response)
        if (response.data != null) {
          let data = response.data;
          setProfileData(data);
        }




      } catch (error) {

      }
    };
    getOwnerProfile();

    const getCreatorProfile = async () => {
      try {
        const acc = nftItem.creator;
        console.log("creator", acc)
        const response = await api
          .get(`/saveUser/${acc}`)

          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("creatoral", response)
        if (response.data != null) {
          let data = response.data;
          setprofileDataCreator(data);
        }




      } catch (error) {

      }
    };
    getCreatorProfile();
    





  }, [nftItem]);

  let latestNft = useSelector((state) => state.allNft.nft);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };






  // async function putForSale(id, price) {
  //   let text = owner;
  //   console.log(owner);
  //   const totalItemsFgorSale = await marketplaceContract.methods
  //   .GetSaleItem()
  //   .call();

  //   let saleIndex = totalItemsForSale.findIndex(object => {
  //     return object.tokenId === id.toString();
  //   });
  //   setIsload(true);
  //   console.log(saleIndex);
    

  //   try {

      


  //     // const itemIdex = getItemIndexBuyTokenId(id);

  //     // const marketAddress = ArtMarketplace.networks[1337].address;
  //     // await artTokenContract.methods.approve(marketAddress, items[itemIdex].tokenId).send({from: accounts[0]});

  //     // let url = uri;
  //     // const testPrice = ethers.utils.parseUnits(price.toString(), 'ether');
  //     // console.log("TestPrice:" + testPrice);
  //     // const receipt = await marketplaceContract.methods
  //     //   .putItemForSale(id, description, image, name, uri, price, owner)
  //     //   .send({ gas: 600000, from: account });
  //     // console.log(receipt);

  //     // const ItemforSale = await marketplaceContract.methods
  //     //   .GetSaleItemLength().call();
  //     // console.log(ItemforSale);
  //     // 

  //     let saleId = saleIndex;
  //     // let item = await marketplaceContract.methods
  //     //   .itemsForSale(saleId)
  //     //   .call();
  //     // let active = await marketplaceContract.methods
  //     //   .activeItems(item.tokenId)
  //     //   .call();

  //     // console.log("item sale");
  //     // console.log(item);
  //     // console.log(active);

  //     // itemsList[itemListIndex] = {
  //     //   ...itemsList[itemListIndex],
  //     //   isForSale: active,
  //     //   saleId: item.id,
  //     //   price: item.price,
  //     //   isSold: item.isSold,
  //     // };

  //     let priceHistoryIndex = Object.keys(nftItem.priceHistory).length;
  //     let newPriceHistory = {
  //       "owner": owner,
  //       'price': Web3.utils.toWei(String(updatedPrice), "ether")
  //     }

  //     nftItem.priceHistory[priceHistoryIndex] = newPriceHistory;

  //     let data = {
  //       "tokenId": id,
  //       "tokenKey": tokenKey,
  //       "price": Web3.utils.toWei(String(price), "ether"),
  //       "saleId": saleId,
  //       "seller": account,
  //       "owner": account,
  //       "isSold": false,
  //       "isForSale": true,
  //       "priceHistory": nftItem.priceHistory,

  //     }
  //     console.log("data");
  //     console.log(data);

  //     const response = await api.post("/updateToken", data, {
  //       headers: {
  //         "Content-Type": `application/json; boundary=${data._boundary}`,
  //       },
  //     });

  //     let receiptData = {
  //       "tokenId": id,
  //       "blockhash": null,
  //       "from": account,
  //       "to": account,
  //       "transactionHash": null,
  //       "action": "Selling"

  //     }
  //     const transactionHistory = await api.post("/nfttransactionhistory", receiptData, {
  //       headers: {
  //         "Content-Type": `application/json; boundary=${receiptData._boundary}`,
  //       },
  //     });


  //     closePriceChangeModal();
  //     if (transactionHistory) {
  //       setInterval(openSaleSuccessModal, 4000);
  //     }


  //     // console.log("transaction", transactionHistory);
  //     // console.log(response);
  //     // history.push('/mynft');
  //     // window.location.reload();
  //   } catch (error) {

  //     setIsload(false);
  //     console.error("Error, puting for sale: ", error);
  //     alert("Error while puting for sale!");
  //   }
  // }


async function createVoucher(tokenId, uri,signatures, minPrice = 0) {
    const voucher = { tokenId, uri, minPrice }
    //const domain = await this._signingDomain()
    const types = {
      NFTVoucher: [
        {name: "tokenId", type: "uint256"},
        {name: "minPrice", type: "uint256"},
        {name: "uri", type: "string"},  
      ]
  }
  debugger;
    let signature = signatures
    console.log(signature);
    return {
      ...voucher,
    }
  }

  async function buy(saleId, price) {
    

    let numberPrice = parseInt(price);

    let marketfee = numberPrice * 0.02;
    let marketPrice = Web3.utils.fromWei(String(marketfee));
    let newmarketprice = Web3.utils.toWei(marketPrice, "ether");
    setIsload(true);

    let amount = (numberPrice + marketfee)
    let newAmount = amount.toString();
    debugger;
    const gassLimit = 210000;
    let redeemer = account;
    const myTotalBalance = parseInt(totalBalance);
   // let voucher="tokenId":tokenId,myTotalBalance,"http://localhost:3000",signature]
    let signatures = signature;
    let voucher = await createVoucher(tokenId, uri, signatures);
    const contractsign=await marketLazyContract.methods.getLazyContractAddress().call();
    const types = {
      NFTVoucher: [
        {name: "tokenId", type: "uint256"},
        {name: "minPrice", type: "uint256"},
        {name: "uri", type: "string"},  
      ]
    }
    const chainId = "4"
    let domain = {
      name: "LazyNFT-Voucher",
      version: "1",
      //verifyingContract: this.contract._address,
      verifyingContract:contractsign,
      chainId
    }
    const verify = await ethers.utils.verifyTypedData(domain, types, voucher, signature);
    // let voucher = {
     
    //   tokenId: tokenId,
    //   minPrice: myTotalBalance,
    //   uri: "http://localhost:3000/Gaslessnft/0dfe7b84-748c-46dd-9ab2-18f5f8960027",
    //   signature: signature
    // }
    if (myTotalBalance > gassLimit) {
      try {
        debugger;
      //  const permission=await marketLazyContract.methods
      //  .setNewMinter(redeemer).call();
      //  console.log(permission);
        const receipt = await marketLazyContract.methods
          .redeem(redeemer, voucher)
          .send({ gas: gassLimit, value: newAmount ,from: redeemer});
        debugger;
        console.log(receipt);
         var receiptData = {
          "tokenId": receipt.events.Transfer.returnValues.tokenId,
          "blockhash": receipt.blockHash,
          "from": receipt.from,
          "to": receipt.events.Approval.returnValues.owner,
          "transactionHash": receipt.transactionHash,
          "action": "Buying"

        }
        
        const transactionHistory = await api.post("/nfttransactionhistory", receiptData, {
          headers: {
            "Content-Type": `application/json; boundary=${receiptData._boundary}`,
          },
        });
        console.log("transaction", transactionHistory);
        const id = receipt.events.itemSold.id; ///saleId
        console.log(saleId);
        console.log(id);
        // setIsload(false);
        // history.push('/mynft');
        // window.location.reload();
        let item = await marketplaceContract.methods
          .itemsForSale(saleId)
          .call();
        let active = await marketplaceContract.methods
          .activeItems(item.tokenId)
          .call();

        console.log('after sale');
        console.log(item);
        console.log(active);


        let data = {
          "tokenId": item.tokenId,
          "tokenKey": tokenKey,
          "price": price,
          "saleId": item.id,
          "seller": item.owner,
          "owner": account,
          "isSold": item.isSold,
          "isForSale": false,
          "priceHistory": nftItem.priceHistory

        }
        console.log(data);

        const response = await api.post("/updateToken", data, {
          headers: {
            "Content-Type": `application/json; boundary=${data._boundary}`,
          },
        });
        console.log(response);
        setIsload(false);
        history.push('/mynft');
        window.location.reload();

      } catch (error) {
        console.error("Error, buying: ", error);
        alert("Error while buying!");
        setIsload(false);

      }
    } else {
      setIsload(false);
      setAlertMessage("You have no enough balance for this transaction!");
      setalertTitle('Sorry!')
      setOpen(true);
    }


  }


  async function buyWithMint(price) {
    setIsload(true);

    let isSuccess = true;
    if (isMinted === 'false') {
      isSuccess = await mint();
    }

    if (isSuccess) {
      let theSaleId = nftId - 1;
      buy(theSaleId, price);
    } else {
      alert("Error On Minting!");
    }

  }


  async function mint() {

    const tokenMetadataURL = window.location.href;
    try {


      const receipt = await marketplaceContract.methods
        .Createmint(tokenMetadataURL, description, image, name, price, nftId)
        .send({ from: account, gas: 210000 });
      console.log(receipt);
      console.log(receipt.events.Transfer.returnValues.tokenId);



      var updateMintActionData = {
        tokenId: nftId,
        isMinted: 'true',
      }
      const updateMintAction = await api.post("/updateMintAction", updateMintActionData, {
        headers: {
          "Content-Type": `application/json; boundary=${updateMintActionData._boundary}`,
        },
      });

      var receiptData = {
        tokenId: receipt.events.Transfer.returnValues.tokenId,
        blockhash: receipt.blockHash,
        from: receipt.from,
        to: receipt.to,
        transactionHash: receipt.transactionHash,
        action: "minted"

      }
      const transactionHistory = await api.post("/nfttransactionhistory", receiptData, {
        headers: {
          "Content-Type": `application/json; boundary=${receiptData._boundary}`,
        },
      });
      console.log("transaction", transactionHistory);
      return true;

    } catch (error) {
      return false;
    }
  }


  async function putForSaleGasLess(id) {

    try {

      let saleId = id - 1;
      let data = {
        "tokenId": id,
        "saleId": saleId,
        "seller": account,
        "owner": account,
        "isSold": true,
        "isForSale": false

      }

      const response = await api.post("/updateToken", data, {
        headers: {
          "Content-Type": `application/json; boundary=${data._boundary}`,
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  function openBuyModal() {
    $('.buymodal').addClass('active');
  }
  function closeBuyModal() {
    $('.buymodal').removeClass('active');
  }

  function closeSaleSuccessModal() {
    $('.saleSuccessModal').removeClass('active');

    history.push('/mynft');
    window.location.reload();
  }

  function openSaleSuccessModal() {
    setIsload(false);
    $('.saleSuccessModal').addClass('active');
  }
  function openPriceChangeModal() {
    
    $('.priceChangeModal').addClass('active');
    let priceGet = 0;
    for (let i = 0; i < Object.keys(nftItem.priceHistory).length; i++) {
      if (nftItem.priceHistory[i].owner == nftItem.owner) {
        priceGet = nftItem.priceHistory[i].price;
      }
    }
    
    let nftPrice = Web3.utils.fromWei(String(priceGet), "ether");
    setUpdatePrice(nftPrice);
  }
  function closePriceChangeModal() {
    $('.priceChangeModal').removeClass('active');
  }






  console.log("account");
  console.log(account);
  console.log("owner");
  console.log(owner);

  console.log("nftItem saiful");
  console.log(nftItem);


  return (
    <div className={classes.pageItem}>
      {isload ? <Loader /> : <span></span>}
      {Object.keys(nftItem).length > 0 ? (
        <div className={classes.detailsPage}>
          <Snackbar open={open} autoHideDuration={8000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
              <AlertTitle>{alertTitle}</AlertTitle>
              {alertMessage}
            </Alert>
          </Snackbar>
          <section className="container">
            <header className={classes.pageHeader} style={{ margin: '20px 0' }}>
              <Link to="/">
                <KeyboardBackspaceIcon fontSize="large" /> Back to list
              </Link>
            </header>
          </section>
          <section className="dateils">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <img
                    className="ui fluid image"
                    alt="that wanaka tree"
                    src={imageLocal}
                    width="500"
                  />
                </div>
                <div className="col-md-4">
                  <div className={classes.detailTitle}>
                    <h1 className="detailTitleHeading">{name}</h1>
                    <div className="row" style={{ marginTop: "10px" }}>
                      <div className="col">
                        <div className="box-col">
                          <div className={classes.priceDiv} style={{ fontSize: '15px' }}>
                            <div>Price</div>
                            <div style={{ fontSize: '20px', color: '#2c98f6' }}>
                              <SvgIcon
                                style={{ width: '30px' }}
                                component={EthereumLogo}
                                viewBox="0 0 400 426.6"
                                titleAccess="ETH"
                              />{Web3.utils.fromWei(String(price), "ether")} <small style={{ fontSize: '10px', color: '#6e6969' }}>ETH</small>
                            </div>
                            <div><small>{owner !== account && (<span style={{ color: "red" }}>(+ 2% MarketFees Will be Applicable)</span>)}</small></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <div className={classes.priceDiv} style={{fontSize:'15px'}}><strong> <SvgIcon
                                                      component={EthereumLogo}
                                                      viewBox="0 0 400 426.6"
                                                      titleAccess="ETH"
                                                  />{Web3.utils.fromWei(String(price), "ether")}</strong></div> */}
                  </div>
                  <div className={classes.detailDes}>
                    <div className="panel panel-default">
                      <div className="panel-heading">Description</div>
                      <div className="panel-body">
                        <p>{description}</p>
                      </div>
                    </div>
                  </div>

                  <div className={classes.buttonDetail}>
                    {/* {isForSale}<br/>
                    {account}<br/>
                    {owner} */}
                    {owner?.toString() == account?.toString() && !isForSale && (
                      <Button
                        variant="contained"
                        className="cnBtn"
                        disableElevation
                        onClick={() => openPriceChangeModal()}
                      >
                        Sell
                      </Button>
                    )}
                    {owner !== account && isForSale && (
                      <Button
                        className="cnBtn"
                        disableElevation
                        variant="contained"

                        onClick={() => buy(saleId, price)}
                      >
                        Buy
                      </Button>
                    )}

                    {/* {owner !== account && (
                      <Button
                        className="cnBtn"
                        disableElevation
                        variant="contained"
                        onClick={openBuyModal}

                      // onClick={() => buyWithMint(saleId, price)}
                      >
                        Buy with mint
                      </Button>
                    )} */}
                  </div>
                  <div className="modal buymodal">
                    <div className="modal-content">
                      <span className="close-button" onClick={closeBuyModal}>×</span>
                      <div className="modal-form confirmCancle">
                        <h5>Do you want to mint and buy this nft?</h5>
                        <div className="alert alert-warning">You have to pay minting gas fee for buy this nft!</div>
                        <div className="confirmCancel clearfix">
                          <Button
                            className="cnBtn cnBtnConfirm"
                            disableElevation

                            onClick={() => buyWithMint(price)}
                          >
                            Confirm
                          </Button>
                          <Button
                            className="cnBtn cnBtnCencele"
                            disableElevation
                            onClick={closeBuyModal}
                          >
                            Cancel
                          </Button>


                        </div>
                      </div>
                    </div>
                  </div>


                  <div className="modal saleSuccessModal">
                    <div className="modal-content">
                      <div className="modal-form confirmCancle">
                        <h5>On Sale Successfully!</h5>
                        <div className="confirmCancel clearfix">
                          <div className="successMess">
                            <img style={{margin: "0 auto"}} src={successGif} />
                          </div>
                          <Button
                            className="cnBtn"
                            disableElevation

                            onClick={() => closeSaleSuccessModal(price)}
                          >
                            Ok
                          </Button>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal priceChangeModal">
                    <div className="modal-content">
                      <div className="modal-form confirmCancle">
                        <h5>Change Price</h5>
                        <form>
                          <label>Price</label>
                          <input className="form-control" onChange={handleInputChange} type="number" name="updatedPrice" value={updatedPrice} />
                        </form>
                        <div className="confirmCancel clearfix">

                          {/* <Button
                            className="cnBtn"
                            disableElevation

                            onClick={() => putForSale(tokenId, updatedPrice)}
                          >
                            Confirm & Sell
                          </Button> */}
                          <Button
                            className="cnBtn cnBtnCencele"
                            disableElevation
                            onClick={closePriceChangeModal}
                          >
                            Cancel
                          </Button>


                        </div>
                      </div>
                    </div>
                  </div>




                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-sm-7">
                  <div className="panel panel-default">
                    <div className="panel-heading">Transaction history</div>
                    <div className="panel-body">
                      <DataTable columns={columns} data={result} filter={true} pagination />
                    </div>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="panel panel-default">
                    <div className="panel-heading">Price history</div>
                    <div className="panel-body">
                      <PriceChart priceHistoryData={nftItem} />
                    </div>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-sm-5">
                  <div className="panel panel-default">
                    <div className="panel-heading">Info</div>
                    <div className="panel-body">
                      <Tabs>
                        <TabList>
                          <Tab>Owner</Tab>
                          <Tab>Creator</Tab>
                          <Tab>Details</Tab>
                        </TabList>

                        <TabPanel>
                          {/* <p>{owner}</p> */}
                          <div className="section-wrapper" style={{ marginTop: '0' }}>
                            <div className="nft-sell-wrapper">
                              <div className="row gx-4 gy-3">
                                <div className="col-12 slideMain">
                                  <div className="swiper-slide" >
                                    <div className="nft-item">
                                      <div className="nft-inner">
                                        <Link to={`/view-profile/${owner}`}>

                                          <div className="nft-content">
                                            <div className="author-thumb">
                                              <div className="veryfied">
                                                {profileData.image ? (
                                                  <img src={profileData.image} />
                                                ) : (
                                                  <img src={userIco} />
                                                )}
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                  <path d="M13.9144 12.7646C14.0077 13.533 13.3419 14.1783 12.5768 14.0608L10.9348 13.8087C10.5173 13.7446 10.0978 13.9107 9.83735 14.2432L8.81298 15.551C8.33567 16.1604 7.40855 16.1458 6.95061 15.5218L5.96782 14.1824C5.71796 13.8419 5.30382 13.6627 4.88456 13.7137L3.23548 13.9141C2.46708 14.0075 1.82181 13.3416 1.93927 12.5765L2.19137 10.9345C2.25547 10.5171 2.08935 10.0975 1.75686 9.83707L0.449081 8.8127C-0.160286 8.33538 -0.145722 7.40826 0.478337 6.95033L1.81765 5.96754C2.15815 5.71768 2.33737 5.30354 2.28642 4.88427L2.08602 3.2352C1.99264 2.4668 2.65851 1.82152 3.4236 1.93899L5.06557 2.19109C5.48303 2.25518 5.90259 2.08907 6.16303 1.75658L7.1874 0.448798C7.66471 -0.160569 8.59183 -0.146005 9.04976 0.478054L10.0326 1.81736C10.2824 2.15787 10.6966 2.33708 11.1158 2.28613L12.7649 2.08574C13.5333 1.99236 14.1786 2.65823 14.0611 3.42332L13.809 5.06528C13.7449 5.48274 13.911 5.9023 14.2435 6.16274L15.5513 7.18711C16.1607 7.66442 16.1461 8.59154 15.522 9.04948L14.1827 10.0323C13.8422 10.2821 13.663 10.6963 13.714 11.1155L13.9144 12.7646Z" fill="url(#paint0_linear)" />
                                                  <path d="M6.93353 8.62215L5.15575 7.55549L4.44464 8.2666L6.93353 11.111L11.5558 6.48882L10.8446 5.77771L6.93353 8.62215Z" fill="white" />
                                                  <defs>
                                                    <linearGradient id="paint0_linear" x1="21.1974" y1="7.44855" x2="3.34965" y2="-1.26427" gradientUnits="userSpaceOnUse">
                                                      <stop stopColor="#2CF9F9" />
                                                      <stop offset="0.354167" stopColor="#397DFF" />
                                                      <stop offset="0.557292" stopColor="#3858FF" />
                                                      <stop offset="0.791667" stopColor="#B14FFF" />
                                                      <stop offset="1" stopColor="#FFA0D9" />
                                                      <stop offset="1" stopColor="#FFBEE5" />
                                                    </linearGradient>
                                                  </defs>
                                                </svg>
                                              </div>
                                            </div>
                                            <div className="author-details d-flex flex-wrap align-items-center gap-15">

                                              <div className="author-det-info">
                                                <h5>{profileData.userName}</h5>
                                                <p className="nft-price yellow-color">{owner.slice(0, 7) + "..." + owner.slice(-4)}</p>
                                              </div>

                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <p>{profileData.userName}</p>
                            <img className="ui fluid image" src={profileData.image} /> */}
                        </TabPanel>
                        <TabPanel>
                          {/* <p>{creator}</p> */}
                          <div className="section-wrapper" style={{ marginTop: '0' }}>
                            <div className="nft-sell-wrapper">
                              <div className="row gx-4 gy-3">
                                <div className="col-12 slideMain">
                                  <div className="swiper-slide" >
                                    <div className="nft-item">
                                      <div className="nft-inner">
                                        <Link to={`/view-profile/${creator}`}>
                                          <div className="nft-content">
                                            <div className="author-thumb">
                                              <div className="veryfied">
                                                {profileDataCreator.image ? (
                                                  <img src={profileDataCreator.image} />
                                                ) : (
                                                  <img src={userIco} />
                                                )}

                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                  <path d="M13.9144 12.7646C14.0077 13.533 13.3419 14.1783 12.5768 14.0608L10.9348 13.8087C10.5173 13.7446 10.0978 13.9107 9.83735 14.2432L8.81298 15.551C8.33567 16.1604 7.40855 16.1458 6.95061 15.5218L5.96782 14.1824C5.71796 13.8419 5.30382 13.6627 4.88456 13.7137L3.23548 13.9141C2.46708 14.0075 1.82181 13.3416 1.93927 12.5765L2.19137 10.9345C2.25547 10.5171 2.08935 10.0975 1.75686 9.83707L0.449081 8.8127C-0.160286 8.33538 -0.145722 7.40826 0.478337 6.95033L1.81765 5.96754C2.15815 5.71768 2.33737 5.30354 2.28642 4.88427L2.08602 3.2352C1.99264 2.4668 2.65851 1.82152 3.4236 1.93899L5.06557 2.19109C5.48303 2.25518 5.90259 2.08907 6.16303 1.75658L7.1874 0.448798C7.66471 -0.160569 8.59183 -0.146005 9.04976 0.478054L10.0326 1.81736C10.2824 2.15787 10.6966 2.33708 11.1158 2.28613L12.7649 2.08574C13.5333 1.99236 14.1786 2.65823 14.0611 3.42332L13.809 5.06528C13.7449 5.48274 13.911 5.9023 14.2435 6.16274L15.5513 7.18711C16.1607 7.66442 16.1461 8.59154 15.522 9.04948L14.1827 10.0323C13.8422 10.2821 13.663 10.6963 13.714 11.1155L13.9144 12.7646Z" fill="url(#paint0_linear)" />
                                                  <path d="M6.93353 8.62215L5.15575 7.55549L4.44464 8.2666L6.93353 11.111L11.5558 6.48882L10.8446 5.77771L6.93353 8.62215Z" fill="white" />
                                                  <defs>
                                                    <linearGradient id="paint0_linear" x1="21.1974" y1="7.44855" x2="3.34965" y2="-1.26427" gradientUnits="userSpaceOnUse">
                                                      <stop stopColor="#2CF9F9" />
                                                      <stop offset="0.354167" stopColor="#397DFF" />
                                                      <stop offset="0.557292" stopColor="#3858FF" />
                                                      <stop offset="0.791667" stopColor="#B14FFF" />
                                                      <stop offset="1" stopColor="#FFA0D9" />
                                                      <stop offset="1" stopColor="#FFBEE5" />
                                                    </linearGradient>
                                                  </defs>
                                                </svg>
                                              </div>
                                            </div>
                                            <div className="author-details d-flex flex-wrap align-items-center gap-15">
                                              <div className="author-det-info">
                                                <h5>{profileDataCreator.userName}</h5>
                                                <p className="nft-price yellow-color">{creator.slice(0, 7) + "..." + creator.slice(-4)}</p>
                                              </div>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                        <TabPanel>
                          <div className="details-row">
                            <div className="row">
                              <div className="col">
                                Contract Address

                              </div>
                              <div className="col">
                                {defaultAccount.slice(0, 7) + "..." + defaultAccount.slice(-4)}

                              </div>

                            </div>
                            <div className="row">
                              <div className="col">
                                Token ID

                              </div>
                              <div className="col">
                                {tokenId}

                              </div>

                            </div>
                            <div className="row">
                              <div className="col">
                                Token Standard

                              </div>
                              <div className="col">
                                ERC-721

                              </div>

                            </div>
                            <div className="row">
                              <div className="col">
                                Blockchain

                              </div>
                              <div className="col">
                                Ethereum

                              </div>

                            </div>

                          </div>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
                {Object.keys(propertiesData).length != 0 ? (
                <div className="col-sm-5">
                  <div className="panel panel-default">
                    <div className="panel-heading">Properties</div>
                    <div className="panel-body">
                      <div className="properties-list clearfix">
                        {Object.keys(propertiesData).map((key) => (
                          <div key={'poperty-' + key} className="properties-li-item">
                            <div className="properties-li-item-inner">
                              <span>{key}</span>
                              <p>{propertiesData[key]}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                ):('')
                }
                
              </div>

              <LatestNft latestNft={relatedNfts} title="Related NFTs" coloumn="col-md-3"></LatestNft>
            </div>
          </section>
        </div>

      ) : (
        <Loader />
      )}
    </div>
  );
};

export default GaslessItem;
