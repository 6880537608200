import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Card from "../../components/Card";
import NftCard from "../../components/NftCard";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStyles } from "./styles.js";
import nodatafound from '../../assets/nodatafound.png';
import loaderPlaceholder from "../../assets/placeholder-loading.gif";
import Slider from '@material-ui/core/Slider';
import { cssStyles } from "./styles.css";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Card as MuiCard } from "@material-ui/core";
import Loader from "../../components/Loader/Loader";
import Web3 from "web3";
import { api, imgBaseUrl } from "../../services/api";
import AlertTitle from '@material-ui/lab/AlertTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import getWeb3 from "../../utils/getWeb3";
import { uuid } from 'uuidv4';
import FractionalizeNFT from "../../contracts/FractionalizeNFT.json";
import ArtMarketplaceWithRoyalityV2 from "../../contracts/ArtMarketplaceWithRoyalityV2.json";
//import { single } from "../../../../backend/config/uploadConfig";
import Swal from 'sweetalert2'
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MakeFNFT = () => {
  const history = useHistory();
  const classes = useStyles();
  let [isLoading, setIsLoading] = useState(true);
  const [anualFee, setAnualFee] = React.useState(0);
  const [theToken, setTheToken] = React.useState(null);
  const [theTokenKey, setTheTokenKey] = React.useState(null);

  
  const account = sessionStorage.getItem('myAccount');
  const [isload, setIsload] = useState(false);
  const myBalance = useSelector((state) => state.allNft.balance);
  let [totalBalance, setTotalBalance] = useState(myBalance);
  const [alertTitle, setalertTitle] = useState('');
  const [open, setOpen] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [nftItem, setNftItem] = useState([]);

  console.log(account);


  // if(nftItem.length > 0){
  //   setIsLoading(false);
  // }
  const [nftPrice, setNftPrice] = React.useState(nftItem.price);
  useEffect(() => {
    setTotalBalance(JSON.parse(sessionStorage.getItem('balance')));
  }, []);


  useEffect(() => {
    const initMyNFT = async () => {
      try {
          
        let myaccount = account;
        const responses = await api
          .get(`/tokenAddress/` + myaccount)
          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log('sadsadsfzfsdfsfz');
        console.log(responses);
        setNftItem(responses.data);
  
      } catch (error) {
  
      }
    };
  
    initMyNFT();
  }, []); 



  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };




  const fractionalizeNFTContract = useSelector(
    (state) => state.allNft.FractionalizeNFTContract
  );
  const marketplaceContract = useSelector(
    (state) => state.allNft.marketplaceContract
  );

  // console.log("Contract",fractionalizerContract);
  const [formData, setFormData] = useState({
    name: "",
    supply: "",
    symbol: "",
    price: ""
  });

  function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  const handleSliderChange = (event, newValue) => {
    setAnualFee(newValue);
  };

  const selectedToken = (token, nftTokenKey, nftPrice) => {
    setNftPrice(nftPrice);
    setTheToken(token);
    setTheTokenKey(nftTokenKey);
  };



  // async function makeFnfT(event) {
  //   setIsload(true);
  //   event.preventDefault();
  //   const { name, supply, symbol, price } = formData;


  //   const data = new FormData();
  //   data.append("name", name);
  //   data.append("supply", supply);
  //   data.append("symbol", symbol);
  //   data.append("tokenId", theToken);
  //   data.append("anualFee", anualFee);
  //   data.append("price", Web3.utils.toWei(String(price), "ether"));
  //   let newprice = Web3.utils.toWei(String(price), "ether");

  //   console.log("data: " + data);
  //   

  //   FMint(theToken, name, symbol, supply, newprice, account)

  // }


  async function makeFnfT(event) {
    
    setIsload(true);
    event.preventDefault();

    // check is fnft price more then nft price
    let oldNftprice = parseInt(nftPrice);
    const { name, supply, symbol, price } = formData;
    let newprice = Web3.utils.toWei(String(price), "ether");
    let totalFractionPrice = parseInt(newprice) * supply;
    if (theToken != null && theTokenKey != null) {
      // if(oldNftprice >= totalFractionPrice){
      const data = new FormData();
      data.append("name", name);
      data.append("supply", supply);
      data.append("symbol", symbol);
      data.append("tokenId", theToken);
      data.append("anualFee", anualFee);
      data.append("price", Web3.utils.toWei(String(price), "ether"));

      let tokenIDUUID = uuid();
      data.append("tokenKey", tokenIDUUID);
      data.append("nftTokenKey", theTokenKey);

      


      console.log("data: " + data);


      FMint(theToken, name, symbol, supply, newprice, account, tokenIDUUID)
      // } else {
      //   setIsload(false);
      //   setAlertMessage('Fractional NFT Price can not more then NFT Price!');
      //   setOpen(true);
      // }
    } else {
      setIsload(false);
      setalertTitle('Warning!')
      setAlertMessage('Please Select one NFT to make FNFT!');
      setOpen(true);
    }
  }



  async function FMint(nftTokenId, erc20Name, erc20Symbol, erc20Supply, buyoutPrice, _paymentToken, tokenKey) {
    const web3 = await getWeb3();
    const networkId = await web3.eth.net.getId();
    const fractionalizeNFTContractData = new web3.eth.Contract(
      FractionalizeNFT.abi,
      FractionalizeNFT.networks[networkId].address
    );
    const marketplaceContractData = new web3.eth.Contract(
      ArtMarketplaceWithRoyalityV2.abi,
      ArtMarketplaceWithRoyalityV2.networks[networkId].address
    );
    const gasLimit = 3000000;
    const myBalance = parseInt(totalBalance);
    if (myBalance > gasLimit) {
      try {

        console.log(nftTokenId);
        const fnftcontractAddress = await fractionalizeNFTContractData.methods.getcontractAddress().call();
        console.log("fnftcontractAddress", fnftcontractAddress);
        const marketplaceContractAddress = await marketplaceContractData.methods.getcontractAddress().call();
        console.log("marketplaceContract", marketplaceContractAddress);

        const sks = await marketplaceContractData.methods.approve(fnftcontractAddress, nftTokenId).send({ gas: 80000, from: account });
        console.log(sks);

        const receipt = await fractionalizeNFTContractData.methods
          .fractionalizeNft(marketplaceContractAddress, nftTokenId, erc20Name, erc20Symbol, erc20Supply, buyoutPrice)
          .send({ gas: 2300000, from: account });
        console.log(receipt);

        const length = await fractionalizeNFTContractData.methods.GetfnftItemLength().call();
        const mapingid = (length - 1);
        const singleItem = await fractionalizeNFTContractData.methods.GetFractionalItem(mapingid).call();
        console.log("Single Item", singleItem);

        let singleToken = {
          'fractionPrice': singleItem.buyoutPrice,
          'fractionsCount': singleItem.totalSupply,
          'fractionsCountInitial': singleItem.totalSupply,
          'tokenId': singleItem.nftTokenId,
          'tokenKey': tokenKey,
          "nftTokenKey": theTokenKey,
          'name_': singleItem.erc20Name,
          'owner': singleItem.originalOwner,
          'erc20Address': singleItem.erc20Address,
          'symbol_': singleItem.erc20Symbol,
          'fnftId': singleItem.fracNFTId,
          'erc721Address': singleItem.erc721Address,
        }
        console.log("Object Single Item", singleToken);
        const response = await api.post("/createFnft", singleToken, {
          headers: {
            "Content-Type": `application/json; boundary=${singleToken._boundary}`,
          },
        });
        console.log("adasdsadasdasdasdasdasdasdasd", response);
        setIsload(false);
        Swal.fire({
            title: 'Success!',
            text: "Fractional Nft Created Successfully",
            icon: 'success',
            showCloseButton: false,
            showCancelButton: false
        }).then((result) => {
          history.push('/myfnft');
          window.location.reload();
        });

        
        

      } catch (error) {
        console.error("Error, minting: ", error);
        setIsload(false);

        Swal.fire({
          title: 'Error!',
          text: "Error while minting.",
          icon: 'danger',
          showCloseButton: false,
          showCancelButton: false
        });
      }
    } else {
      setIsload(false);
      setAlertMessage("You have no enough balance for this transection!");
      setalertTitle('Sorry!')
      setOpen(true);
    }
  }

  return (
    <section className="explore-section">
      <div className={classes.homepage}>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </Alert>
        </Snackbar>

        {isload ? <Loader /> : <span></span>}

        <section className="allNfts">

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-header" style={{ marginBottom: '20px' }}>
                  <h3>⚡️ Make Any NFT To FNFT</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-8">
                {Object.keys(nftItem).length > 0 ? (
                  <div className="row">

                    {nftItem.map((nft) => (
                      nft.owner == account && !nft.isForSale ?
                        <div className="col-md-4" style={{ marginBottom: '15px' }} key={'m' + nft.tokenId}>
                          <MuiCard className={classes.root}>

                            <CardActionArea>
                              <label className="container-check">
                                <input type="radio" value={nft.tokenId} onChange={() => selectedToken(nft.tokenId, nft.tokenKey, nft.price)} name="nftitem" />
                                <span className="checkmark"></span>
                                <CardMedia
                                  component="img"
                                  alt={nft.name}
                                  height="240"
                                  image={nft.imagelistLocal?.image_3}
                                  title={nft.name}
                                  style={{height: '200px'}}
                                />
                                <CardContent className={classes.content}>
                                  <div className={classes.title}>
                                    <Typography
                                      className={"MuiTypography--heading"}
                                      variant={"h5"}
                                      gutterBottom
                                    >
                                      {nft.name.slice(0, 14)}
                                      {nft.name.length > 14 ? (
                                        <span>...</span>
                                      ) : (
                                        <span></span>
                                      )}

                                    </Typography>
                                  </div>
                                </CardContent>
                              </label>
                            </CardActionArea>

                          </MuiCard>
                        </div>
                        :
                        <React.Fragment></React.Fragment>
                    ))}
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <span>No NFT Found!</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-md-4">
                <form className="form-style-9" onSubmit={makeFnfT}>
                  <h3>Vault details</h3>
                  <ul>
                    <li>
                      <input
                        name="name"
                        required
                        value={formData.name}
                        onChange={handleInputChange}
                        type="text" className="field-style" placeholder="name" />
                    </li>
                    <li>
                      <div className="row">
                        <div className="col-md-7">
                          <input
                            name="supply"
                            required
                            value={formData.supply}
                            onChange={handleInputChange}
                            type="text" className="field-style" placeholder="Supply" />
                        </div>
                        <div className="col-md-5">
                          <input
                            name="symbol"
                            required
                            value={formData.symbol}
                            onChange={handleInputChange}
                            type="text" className="field-style" placeholder="Symbol" />
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="input-group mb-3">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="basic-addon1">ETH</span>
                        </div>
                        <input label="price" name="price" value={formData.price} onChange={handleInputChange} type="number" className="field-style priceinput" placeholder="Reserve Price (Per fraction price)" aria-label="Price" aria-describedby="basic-addon1" />
                      </div>
                    </li>
                    <li style={{ paddingTop: '10px' }}>
                      <label style={{ color: '#909090' }}>ANNUAL MANAGEMENT FEE</label>
                      <div style={{ padding: '0 6px' }}>
                        <Slider
                          aria-label="Temperature"
                          defaultValue={0}
                          valueLabelDisplay="auto"
                          marks
                          min={0}
                          max={10}
                          color="secondary"
                          onChange={handleSliderChange}
                        />
                        <div className="clearfix">
                          <span className="minVal">0%</span>
                          <span className="maxVal">10%</span>
                        </div>
                      </div>

                    </li>


                    <li>
                      <button className="default-btn" type="submit">Continue</button>
                    </li>
                  </ul>
                </form>
              </div>
            </div>
          </div>


        </section>



      </div>
    </section>
  );
};

export default MakeFNFT;

