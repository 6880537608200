import React from "react";
import Web3 from "web3";
const DateFormat = ({ dateString }) => {
 
 let formatedDate = new Date(dateString)
    
    
    return (
        <>
            <span className="time-left">{formatedDate}</span>
        </>
    );
};

export default DateFormat;
