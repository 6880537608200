import React, { useState, useEffect }  from 'react'
import { useHistory } from 'react-router-dom'
import { api } from "../../../services/api";
import getWeb3 from "../../../utils/getWeb3";
import Web3 from "web3";

import {styles} from './styles.css';
import Select from 'react-select';
import { useSelector } from "react-redux";
import loaderMazic from '../../../assets/loader-mazic.gif';
import CreateCollectionModal from '../CreateCollectionModal/CreateCollectionModal';
import Slider from '@material-ui/core/Slider';
import Swal from 'sweetalert2'
import { uuid } from 'uuidv4';
import $ from 'jquery';
import LazyNFT from "../../../contracts/LazyNFT.json";
import ArtMarketplaceWithRoyalityV2 from "../../../contracts/ArtMarketplaceWithRoyalityV2.json";
import { ethers } from 'ethers'
const { LazyMinter } = require("../../../lib");
const CollectionWiseNftGenerate = ({historyId, nftListData}) => {

    const history = useHistory();
    // const { user } = useContext(LoginContext)

    console.log(nftListData)
    const [initializeFormData, setInitializeFormData] = useState([]);
    const [progressData, setProgressData] = useState([]);
    const [progressDataGasless, setProgressDataGasless] = useState([]);
    const [allCatagories, setAllCatagories] = useState([]);
    const [myAllCollection, setMyAllCollections] = useState([]);
    const [collectionSlug, setCollectionSlug] = useState('');
    
    const [onGoing, setOnGoing] = useState(false);
    const [isShowCreateFrom, setIsShowCreateFrom] = useState(false);
    const account = sessionStorage.getItem('myAccount');
    const artMarketplaceContract = useSelector(
        (state) => state.allNft.marketplaceContract
    );
    const [processType, setProcessType] = useState('gasless');

    useEffect(() => {
        let dataObjArray = [];
        
        const resetProperty = (data) =>{
            // return data;
            let returnData = {};
            Object.keys(data).map((key) => (
                returnData[data[key]['trait_type']] = data[key]['value']
            ));
            return returnData;
        }
        getProgressDataInit();
        getProgressDataInitGasless();
        Object.keys(nftListData.mainObject).map((key) => (
            dataObjArray.push({
                "filename": nftListData.mainObject[key].image,
                "name": nftListData.mainObject[key].name,
                "description": nftListData.mainObject[key].description,
                "price": '',
                "owner": nftListData.mainObject[key].address,
                "creator": nftListData.mainObject[key].address,
                "isForSale": false,
                "isSold": false,
                "categoryID": '0',
                "royalityPercentage": '0',
                "properties": resetProperty(nftListData.mainObject[key].properties),
            })
        ));
        setInitializeFormData(dataObjArray);

        const init = async () => {
            const responses = await api
                .get(`/getCategories/`)
                .catch((err) => {
                console.log("Err: ", err);
                });
            let allCat = Object.keys(responses.data).map((key) => responses.data[key]);
            
            let allCatData = [];
            if(Object.keys(allCat).length){
                Object.keys(allCat).map((key) => (
                allCatData.push({value: allCat[key].categoryID, label: allCat[key].category})
                ));
            }
            setAllCatagories(allCatData);

            console.log("nftListData.mainObject[0].address");
            let myacc = account;
            if(account != ''){
                myacc = account;
            }else{
                const web3 = await getWeb3();
                let accounts = await web3.eth.getAccounts();
                myacc = accounts[0];
            }

            const collections = await api
                .get(`/getUserwiseCollection/${myacc}`)
                .catch((err) => {
                    console.log("Err: ", err);
                });
                let allCollection = Object.keys(collections.data).map((key) => collections.data[key]);
                console.log(allCollection);
                let collectionOptions = [];
                if(Object.keys(collections.data).length){
                    Object.keys(allCollection).map((key) => (
                        collectionOptions.push({value: allCollection[key].slag, label: allCollection[key].collectionName})
                    ));
                }
                setMyAllCollections(collectionOptions);
        }
        init();
          
        
    }, []);

    const getProgressDataInit = () =>{
        let progressDataInit = [];
        Object.keys(nftListData.mainObject).map((key) => (
            progressDataInit.push({
                "name": nftListData.mainObject[key].name,
                "message": "Wait for minting...",
                "minStatus": false,
                "onGoing": false,
                "classname": ''
            })
        ))
        setProgressData(progressDataInit);
    }

    const getProgressDataInitGasless = () =>{
        let progressDataInit = [];
        Object.keys(nftListData.mainObject).map((key) => (
            progressDataInit.push({
                "name": nftListData.mainObject[key].name,
                "message": "Wait for signing!",
                "minStatus": false,
                "onGoing": false,
                "classname": ''
            })
        ));
        setProgressDataGasless(progressDataInit);
    }

    

    const processTypeChange = (type) => {

        $('.type-bx').removeClass('active');
        if (type == "gasless") {
          $('.gasless').addClass('active');
        } else if (type == "traditional") {
          $('.traditional').addClass('active');
        }
        setProcessType(type);
    }

    const continueProcess = () => {
        setIsShowCreateFrom(true);
    }

    const backtoProcess = () => {
        setIsShowCreateFrom(false);
        setProcessType("");
    }


    const handleFormChange = (index, event) => {
        
        let data = [...initializeFormData];
        data[index][event.target.name] = event.target.value;

        let progressdataGet = [...progressData];
        let progressDataGaslessGet = [...progressDataGasless];
        if(event.target.name == 'name'){
            progressdataGet[index][event.target.name] = event.target.value;
            progressDataGaslessGet[index][event.target.name] = event.target.value;
            setProgressData(progressdataGet);
            setProgressDataGasless(progressDataGaslessGet);
        }

        console.log(progressdataGet);
        setInitializeFormData(data);
    }

    const handleRoyalityChange = (index, event, newValue) => {
        debugger;
        let data = [...initializeFormData];
        data[index]['royalityPercentage'] = newValue;
        setInitializeFormData(data);
    }

    console.log("initializeFormData")
    console.log(initializeFormData)

    const handleChangeCollection = (selectedOption) => {
        
        setCollectionSlug(selectedOption.value);
    };


    const gotoMinting = async (event) => {
        event.preventDefault();
        if(collectionSlug != ''){
            const web3 = await getWeb3();
            let myaccount = await web3.eth.getAccounts();
            let myAcc = myaccount[0];
            const networkId = await web3.eth.net.getId();
            if(processType == "traditional"){
                const artMarketplaceContractData = new web3.eth.Contract(
                    ArtMarketplaceWithRoyalityV2.abi,
                    ArtMarketplaceWithRoyalityV2.networks[networkId].address
                );
                traditionalMinting(artMarketplaceContractData);
            } else if (processType == "gasless") {
                const marketLazyContractData = new web3.eth.Contract(
                    LazyNFT.abi,
                    LazyNFT.networks[networkId].address
                );
                gaslessMinging(marketLazyContractData);
            }

        }else{
            Swal.fire({
                title: 'Warning!',
                text: "Please select collection first!",
                icon: 'warning',
                background: '#ffa100',
                color: 'white',
                confirmButtonColor: '#000',
            });
        }
        
    } 

    const traditionalMinting = async (artMarketplaceContractData) =>{
        setOnGoing(true);
        console.log(initializeFormData);
        for(let i = 0; i < initializeFormData.length; i++){
            progressData[i].onGoing = true;
            progressData[i].message = "Mint on going";
            setProgressData(progressData);

            let data = {
                "name": initializeFormData[i].name,
                "filename": initializeFormData[i].filename,
                "description": initializeFormData[i].description,
                "saleId": null,
                "price": Web3.utils.toWei(String(initializeFormData[i].price), "ether"),
                "seller": initializeFormData[i].creator,
                "owner": initializeFormData[i].owner,
                "creator":  initializeFormData[i].creator,
                "isForSale": false,
                "isSold": false,
                "categoryID": initializeFormData[i].categoryID,
                "collectionName": collectionSlug,
                "isGasless": 'no',
                "royalityPercentage":initializeFormData[i].royalityPercentage,
                "properties": JSON.stringify(initializeFormData[i].properties)
            }
        
            try {
                // const allSupply = await api.get(`/tokens/`)
                //     .catch((err) => {
                //     console.log("Err: ", err);
                // });

                // let total = Object.keys(allSupply.data).length;
                let tokenIDUUID = uuid();
                data['tokenId']= tokenIDUUID;
                const response = await api.post("/tokensCreateWithCollection", data, {
                    headers: {
                    "Content-Type": `application/json; boundary=${data._boundary}`,
                    },
                });
                console.log(response);
                let isMinted = await mint(response.data.message[0], initializeFormData[i].description, response.data.message[1], initializeFormData[i].name, Web3.utils.toWei(String(initializeFormData[i].price), "ether"), response.data.tokenId, tokenIDUUID, initializeFormData[i].royalityPercentage, artMarketplaceContractData);
                if(isMinted){
                    progressData[i].minStatus = true;
                    progressData[i].onGoing = false;
                    progressData[i].message = "Minting Complete!";
                    progressData[i].classname = "step-success";
                    let imgData = {
                        "imageUrl": initializeFormData[i].filename, 
                        "historyId": historyId
                    }
                    const updateDoneList = await api.post(`/doneimgListUpdate/${initializeFormData[i].creator}`, imgData, {
                        headers: {
                        "Content-Type": `application/json; boundary=${data._boundary}`,
                        },
                    });
                    

                    Swal.fire({
                        title: 'Minting Complete!',
                        text: progressData[i].name+" minting successfully",
                        icon: 'success',
                        background: '#111827',
                        color: 'white',
                        confirmButtonColor: '#3b82f6',
                    })
                    
                }else{
                    progressData[i].minStatus = false;
                    progressData[i].onGoing = false;
                    progressData[i].message = "Sorry minting faild!";
                    progressData[i].classname = "step-faild";

                    Swal.fire({
                        title: 'Sorry!',
                        text: "Minting faild!",
                        icon: 'danger',
                        background: '#910411',
                        color: 'white',
                        confirmButtonColor: '#910411',
                    })
                }

                setProgressData(...progressData);

                
                


            } catch (error) {
                progressData[i].minStatus = false;
                progressData[i].onGoing = false;
                progressData[i].message = "Sorry minting faild!";
                progressData[i].classname = "step-faild";
                Swal.fire({
                    title: 'Sorry!',
                    text: "Minting faild!",
                    icon: 'danger',
                    background: '#910411',
                    color: 'white',
                    confirmButtonColor: '#910411',
                })
                console.log(error);
            }
        }
        getProgressDataInit();
        setOnGoing(false);

        let htmlData = "<ul>";
        Object.keys(progressData).map((key) => (
            htmlData += "<li>" +progressData[key].name+" " + progressData[key].message +"</li>"
        ))
        htmlData += "</ul>";
        Swal.fire({
            title: '<strong>Minting Summery</strong>',
            icon: 'info',
            html: htmlData,
            showCloseButton: false,
            showCancelButton: false
        }).then((result) => {
            history.push('/myCollection')
        });
    }


    const gaslessMinging = async (marketLazyContractData) =>{
        debugger;
        setOnGoing(true);
        
        for(let i = 0; i < initializeFormData.length; i++){
            
            progressDataGasless[i].onGoing = true;
            progressDataGasless[i].message = "Signing On Going!";
            setProgressDataGasless(progressDataGasless);

            let data = {
                "name": initializeFormData[i].name,
                "filename": initializeFormData[i].filename,
                "description": initializeFormData[i].description,
                "saleId": null,
                "price": Web3.utils.toWei(String(initializeFormData[i].price), "ether"),
                "seller": initializeFormData[i].creator,
                "owner": initializeFormData[i].owner,
                "creator":  initializeFormData[i].creator,
                "isForSale": false,
                "isSold": false,
                "categoryID": initializeFormData[i].categoryID,
                "collectionName": collectionSlug,
                "isGasless": 'yes',
                "royalityPercentage": initializeFormData[i].royalityPercentage,
                "properties": JSON.stringify(initializeFormData[i].properties)
            }
        
            try {
                // const allSupply = await api.get(`/tokens/`)
                //     .catch((err) => {
                //     console.log("Err: ", err);
                // });

                // let total = Object.keys(allSupply.data).length;
                let tokenIDUUID = uuid();
                data['tokenId']= tokenIDUUID;
                const response = await api.post("/tokensCreateWithCollection", data, {
                    headers: {
                    "Content-Type": `application/json; boundary=${data._boundary}`,
                    },
                });
                console.log(response);
                debugger;
                let isSigning = await gaslessSigning(response, marketLazyContractData, tokenIDUUID, initializeFormData[i].price);
                debugger;
                if(isSigning){
                    progressDataGasless[i].minStatus = true;
                    progressDataGasless[i].onGoing = false;
                    progressDataGasless[i].message = "Signing Complete!";
                    progressDataGasless[i].classname = "step-success";
                    let imgData = {
                        "imageUrl": initializeFormData[i].filename, 
                        "historyId": historyId
                    }
                    const updateDoneList = await api.post(`/doneimgListUpdate/${initializeFormData[i].creator}`, imgData, {
                        headers: {
                        "Content-Type": `application/json; boundary=${data._boundary}`,
                        },
                    });
                    

                    Swal.fire({
                        title: 'Signing Complete!',
                        text: progressDataGasless[i].name+" Signing successfully",
                        icon: 'success',
                        background: '#111827',
                        color: 'white',
                        confirmButtonColor: '#3b82f6',
                    })
                    
                }else{
                    progressDataGasless[i].minStatus = false;
                    progressDataGasless[i].onGoing = false;
                    progressDataGasless[i].message = "Sorry Signing faild!";
                    progressDataGasless[i].classname = "step-faild";

                    Swal.fire({
                        title: 'Sorry!',
                        text: "Signing faild!",
                        icon: 'danger',
                        background: '#910411',
                        color: 'white',
                        confirmButtonColor: '#910411',
                    })
                }

                setProgressDataGasless(...progressDataGasless);

            } catch (error) {
                progressDataGasless[i].minStatus = false;
                progressDataGasless[i].onGoing = false;
                progressDataGasless[i].message = "Sorry Signing faild!";
                progressDataGasless[i].classname = "step-faild";
                Swal.fire({
                    title: 'Sorry!',
                    text: "Signing faild!",
                    icon: 'danger',
                    background: '#910411',
                    color: 'white',
                    confirmButtonColor: '#910411',
                })
                console.log(error);
            }
        }
        getProgressDataInitGasless();
        setOnGoing(false);

        let htmlData = "<ul>";
        Object.keys(progressData).map((key) => (
            htmlData += "<li>" +progressDataGasless[key].name+" " + progressDataGasless[key].message +"</li>"
        ))
        htmlData += "</ul>";
        Swal.fire({
            title: '<strong>Signing Summery</strong>',
            icon: 'info',
            html: htmlData,
            showCloseButton: false,
            showCancelButton: false
        }).then((result) => {
            history.push('/myCollection')
        });

    }

   const gaslessSigning = async (response, marketLazyContractData, tokenIDUUID, price) =>{
        debugger;
        try{
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner(account);
            const contractsign = await marketLazyContractData.methods.getLazyContractAddress().call();
            const lazyMinter = new LazyMinter({
            contract: marketLazyContractData,
            signer: signer,
            });
            let minPrice = Web3.utils.toWei(String(price), "ether");
            const voucher = await lazyMinter.createVoucher(response.data.tokenId, response.data.message[0], minPrice)
            debugger;
            let datademo = { tokenId: tokenIDUUID, voucher: voucher, signature: voucher.signature };
            const voucherStatusupdate = await api.post("/voucherUpdate", datademo, {
                headers: {
                    "Content-Type": `application/json; boundary=${datademo._boundary}`,
                },
            });

            let updateMintingStatus = { tokenId: response.data.tokenId };
            const updateStatusAfterMinting = await api.post("/updateStatusAfterMinting", updateMintingStatus, {
                headers: {
                    "Content-Type": `application/json; boundary=${updateMintingStatus._boundary}`,
                },
            });
            console.log("updateStatusAfterMinting", updateStatusAfterMinting);
            return true;
        } catch (error) {
            return false;
        }
        
    }
    


    async function mint(tokenMetadataURL, description, images, name, price, tokenId, tokenKey, royality_percentage, artMarketplaceContractData) {
        debugger;
        try {
          const receipt = await artMarketplaceContractData.methods.CreatemintWithRoyality(tokenMetadataURL, description, images, name, price, tokenId, royality_percentage)
            .send({ from: account, gas: 4300000 });
          console.log(receipt);
          console.log(receipt.events.Transfer.returnValues.tokenId);
    
          var receiptData = {
            tokenId: receipt.events.Transfer.returnValues.tokenId,
            blockhash: receipt.blockHash,
            from: receipt.from,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            action: "Minted"
    
          }
          const transactionHistory = await api.post("/nfttransactionhistory", receiptData, {
            headers: {
              "Content-Type": `application/json; boundary=${receiptData._boundary}`,
            },
          });
          console.log("transaction", transactionHistory);

          let data = { tokenId: tokenId };
            const updateStatusAfterMinting = await api.post("/updateStatusAfterMinting", data, {
                headers: {
                "Content-Type": `application/json; boundary=${data._boundary}`,
                },
            });
          
          return true;
    
        } catch (error) {
            
        //   let data = { tokenID: tokenKey }
        //   const deleteres = await api.post("/deleteNFT", data, {
        //     headers: {
        //       "Content-Type": `application/json; boundary=${data._boundary}`,
        //     },
        //   });
        console.log(error);
          
          return false;
        }
    }

    

    return (
        <>
            <section style={{minHeight:'100vh'}} className="py-2 lg:py-8 bg-[white] h-full">
                
                <div className="container mx-auto px-5">
                    <div className='row'>
                        <div className='col-4'></div>
                        <div className='col-4'>
                            <div className='bg-white p-5 rounded-lg'>
                                <label className="block mb-2 text-sm font-bold text-gray-700 justify-left">
                                    Please select collection first
                                </label>
                                <Select
                                    options={myAllCollection}
                                    onChange={handleChangeCollection}
                                    placeholder="Select Collection"
                                />
                            </div>
                        </div>
                        <div className='col-4'></div>
                        
                    </div>
                    <div className='row'>
                        <div className='col-3'></div>
                        <div className='col-6'>
                            {!isShowCreateFrom ?
                            <div className="process">
                            <h2>Gasless or Traditional Minting?</h2>
                            <div className="processType">
                                <div className="type">
                                    <div className="gasless type-bx" onClick={() => processTypeChange('gasless')}>
                                        <svg style={{ margin: "0 auto" }} width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 48.5H8.5M41.5 48.5H38.5M8.5 48.5V10C8.5 7.79086 10.2909 6 12.5 6H34.5C36.7091 6 38.5 7.79086 38.5 10V48.5M8.5 48.5H38.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M14.5 22V15.5C14.5 14.3954 15.3954 13.5 16.5 13.5H30.5C31.6046 13.5 32.5 14.3954 32.5 15.5V22C32.5 23.1046 31.6046 24 30.5 24H16.5C15.3954 24 14.5 23.1046 14.5 22Z" stroke="white" strokeWidth="2" />
                                        <path d="M38.5 33C38.5 40.5 49 41.5 49 33C49 27 49 25 49 18.5C49 15.5 48 15.5 43.5 12" stroke="white" strokeWidth="2" strokeLinecap="round" />
                                        <line x1="10.0858" y1="45.5858" x2="46.0858" y2="9.58579" stroke="white" strokeWidth="4" />
                                        <rect x="2.5" y="2" width="50" height="50" rx="25" stroke="white" strokeWidth="2" />
                                        </svg>
                                        <h3>Gasless</h3>
                                        <p>(No transaction needed)</p>
                                    </div>
                                </div>
                                <div className="type">
                                    <div className="traditional type-bx" onClick={() => processTypeChange('traditional')}>
                                        <svg style={{ margin: "0 auto" }} width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.5 48.5H8.5M41.5 48.5H38.5M8.5 48.5V10C8.5 7.79086 10.2909 6 12.5 6H34.5C36.7091 6 38.5 7.79086 38.5 10V48.5M8.5 48.5H38.5" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" />
                                        <path d="M14.5 22V15.5C14.5 14.3954 15.3954 13.5 16.5 13.5H30.5C31.6046 13.5 32.5 14.3954 32.5 15.5V22C32.5 23.1046 31.6046 24 30.5 24H16.5C15.3954 24 14.5 23.1046 14.5 22Z" stroke="#2D2D2D" strokeWidth="2" />
                                        <path d="M38.5 33C38.5 40.5 49 41.5 49 33C49 27 49 25 49 18.5C49 15.5 48 15.5 43.5 12" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" />
                                        </svg>
                                        <h3>Traditional</h3>
                                        <p>(Transaction needed)</p>
                                    </div>
                                </div>
                            </div>
                            <div className="continue-btn">
                                <button onClick={continueProcess} className="default-btn" disabled={processType == ""} type="button">Continue &#8594;</button>
                            </div>
                            </div>
                            : <span></span>}
                        </div>
                        <div className='col-3'></div>
                    </div>
                    <div>
                        {Object.keys(initializeFormData).length != 0 && isShowCreateFrom ? (
                            <div className="container">
                                <div className='row'>
                                    <div className='col-12'>
                                        <button onClick={backtoProcess} type="button" className="backto-process"> &#8592; Back to minting option</button>
                                    </div>
                                    <div className='col-12'>
                                        <div style={{textAlign:'center'}}>
                                            <h3 style={{textTransform:'uppercase', marginBottom:'20px'}}>Create {processType} Nft</h3>
                                        </div>
                                    </div>
                                </div>
                                <form onSubmit={gotoMinting}>
                                    <div className='row'>
                                        {Object.keys(initializeFormData).map((key) => (
                                            <div key={'nfr-collet-'+key} className="col-md-6">
                                                
                                                <div className="w-full flex bg-white p-5 rounded-lg shadow-sm border-solid border-2 border-slate-300">
                                                    <div className='mr-5'>
                                                        <img className='border-dotted p-2  border-2 border-indigo-600' style={{maxHeight: '150px'}} src={initializeFormData[key].filename} />
                                                    </div>
                                                    <div className="w-full">
                                                            <div className="mb-4">
                                                                <label className="block mb-2 text-sm font-bold text-gray-700 justify-left">
                                                                    Name
                                                                </label>
                                                                <input
                                                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                                    value={initializeFormData[key].name}
                                                                    type="text"
                                                                    name='name'
                                                                    required
                                                                    placeholder="Name"
                                                                    onChange={event => handleFormChange(key, event)}
                                                                />
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block mb-2 text-sm font-bold text-gray-700">
                                                                    Description
                                                                </label>
                                                                <textarea
                                                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                                    value={initializeFormData[key].description}
                                                                    name='description'
                                                                    placeholder="Description"
                                                                    required
                                                                    onChange={event => handleFormChange(key, event)}
                                                                ></textarea>
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block mb-2 text-sm font-bold text-gray-700 justify-left">
                                                                    Price
                                                                </label>
                                                                <input
                                                                    className="w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                                                                    value={initializeFormData[key].price}
                                                                    type="number"
                                                                    name='price'
                                                                    required
                                                                    placeholder="Price"
                                                                    onChange={event => handleFormChange(key, event)}
                                                                />
                                                            </div>
                                                            <div className="mb-4">
                                                                <label className="block mb-2 text-sm font-bold text-gray-700 justify-left">
                                                                    Category
                                                                </label>
                                                                {/* <select name='price' onChange={event => handleFormChange(key, event)} className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                                                    <option>New Mexico</option>
                                                                    {Object.keys(allCatagories).map((key) => (
                                                                        <option key={'cat' + key} value={allCatagories[key].categoryID}>{allCatagories[key].category}</option>
                                                                    ))}
                                                                </select> */}

                                                                <Select
                                                                    options={allCatagories}
                                                                    onChange={event => handleFormChange(key, event)}
                                                                    placeholder="Select Category"
                                                                />
                                                                
                                                            </div>
                                                            <div className='mb-4'>
                                                                <label className="block mb-2 text-sm font-bold text-gray-700">
                                                                    Attributes/Propertise
                                                                </label>
                                                                <div className="properties-list clearfix">
                                                                    {Object.keys(initializeFormData[key].properties).map((pkey) => (
                                                                    <div key={'poperty-c-' + pkey} className="properties-li-item">
                                                                        <div className="properties-li-item-inner">
                                                                        <span>{pkey}</span>
                                                                        <p>{initializeFormData[key].properties[pkey]}</p>
                                                                        </div>
                                                                    </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                            <div className='mb-4'>
                                                                <label className="block mb-2 text-sm font-bold text-gray-700">
                                                                Royalty
                                                                </label>
                                                                <div className="properties-list clearfix">
                                                                    <Slider
                                                                        aria-label="Temperature"
                                                                        defaultValue={0}
                                                                        valueLabelDisplay="auto"
                                                                        marks
                                                                        step={1}
                                                                        min={0}
                                                                        max={10}
                                                                        color="secondary"
                                                                        onChange={(event,value)=>handleRoyalityChange(key, event, value)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            
                                                            
                                                            
                                                            {/* <div className="mb-6 text-center">
                                                                <button
                                                                    className="w-full px-4 py-2 font-bold text-white bg-blue-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
                                                                    type="button"
                                                                >
                                                                    Register Account
                                                                </button>
                                                            </div> */}
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <div className='col-md-12'>
                                            
                                        </div>
                                    </div>
                                    <div className='btndiv'>
                                        <button type='submit' className='btn btnInfo'>Create</button>
                                    </div>

                                </form>
                            </div>
                        ):('')}
                    </div>
                </div>
            </section>

            {onGoing ? (
                <div className="modal active">
                    <div className="modal-content modal-content-mint">
                        <div className='loader-img'>
                            <img src={loaderMazic} />
                            {processType=='gasless' ? (
                                <h4>Please wait until gasless signing complete...</h4>
                            ):(
                                <h4>Please wait until  minting complete...</h4>
                            )}
                            
                        </div>
                        
                        
                        <div className="Scriptcontent">
                        {processType=='gasless' ? (
                            Object.keys(progressDataGasless).map((key) => (
                                <div className={"step " + progressDataGasless[key].classname} key={'step'+key}>
                                    <div>
                                        <div className="circle">
                                            <i className="fa fa-check"></i>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="title">
                                            {progressDataGasless[key].name} 
                                            {progressDataGasless[key].onGoing ? (
                                                <div className="loading">...</div>
                                            ):('')}
                                            
                                        </div>
                                        <div className="caption">{progressDataGasless[key].message}</div>
                                    </div>
                                </div>
                            )) 
                            ):(
                            Object.keys(progressData).map((key) => (
                                <div className={"step " + progressData[key].classname} key={'step'+key}>
                                    <div>
                                        <div className="circle">
                                            <i className="fa fa-check"></i>
                                            <i className="fa fa-times" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="title">
                                            {progressData[key].name} 
                                            {progressData[key].onGoing ? (
                                                <div className="loading">...</div>
                                            ):('')}
                                            
                                        </div>
                                        <div className="caption">{progressData[key].message}</div>
                                    </div>
                                </div>
                            )) 
                        )}
    		            </div>
                       
                    </div>
                </div>
            ) : ("")}

        </>
    )
}

export default CollectionWiseNftGenerate
