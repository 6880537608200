import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

  

  search: {
    width: '100%',
    position: "relative",
    display: "flex",
  },
  
  searchTerm: {
    width: '100%',
    border: '1px solid #ececec',
    borderRight: 'none',
    padding: '5px 30px 5px 15px',
    height: '44px',
    borderRadius: '20px',
    outline: 'none',
    color: '#9DBFAF'
  },

  
  searchButton: {
    width: "45px",
    height: "44px",
    border: "none",
    background: "#f14d5d",
    textAlign: "center",
    color: "#fff",
    borderRadius: "0 20px 20px 0",
    cursor: "pointer",
    fontSize: "20px",
  }, 

  linkClass: {
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0'
  }
  

 
});

export { useStyles };