import React, { Profiler,useEffect,useState } from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";

import { Styles } from "./styles.css";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
import img  from "../../assets/2.jpg";
import historyIco  from "../../assets/history.png";
import userIco  from "../../assets/user.png";
import ProfileImageGet from '../../_pipe/profileImageGet';

import { api, imgBaseUrl} from "../../services/api";
import { data } from "dom7";




const FnftCardOwner = ({ name_, tokenId, tokenKey, imagelist, ownFractionCount, fractionPrice, erc20Address, from, to, imagelistLocal }) => {
  console.log("image: ", imagelist);
  let more;
  let name = name_;
  let [profileData, setProfileData] = React.useState([]);
  if(name.length > 14){
    more = '...';
  }
  const dispatch = useDispatch();
  let usdRate = useSelector((state) => state.allNft.usdRate);

//   useEffect(() => {
//     async function profile(){
      
//         try {
//           const acc = owner;
//           const response = await api
//           .get(`/saveUser/${acc}`)
//           .catch((err) => {
//               console.log("Err: ", err);
//           });
  
//           if(response.statusText = "OK"){
//               if(response.data != null){
//                   let data = response.data;
//                   setProfileData(data);
//                   //console.log(data);
//               }
//           }
//         } catch (error) {
          
//         }
//     };
  
//     profile();

    
//   }, [dispatch]);
  
  //
  //if(owner==profileData.address)
  //{
      //console.log("OK")
  //}
  return (

    <div className="col-sm-6 col-md-4">
        <div className="auction-card">
            <div className="auction-card-img">
                <Link to={`/fnft/${tokenKey}/${to}`}>
                    <img src={imagelistLocal?.image_3} alt="Images"/>
                </Link>
                <div className="auction-card-user">
                    {/* <a href={`/view-profile/${profileData.address}`} className="auction-card-user-option">
                        
                        {owner==profileData.address}
                        {profileData.image ? (
                            <img src={profileData.image}  alt="sdd"/>
                        ):(
                        <img src={userIco} alt="ff"/>
                        )}
                    </a> */}
                     <ProfileImageGet address={to} userIco={userIco} />
                </div>
            </div>
            <Link to={`/fnft/${tokenId}/${to}`}>
                <div className="content">
                    <h3>{name.slice(0, 14)}</h3>
                    <p className="fractionCount">Fractions#<strong>
                        {ownFractionCount}
                    </strong></p>
                    {/* <p><i className="bi bi-heart-fill"></i> 142</p> */}
                    <div className="auction-card-content">
                        <div className="card-left">
                            <span>Exit Price</span>
                            <h4><SvgIcon
                                    component={EthereumLogo}
                                    viewBox="0 0 400 426.6"
                                    titleAccess="ETH"
                                /> 
                                {(Web3.utils.fromWei(String(fractionPrice), "ether") * ownFractionCount)} 
                                <small style={{display: 'block', fontSize:'12px', marginTop:'5px'}}> {'$'+ ((Web3.utils.fromWei(String(fractionPrice), "ether") * ownFractionCount) * usdRate).toFixed(3)}</small>
                                </h4>
                        </div>
                        <div className="card-right">
                            <span>Fraction Price</span>
                            <h4><SvgIcon
                                    component={EthereumLogo}
                                    viewBox="0 0 400 426.6"
                                    titleAccess="ETH"
                                /> {Web3.utils.fromWei(String(fractionPrice), "ether")} 
                                <small style={{display: 'block', fontSize:'12px', marginTop:'5px'}}> {'$'+ (Web3.utils.fromWei(String(fractionPrice), "ether") * usdRate).toFixed(3)}</small>
                                </h4>
                                
                        </div>
                    </div>
                    <button type="button" className="default-btn">Details </button>
                </div>
            </Link>
        </div>
    </div>
    

  );
};

export default FnftCardOwner;
