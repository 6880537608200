import React from "react";
import { styles } from './styles.css';

const Layer = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="page-content">
                        <h1>About Us</h1>
                        <p>
                            MetaWirks Started in early 2022 with a vision to provide creators and collectors a platform to buy, sell, and discover exclusive crypto collectibles, non-fungible tokens (NFTs) and also for fractional ownership of NFTs.
                        </p>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            
        </div>
    )
}
export default Layer;