import React, { useEffect, useState } from "react";
import underconsImage from '../../assets/undercons.png';

const Underconstruction = () => {
  return (
    <div className="undercons" style={{ padding: "50px", textAlign:'center' }}>
        <img src={underconsImage} style={{maxWidth: "600px"}} />
    </div>
  );
};

export default Underconstruction;
