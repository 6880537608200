import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import AddIcon from '@material-ui/icons/Add';
import Typography from '@material-ui/core/Typography';
import blue from '@material-ui/core/colors/blue';
import userIco from '../../assets/user.png';
import { Link } from "react-router-dom";
import MetaMaskIco from '../../assets/metamask.png';
import nft from '../../assets/nft.png';
import graphIco from '../../assets/graph.png';

import user from '../../assets/user.png';
import edit from '../../assets/edit.png';
import trustIco from '../../assets/trust.png';
import { mystyles } from "./styles.css";
import getWeb3 from "../../utils/getWeb3";
import Alert from '@material-ui/lab/Alert';
import { api, defaultAccount, usdRate, defaultChainId, defaultNetworkType } from "../../services/api";

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
};

class SimpleDialog extends React.Component {
  handleClose = () => {
    this.props.onClose(this.props.selectedValue);
  };

  handleListItemClick = value => {
    this.props.onClose(value);
    
  };

  connectMetaMaske = async () => {
    console.log('hello from hrere');
    if (typeof window.ethereum !== 'undefined') {
      
      let isUnlock = await window.ethereum._metamask.isUnlocked();
      console.log(isUnlock);
      if(isUnlock){
        let accounts = await window.ethereum.request({ method: 'eth_accounts' });
      }else{
        
      await window.ethereum
        .request({
          method: 'wallet_requestPermissions',
          params: [{ eth_accounts: {} }],
        })
        .then((permissions) => {
          console.log(permissions);
          const accountsPermission = permissions.find(
            (permission) => permission.parentCapability === 'eth_accounts'
          );
          if (accountsPermission) {
            window.location.reload(true);
            console.log('eth_accounts permission successfully requested!');
          }
        })
        .catch(async (error) => {
          if (error.code === 4001) {
            // EIP-1193 userRejectedRequest error
            console.log('Permissions needed to continue.');
          }
          else if(error.code === -32000){
            const accList = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if(accList.length){
              window.location.reload(true)
            }
          } 
          else {
            console.log(error);
            const accList = await window.ethereum.request({ method: 'eth_requestAccounts' });
            if(accList.length){
              window.location.reload(true);
            }
          }
        });
      }
      
      
      // const connected = await window.ethereum.request({
      //   method: 'wallet_switchEthereumChain',
      //   params: [{ chainId: defaultChainId }],
      // });
      // console.log(connected);
      // if(accounts.length){
      //   window.location.reload();
      // }
    }else{
      window.open(
        'https://metamask.io/',
        '_blank'
      );
    }
    
  };

  connectOnBraveWalle = async () =>{

  }

  // connectMetaMaske = async () => {
  //   
  //   if (typeof window.ethereum !== 'undefined') {
  //     let isUnlock = await window.ethereum._metamask.isUnlocked();
  //     if(isUnlock){
  //       let accounts = await window.ethereum.request({ method: 'eth_accounts' });
  //       if(accounts.length){
  //         window.location.reload(true);
  //       }
  //     }else{
  //       window.ethereum
  //       .request({
  //         method: 'wallet_requestPermissions',
  //         params: [{ eth_accounts: {} }],
  //       })
  //       .then((permissions) => {
  //         const accountsPermission = permissions.find(
  //           (permission) => permission.parentCapability === 'eth_accounts'
  //         );
  //         if (accountsPermission) {
  //           console.log('eth_accounts permission successfully requested!');
  //         }
  //       })
  //       .catch((error) => {
  //         if (error.code === 4001) {
  //           // EIP-1193 userRejectedRequest error
  //           console.log('Permissions needed to continue.');
  //         }else if(error.code === -32000){
  //           const connected = window.ethereum.request({
  //             method: 'wallet_switchEthereumChain',
  //             params: [{ chainId: defaultChainId }],
  //           });
  //         } else {
  //           console.error(error);
  //         }
  //       });
  //     }
  //   }else{
  //     window.open(
  //       'https://metamask.io/',
  //       '_blank'
  //     );
  //   }
    
  // };

  // connectMetaMaske = async () => {
  //   try {
  //     // Will open the MetaMask UI
  //     // You should disable this button while the request is pending!
  //     const { ethereum } = window;
  //     await ethereum.request({ method: 'eth_requestAccounts' });
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  

 

  

  render() {
    const { classes, onClose, selectedValue, isconnected, ...other } = this.props;
    const account = sessionStorage.getItem('myAccount');

    let isShowAlert = true;
    if (typeof window.ethereum !== 'undefined') {
      isShowAlert = false;
    }
    
    
    let isConnected = isconnected;

    console.log("isConnected koce");
    console.log(isConnected);
   
    return (
      <Dialog onClose={this.handleClose} aria-labelledby="simple-dialog-title" {...other}>

        <div className="acc-modal">
          {isConnected ? (
            <div>
              <ul>
                <li onClick={() => this.handleListItemClick(account)}>
                  <a href="/">
                    <img src={user} /> <span>{account.slice(0,7)}...{account.slice(-4)}</span>
                  </a>
                </li>
                <li onClick={() => this.handleListItemClick(account)}>
                  <a href="/mynft">
                    <img src={nft} /> <span>My NFT</span>
                  </a>
                </li>
                <li onClick={() => this.handleListItemClick(account)}>
                  <a href="/myfnft">
                    <img src={graphIco} /> <span>My Fractional NFT</span>
                  </a>
                </li>
                <li onClick={() => this.handleListItemClick(account)}>
                  <a href="/myCollection">
                    <img src={nft} /> <span>My Collection</span>
                  </a>
                </li>
                <li onClick={() => this.handleListItemClick(account)}>
                  <a href="/edit-profile">
                    <img src={edit} /> <span>Edit Profile</span>
                  </a>
                </li>
              </ul>
             
            </div>
            

            ):(
              <div className="itemParent">
                <h5>Connect to a Wallet</h5>
                <hr></hr>
                <ul>
                    <li>
                      <button type="button" onClick={() => this.connectMetaMaske(account)}>
                        <img src={MetaMaskIco} />
                        <span>Connect with Metamask</span>
                      </button>
                    </li>
                    {/* <li>
                      <a onClick={() => this.handleListItemClick(account)} target="_blank">
                        <img src={trustIco} />
                        <span> Trust Wallet</span>
                      </a>
                    </li> */}
                  
                </ul>
                { isShowAlert ? ( <Alert severity="warning">MetaMask is not installed!</Alert>):(<span></span>)}
              </div>
              
            )}
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  selectedValue: PropTypes.string,
  showMessage: PropTypes.bool
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class SimpleDialogDemo extends React.Component {
  state = {
    open: false,
  };

  



  handleClickOpen = () => {
    this.setState({
      open: true,
    });
  };

  handleClose = value => {
    this.setState({ selectedValue: value, open: false });
  };

  render() {
    const account = this.props.myaccount;
    const profilePic = this.props.accImage;
    let isConnected = this.props.isconnected;
    return (
      <div>
        {/* <Typography variant="subtitle1">Selected: {this.state.selectedValue}</Typography>
        <br /> */}
        {/* <Button variant="outlined" color="primary" onClick={this.handleClickOpen}>
          Open simple dialog
        </Button> */}
        {isConnected ? (
          
          profilePic ? ( 
            <img onClick={this.handleClickOpen} src={profilePic} alt="" style={{height: '30px', width: '30px', borderRadius: '50%', cursor: 'pointer'}} />
           ):(
            <img onClick={this.handleClickOpen} src={userIco} alt="" style={{height: '30px', width: '30px', borderRadius: '50%', cursor: 'pointer'}} />
          )
          
          
        ):(
          <Button onClick={this.handleClickOpen} variant="contained" className="cnBtn" color="primary" disableElevation>
            Connect
          </Button>
        )}

        <SimpleDialogWrapped
          selectedValue={this.state.selectedValue}
          open={this.state.open}
          onClose={this.handleClose}
          myaccount={account}
          isconnected={isConnected}
        />
      </div>
    );
  }
}

export default SimpleDialogDemo;