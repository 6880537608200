import React from 'react';
import Web3 from "web3";
import {
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import Chart from 'chart.js/auto'
import { Line } from 'react-chartjs-2';

Chart.register(
  CategoryScale,
  LinearScale,
  LineElement,
  Title,
  Tooltip,
  Legend
);


// export const options = {
//   responsive: true,
  
//   plugins: {
//     legend: {
//       position: 'top',
//     },
//     title: {
//       display: false,
//       text: '',
//     },
//   },
// };



const PriceChart = (priceHistoryData) => {
    
    const labels = [];
    let dataList = [];
    for(let i=0; i < Object.keys(priceHistoryData.priceHistoryData.priceHistory).length; i++){
        let owner = priceHistoryData.priceHistoryData.priceHistory[i].owner.slice(0, 3) + "..." + priceHistoryData.priceHistoryData.priceHistory[i].owner.slice(-2);
        labels.push(owner);
        dataList.push( Web3.utils.fromWei(String(priceHistoryData.priceHistoryData.priceHistory[i].price), "ether"));
    }
    
    const data = {
        labels: labels,
        datasets: [
          {
            label: "Price",
            data: dataList,
            fill: true,
            backgroundColor: "rgba(75,192,192,0.2)",
            borderColor: "rgba(75,192,192,1)"
          }
        ]
      };
    // const data = {
    //     type: 'line',
    //     labels,
    //     datasets: [
    //       {
    //         label: 'Price Change Chart',
    //         data: dataList,
    //         fill: false,
    //         lineTension: 0.5,
    //         backgroundColor: 'rgba(75,192,192,1)',
    //         borderColor: 'rgba(0,0,0,1)',
    //         borderWidth: 2,
    //       }
    //     ],
    //   };
    return (
        <div>
            <Line data={data} />
        </div>
    )
}
export default PriceChart;