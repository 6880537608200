import axios from 'axios';

export const api = axios.create({
  cache: false,
  baseURL: process.env.REACT_APP_SITE_API_URL

});

const domain = process.env.REACT_APP_SITE_API_URL;
export default domain;


export const usdRate = axios.get(`${process.env.REACT_APP_ETH_TO_USD_URL}`).then((response) => {
  return response;
});

// image Url
export const imgBaseUrl = process.env.REACT_APP_IPFS_IMAGE_BASE_URL;

// ropsten
// export const defaultAccount = "0xF0f2896d974A76bC3d58057401E348BE205258C0";
// export const defaultChainId = "0x3";
// export const defaultNetworkType = "ropsten";

export const defaultAccount = process.env.REACT_APP_DEFAULT_ACCOUNT;
export const privateKey = process.env.REACT_APP_HD_WALLET_PRIVATE_KEY;
export const defaultChainId = process.env.REACT_APP_DEFAULT_CHAIN_ID;
//export const defaultChainIdNumber = '4';
export const defaultChainIdNumber = process.env.REACT_APP_DEFAULT_CHAIN_ID_NUMBER;
export const defaultNetworkType = process.env.REACT_APP_DEFAULT_NETWORK;
export const defaultNetworkName = process.env.REACT_APP_DEFAULT_NETWORK_NAME;
export const defaultNetworkRPC_URL = process.env.REACT_APP_DEFAULT_NETWORK_RPC_URL;
export const providerUrl = process.env.REACT_APP_HD_WALLET_PROVIDER_URL;
export const defaultNetworkBlockExploreUrl = process.env.REACT_APP_DEFAULT_NETWORK_BLOCK_EXPLORE_URL;
export const defaultNetworkCurrencySymbol = process.env.REACT_APP_DEFAULT_NETWORK_CURRENCY_SYMBOL;
export const defaultNetworkCurrencySymbolIcon = '<svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 560 400" xmlns="http://www.w3.org/2000/svg"><path d="m29 10.2c-.7-.4-1.6-.4-2.4 0l-5.6 3.3-3.8 2.1-5.5 3.3c-.7.4-1.6.4-2.4 0l-4.3-2.6c-.7-.4-1.2-1.2-1.2-2.1v-5c0-.8.4-1.6 1.2-2.1l4.3-2.5c.7-.4 1.6-.4 2.4 0l4.3 2.6c.7.4 1.2 1.2 1.2 2.1v3.3l3.8-2.2v-3.4c0-.8-.4-1.6-1.2-2.1l-8-4.7c-.7-.4-1.6-.4-2.4 0l-8.2 4.8c-.8.4-1.2 1.2-1.2 2v9.4c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l5.5-3.2 3.8-2.2 5.5-3.2c.7-.4 1.6-.4 2.4 0l4.3 2.5c.7.4 1.2 1.2 1.2 2.1v5c0 .8-.4 1.6-1.2 2.1l-4.2 2.5c-.7.4-1.6.4-2.4 0l-4.3-2.5c-.7-.4-1.2-1.2-1.2-2.1v-3.2l-3.8 2.2v3.3c0 .8.4 1.6 1.2 2.1l8.1 4.7c.7.4 1.6.4 2.4 0l8.1-4.7c.7-.4 1.2-1.2 1.2-2.1v-9.5c0-.8-.4-1.6-1.2-2.1z" fill="#8247e5" fill-rule="nonzero" transform="matrix(7.04961 0 0 7.04961 145 81.9191)" /></svg>'






