import React from "react";
import Web3 from "web3";
const OwnerPercentage = ({ singleOwnerdata, fractionsCountInitial }) => {
 
    let amount = parseInt(singleOwnerdata.ownFractionCount);
    let totalSupply = parseInt(fractionsCountInitial);
    let percentage = (amount/totalSupply)*100;
    let percentageStyle = String(percentage) + "%";
    
    let percentageStyleTooktio = String(percentage-3) + "%";

    const mystyle = {
        width: percentageStyle
    };
    
    
    return (
        <>
            <div className="tooltipnew" style={{width: "50px", textAlign: 'center', left: percentageStyleTooktio}}>
                <p>{percentageStyle}</p>
                <div className="arrow-down"></div>
            </div>
            <div className="bar d-flex" style={{maxWidth: '100%'}}>
                <div className="filled-bar" style={mystyle}></div>
            </div>
        </>
    );
};

export default OwnerPercentage;
