import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Fractionalizer from "../../contracts/Fractionalizer.json";

import getWeb3 from "../../utils/getWeb3";
import { api, defaultAccount } from "../../services/api";

import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import Loader from "../../components/Loader/Loader";
import SimpleSlider from "../../components/Slider/Slider";

import SearchGlobal from '../../components/Search/index';
import nodatafound from '../../assets/nodatafound.png';
import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
} from "../../redux/actions/nftActions";
import Card from "../../components/Card";
import NftCard from "../../components/NftCard";
import LatestNft from "../../components/LatestNft/LatestNft";

import { useStyles } from "./styles.js";
import { Styles } from "./styles.css";


// import veterans from "../../assets/arts/Sparse-Ahmed-Mostafa-vetarans-2.jpg";
// import lionKing from "../../assets/arts/suresh-pydikondala-lion.jpg";
// import dreaming from "../../assets/arts/phuongvp-maybe-i-m-dreaming-by-pvpgk-deggyli.jpg";
// import modeling3d from "../../assets/arts/alan-linssen-alanlinssen-kitbashkitrender2.jpg";
// import woman from "../../assets/arts/ashline-sketch-brushes-3-2.jpg";
// import stones from "../../assets/arts/rentao_-22-10-.jpg";
// import wale from "../../assets/arts/luzhan-liu-1-1500.jpg";
// import comic from "../../assets/arts/daniel-taylor-black-and-white-2019-2.jpg";
// import galerie from "../../assets/galerie.svg";
import loaderPlaceholder from "../../assets/placeholder-loading.gif";





const AllNft = () => {
  

  let nftItem = useSelector((state) => state.allNft.nft);
  let sortedItemList = nftItem.sort(function (a, b) { return b.tokenId - a.tokenId; });
  const account = useSelector((state) => state.allNft.account);
  console.log(account);
  let [allNftData, setAllNftData] = useState([]);

  let [allNftFresh, setAllNftFresh] = useState(nftItem);
  let [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [howManyLoad, sethowManyLoad] = useState(6);

  useEffect(() => {
    
    setAllNftData(sortedItemList);
  }, [sortedItemList]);



  let isLoadMoreBtnShow = true;
  // if(nftItem.length > 0){
  //   setAllNftData(nftItem);
  // }


  allNftData = allNftData.slice(0, howManyLoad);


  if (nftItem.length <= howManyLoad) {
    isLoadMoreBtnShow = false;
  }

  async function loadMoreNft() {

    if (allNftFresh.length > howManyLoad) {
      sethowManyLoad(howManyLoad + 8);
    }
  }

  const [sortBy, setSortBy] = useState('');
  async function sortByChange(event) {
    setSortBy(event.target.value);

    if (event.target.value === "price-asc") {
      let allNft = allNftData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      setAllNftData(allNft);
      console.log(allNft);
    } else if (event.target.value === "price-desc") {
      let allNft = allNftData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      setAllNftData(allNft);
      console.log(allNft);
    }
  };

  const [name, setName] = useState("");
  const handleSubmit = (evt) => {
    setIsLoading(true);
    evt.preventDefault();
    if (name != '') {
      let allNft = allNftFresh.filter(item => item.name.toLowerCase().includes(name.toLowerCase()));
      setAllNftData(allNft);
      console.log(allNft);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }





  return (
    <section className="explore-section">
      <div className={classes.allNftPage}>
        <div className="filter-sec">
          <div className="page-header">
            <div className="container">
              <div className="row">
                <div className="banner__Explore">
                  <h1>Explore NFT<small>s</small></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row" style={{ alignItems: 'center', paddingBottom: "10px", borderBottom: '1px solid rgb(218 218 218)' }}>
              <div className="col-md-3">
                <select value={sortBy} onChange={sortByChange} className="form-select" aria-label="Default select example">
                  <option value="all" defaultValue="all">Sort By</option>
                  <option value="price-asc">Price Asc.</option>
                  <option value="price-desc">Price Desc.</option>
                </select>
                {/* <Box sx={{ minWidth: 120 }}>
                              <FormControl fullWidth>
                                  <InputLabel id="demo-simple-select-label">Sort By</InputLabel>
                                  <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={sortBy}
                                  label="Sort By"
                                  onChange={sortByChange}
                                  >
                                  <MenuItem value="price-asc">Price Asc.</MenuItem>
                                  <MenuItem value="price-desc">Price Desc.</MenuItem>
                                  </Select>
                              </FormControl>
                          </Box> */}
              </div>
              <div className="col-md-6">
                {/* <div style={{ width: '100%' }}>
                              <div>
                                  <div>
                                  <form onSubmit={handleSubmit} className={classes.search}>
                                      <input value={name} onChange={e => setName(e.target.value)} type="text" className={classes.searchTerm} placeholder="Search By Title" />
                                      <button type="submit" className={classes.searchButton}>
                                          <svg style={{ height: '17px' }} className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g className="search-path" fill="none" stroke="#fff"><path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4"/><circle cx="8" cy="8" r="7"/></g></svg>
                                      </button>
                                  </form>
                                  
                                  </div>
                              </div>
                          </div> */}
              </div>
              <div className="col-md-3">
                <h3 className="counting">Total items: {allNftData.length}</h3>
              </div>
            </div>
            {/* <hr style={{margin: "0", marginTop: '10px'}} /> */}
          </div>

        </div>



        {Object.keys(allNftData).length > 0 ? (

          <div className="container">
            {/* <div className="row">
                      <div className="col-12">
                          <div className="section-header">
                              <h3>NFT</h3>
                          </div>
                      </div>
                  </div> */}

            <div className="row explore-content-section">
              {allNftData.map((nft) => (
                !nft.Fnfnt ? (
                  <NftCard item key={'alln' + nft.tokenId} {...nft}></NftCard>
                ) : ('')
              ))}

              {isLoadMoreBtnShow ? (
                <div className="col-lg-12">
                  <span className="btn-main lead m-auto" onClick={loadMoreNft}>Load More</span>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        ) : (
          <section className="explore-section">
            <div className="container">
              <img src={nodatafound} />
            </div>
          </section>
        )}


      </div>
    </section>
  );
};

export default AllNft;


