import React, { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CancelOutlinedIcon  from "@material-ui/icons/CancelOutlined";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';
import userIco from '../../../assets/user.png';

import DropZone from "../../../components/DropZone";
import { api } from "../../../services/api";
import { useStyles } from "./styles.js";
import { mystyle } from "./styles.css";
import Loader from "../../../components/Loader/Loader";
import ticketIco from '../../../assets/ticket.png';
import DateFormat from '../../../_pipe/DateFormat';
import { useDispatch, useSelector } from "react-redux";
import getWeb3 from "../../../utils/getWeb3";
import $ from 'jquery';
import moment from 'moment';

import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
} from "../../../redux/actions/nftActions";
const SupportTicketDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { ticketId } = useParams();
  const connectedAccount = useSelector((state) => state.allNft.account);
  let [account, setMyAccount] = useState(connectedAccount);
  const [message, setMessage] = useState("");
  const [isLoaderShow, setIsLoaderShow] = useState(false);
  const [classOfStatus, setClassOfStatus] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  let [supportTiketDeails, setSupportTiketDeails] = useState([]);
  let [userData, setUserData] = useState();
  let [messageList, setMessageList] = useState({});
  

  


  const [formData, setFormData] = useState({
    message: ''
  });


  useEffect(() => {

    const init = async () => {

      const messageListGet = await api
          .get(`/messagHistory/`+ticketId)
          .catch((err) => {
            console.log("Err: ", err);
      });

      if(messageListGet != undefined){
        if(messageListGet.data != ''){
          if(messageListGet.data.messageHistory != ''){
            setMessageList(messageListGet.data.messageHistory);
          }
          
        }
      }

      try {
        const singleSupportTicket = await api
          .get(`/supportTicketDetails/`+ticketId)
          .catch((err) => {
            console.log("Err: ", err);
          });
        console.log("singleSupportTicket");
        console.log(singleSupportTicket);
        if(singleSupportTicket != undefined){
            setSupportTiketDeails(singleSupportTicket.data[0]);
        }

        const web3 = await getWeb3();
        let accounts = await web3.eth.getAccounts();
        setMyAccount(accounts[0]);


        const userResponse = await api
        .get(`/saveUser/${accounts[0]}`)
        .catch((err) => {
            console.log("Err: ", err);
        });
        if(userResponse != undefined){
            if(userResponse.statusText = "OK"){
                if(userResponse.data != null){
                let data = userResponse.data;
                    setUserData(data);
                    console.log("user date");
                    console.log(data);
                }
            }
        }
        
        

        
      } catch (error) {
        
      }
    };
    init();
    
  }, []);

  function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  async function sendMessage(event) {
    
    event.preventDefault();
    $('.chat-message').scrollTop($('.chat-message')[0].scrollHeight);
    setIsLoaderShow(true);
    const { message } = formData;

    // console.log("bio: " + bio);
    // console.log("fbLink: " + fbLink);
    // console.log("twitterLink: " + twitterLink);
    // console.log("instagramLink: " + instagramLink);
    // let data = new FormData();
    // data.append("message", message);
    // data.append("user", userData.userName);

   
    let userDataGet = null;
    const userResponse = await api
        .get(`/saveUser/${account}`)
        .catch((err) => {
            console.log("Err: ", err);
        });
        if(userResponse != undefined){
            if(userResponse.statusText = "OK"){
                if(userResponse.data != null){
                let data = userResponse.data;
                userDataGet = data
                    setUserData(data);
                }
            }
        }

    
    let data = {
      "message": message,
      "user": userDataGet?.userName,
    }

    try {
      const response = await api.post("/messaging/"+ ticketId, data, {
        headers: {
          "Content-Type": `application/json; boundary=${data._boundary}`,
        },
      });
      console.log(response);
      if(response.status){
        setIsLoaderShow(false);
        if(response.statusText = "Created"){
          formData.message = "";
            let message = "Message has been sent!";
            setMessage(message);
            setClassOfStatus("success");

          const messageListGet = await api
              .get(`/messagHistory/`+ticketId)
              .catch((err) => {
                console.log("Err: ", err);
          });

          if(messageListGet != undefined){
            if(messageListGet.data != ''){
              if(messageListGet.data.messageHistory != ''){
                setMessageList(messageListGet.data.messageHistory);
              }
              
            }
          }

        }else{
          let message = "Error to created profile!";
          setMessage(message);
          setClassOfStatus("error");

        }
        // window.location.reload();
      }
    } catch (error) {
      setIsLoaderShow(false);
      console.log(error);
      // error.response.data
    }
  }



  return (
    <div className={classes.pageCreateNft}>
      <div className="container" style={{marginTop: "20px"}}>
        
        {isLoaderShow ? <Loader/> : <span></span> }
        <div className="main-body" style={{ margin: '50px 0' }}>
          <div className="row">
            <div className="col-md-8">
            {(() => {
              switch (supportTiketDeails?.status) {
                case "Pending": return <h4 className="alert alert-warning">Your Support ticket is on pending!</h4>;
                case "Open":  
                  return <div className="panel panel-default support-details">
                    <div className="panel-heading">Chat Messages</div>
                    <div className="panel-body">
                      <div className="chat-message">
                      
                      {messageList ? (
                        <div className="clearfix">
                        {Object.keys(messageList).map((key) => (
                          <div className={`chat-container ${messageList[key].user !="Admin" ? "darker" : ""}`}>
                            {messageList[key].user !="Admin" ? (
                            <img src={userData?.image} alt="Avatar" class="right" />
                            ):(
                              <img src={userIco} alt="Avatar" class="right" />
                            )}
                            <p>{messageList[key].message}</p>
                            {moment(messageList[key].sendingTime).format('lll')}
                          </div>
                        ))}
                        </div>
                      ) : (
                        ""
                      )}

                      </div>
                      <form style={{marginTop: '0'}} onSubmit={sendMessage}>
                        <div className="card">
                          <div className="card-body myProfile">
                            
                            <div className="row mb-3">
                              <div className="col-sm-9 text-secondary">
                                <div className="mb-20">
                                    <h6 className="mb-10">Message</h6>
                                    <textarea rows={6} className="form-control" name="message" value={formData.message} onChange={handleInputChange}></textarea>
                                </div>
                                {message != "" ?  <Alert severity={classOfStatus}>{message}</Alert> : <span></span> }
                                <div className="mb-20">
                                    <input type="submit" className="btn btn-primary px-4" value="Save Changes" />
                                </div>
                              </div>
                              {/* <div className="col-sm-3 text-secondary">
                                <div style={{marginBottom: '15px', marginTop: '24px'}}>
                                  {userImage ? <DropZone required name="Change Image" onFileUploaded={setSelectedFile} /> : <DropZone required name="Upload Profile Image" onFileUploaded={setSelectedFile} /> }
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>;
                case "Close":  return <h4 className="alert alert-warning">Your Support ticket has been closed!</h4>;
                default: return "";
              }
            })()}
         
            </div>
            <div className="col-md-4">
              <div className="panel panel-default support-details">
                <div className="panel-heading">Support Details</div>
                  <div className="panel-body">
                    <p><strong>Title:</strong> {supportTiketDeails?.title}</p>
                    <p><strong>Sub Title:</strong> {supportTiketDeails?.subTitle}</p>
                    <p><strong>Subject:</strong> {supportTiketDeails?.subject}</p>
                    <p><strong>Status:</strong> {supportTiketDeails?.status}</p>
                    <p><strong>description:</strong> {supportTiketDeails?.description}</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
        </div>
      </div>
  );
};

export default SupportTicketDetails;
