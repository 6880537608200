import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  allNftPage: {

  },
  images: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    
  },
  banner: {
    // minHeight: "50vh",
    // maxHeight: "50vh",
    // marginBottom: "3rem"
  },
  gridBanner: {
    // margin: 0,
    // width: '100%',
  },
  main: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 1rem",
    '& img': {
      width: "55%"
    },
    '& p': {
      margin: "3rem 8rem 4rem 8.5rem",
      fontSize: "1.2rem",
      textAlign: "center"
    },
    '& button': {
      textTransform: 'none',
      fontSize: "1.2rem",
      fontWeight: "400",
      background: '#3F51B5'
    }

  },
  allNfts: {
    padding: "2rem 0",
  },
  title: {
    fontFamily: "sans-serif",
    fontSize: "1.8rem",
    fontWeight: "600",
    marginBottom: "1rem",
  },
  search: {
    width: '100%',
    position: "relative",
    display: "flex",
  },
  
  searchTerm: {
    width: '100%',
    border: '1px solid #ececec',
    borderRight: 'none',
    padding: '5px 30px 5px 15px',
    height: '40px',
    borderRadius: '20px',
    outline: 'none',
    color: '#9DBFAF'
  },

  
  searchButton: {
    width: "45px",
    height: "40px",
    border: "1px solid #9794ea",
    background: "#9794ea",
    textAlign: "center",
    color: "#fff",
    borderRadius: "0 20px 20px 0",
    cursor: "pointer",
    fontSize: "20px",
    right: '0',
    position: 'absolute'
  }, 

  linkClass: {
    position: 'absolute',
    right: '0',
    top: '0',
    bottom: '0'
  }
});

export { useStyles };