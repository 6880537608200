import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { cssStyles } from "./styles.css";
import { api } from "../../../services/api";
import Loader from "../../../components/Loader/Loader";

import $ from 'jquery';
const CreateCollectionModal = () => {
    const history = useHistory();
    const [isload, setIsload] = useState(false);
    const [isSelectedLogo, setIsSelectedLogo] = useState(false);
    const [selectedLogoFile, setSelectedLogoFile] = useState();
    const [logofileUrl, setLogoFileUrl] = useState();
    const [uniqueNameStatus, setUniqueNameStatusError] = useState();
    const account = useSelector((state) => state.allNft.account);
    
    const changeHandlerLogo = (event) => {
        setSelectedLogoFile(event.target.files[0]);
        setLogoFileUrl(URL.createObjectURL(event.target.files[0]));
        setIsSelectedLogo(true);
    };
    
    const removeLogoImage = () => {
        setIsSelectedLogo(false);
        setLogoFileUrl('');
    }

    const [isSelectedfeatured, setIsSelectedfeatured] = useState(false);
    const [selectedfeaturedFile, setSelectedfeaturedFile] = useState();
    const [featuredfileUrl, setfeaturedFileUrl] = useState();
    const changeHandlerFeaturedImg = (event) => {
        
        setSelectedfeaturedFile(event.target.files[0]);
        setfeaturedFileUrl(URL.createObjectURL(event.target.files[0]));
        setIsSelectedfeatured(true);
    };
    
    const removeFeaturedImg = () => {
        setIsSelectedfeatured(false);
        setfeaturedFileUrl('');
    }

    const [isSelectedBannar, setIsSelectedBannar] = useState(false);
    const [selectedBannarFile, setSelectedBannarFile] = useState();
    const [BannarfileUrl, setBannarFileUrl] = useState();
    const changeHandlerBannar = (event) => {
        
        setSelectedBannarFile(event.target.files[0]);
        setBannarFileUrl(URL.createObjectURL(event.target.files[0]));
        setIsSelectedBannar(true);
    };
    
    const removeBannar = () => {
        setIsSelectedBannar(false);
        setBannarFileUrl('');
    }

    const [formData, setFormData] = useState({
        collectionName: "",
        userName: account,
        Description: "",
        Category: "0"
    });

    async function handleInputChange(event) {
        let { name, value } = event.target;
        // if(name === 'image'){
        //   value = event.target.files[0];
        // }
        setFormData({ ...formData, [name]: value });
    }

    async function checkNameIsExist(){
        if(formData.collectionName != ''){
            const isCheckData = await api
            .get(`/collectionSlugCheckExists/${formData.collectionName}`)
            .catch((err) => {
            console.log("Err: ", err);
            });
            if(isCheckData.data != undefined){
                if(isCheckData.data.status){
                    setUniqueNameStatusError(true);
                }else{
                    setUniqueNameStatusError(false);
                }
            }
        }
    }
    async function insertCollection(event){
        
        setIsload(true);
        event.preventDefault();
        const { collectionName, userName, Description, Category} = formData;
        const data = new FormData();
        data.append("Description", Description);
        data.append("Category", Category);

        
        if (selectedfeaturedFile) {
            data.append('feature', selectedfeaturedFile);
        }else{
            alert('Please add feature image!');
            setIsload(false);
            return;
        }
        if (selectedBannarFile) {
            data.append('banner', selectedBannarFile);
        }else{
            alert('Please add banner image!');
            setIsload(false);
            return;
        }
        if (selectedLogoFile) {
            data.append('logo', selectedLogoFile);
        }else{
            alert('Please add logo image!');
            setIsload(false);
            return;
        }

        try {
            const response = await api.post(`/createCollection/${account}/${collectionName}`, data, {
                headers: {
                  "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
                },
            });
            if(response.data != undefined){
                if(response.data.status){
                    history.push('/');
                }
            }
            setIsload(false);

        } catch (error) {
            console.log(error);
            setIsload(false);

            // error.response.data
      
          }
    }
    
    return (
        <>
             {/* <section>
                <div className="breadcrumb">
                    <div className="root_collection">
                        <a className="" href="#"><span>My Collections</span></a>
                        <div className=""><i className="fa-solid fa-chevron-right"></i></div>
                    </div>
                    <span className="">Create a Collec...</span>
                </div>
            </section> */}
            {isload ? <Loader /> : <span></span>}
            <section className="my-4">

                <div className="container">
                    <div className="row">
                        <div className="col-2"></div>
                        <div className="col-8">
                            <h1 className="collection-title">Create a Collection</h1>

                            <form className="mt-5" onSubmit={insertCollection}>
                                {/* <div className="mb-3">
                                    <p className="required_fields"><span className="required">*</span> Required fields</p>
                                </div> */}
                                <div className="logo-text">
                                    <label>Logo image</label>
                                    <span>This image will also be used for navigation. 350 x 350 recommended.</span>
                                    <div className="logo-circle">
                                        {!isSelectedLogo ? (
                                            <div>
                                                <input
                                                    type="file"
                                                    onChange={changeHandlerLogo}
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    name="file"
                                                    id="contained-button-file-logo"
                                                />
                                                <label htmlFor="contained-button-file-logo" className="button-file">
                                                    <span>PNG, GIF, WEBP, MP4 or MP3. Max 100mb.</span>
                                                    <p className="button-file-upload" style={{ marginBottom: '0', cursor: 'pointer' }}>
                                                    Choose File
                                                    </p>
                                                </label>
                                            </div>
                                        ) : (
                                            <div className="uploaded-image">
                                                <span onClick={removeLogoImage} className="cross" >&times;</span>
                                                <img style={{ height: '200px', width: '100%' }} src={logofileUrl} />
                                            </div>
                                        )}
                                        <div className="overlay-circle"></div>
                                    </div>
                                </div>
                                <div className="logo-text mt-4">
                                    <label>Featured image</label>
                                    <span>This image will be used for featuring your collection on the homepage, category pages, or other promotional areas of OpenSea. 600 x 400 recommended.</span>
                                    <div className="logo-square">
                                        {!isSelectedfeatured ? (
                                            <div>
                                                <input
                                                    type="file"
                                                    onChange={changeHandlerFeaturedImg}
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    name="file"
                                                    id="contained-button-file-fea"
                                                />
                                                <label htmlFor="contained-button-file-fea" className="button-file">
                                                    <span>PNG, GIF, WEBP, MP4 or MP3. Max 100mb.</span>
                                                    <p className="button-file-upload" style={{ marginBottom: '0', cursor: 'pointer' }}>
                                                    Choose File
                                                    </p>
                                                </label>
                                            </div>
                                        ) : (
                                            <div className="uploaded-image">
                                                <span onClick={removeFeaturedImg} className="cross" >&times;</span>
                                                <img style={{ height: '200px', width: '100%' }} src={featuredfileUrl} />
                                            </div>
                                        )}
                                        <div className="overlay-circle"></div>
                                    </div>
                                </div>
                                <div className="logo-text mt-4">
                                    <label >Banner image</label>
                                    <span>This image will appear at the top of your collection page. Avoid including too much text in this banner image, as the dimensions change on different devices. 1400 x 400 recommended.</span>
                                    <div className="logo-squareTwo">
                                    {!isSelectedBannar ? (
                                            <div>
                                                <input
                                                    type="file"
                                                    onChange={changeHandlerBannar}
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    name="file"
                                                    id="contained-button-file-Bannar"
                                                />
                                                <label htmlFor="contained-button-file-Bannar" className="button-file">
                                                    <span>PNG, GIF, WEBP, MP4 or MP3. Max 100mb.</span>
                                                    <p className="button-file-upload" style={{ marginBottom: '0', cursor: 'pointer' }}>
                                                    Choose File
                                                    </p>
                                                </label>
                                            </div>
                                        ) : (
                                            <div className="uploaded-image">
                                                <span onClick={removeBannar} className="cross" >&times;</span>
                                                <img style={{ height: '200px', width: '100%' }} src={BannarfileUrl} />
                                            </div>
                                        )}
                                        <div className="overlay-circle"></div>
                                    </div>
                                </div>
                                <div className="logo-text mt-4">
                                    <label >Name</label>
                                    <input type="text" className="input_common" 
                                     id="name" name="collectionName" 
                                    placeholder="Example: Treasures of the Sea" 
                                    required=""
                                    value={formData.collectionName}
                                    onBlur={checkNameIsExist}
                                    onChange={handleInputChange}
                                    />
                                    {uniqueNameStatus == true ? <p className="error"> &#9587; This name already taken!</p> : ''}
                                    {uniqueNameStatus == false ? <p className="success"> ✓ Yes all right!</p> : ''}

                                </div>
                                {/* <div className="logo-textt mt-4">
                                    <label >URL</label>
                                    <span>Customize your URL on OpenSea. Must only contain lowercase letters,numbers, and hyphens.</span>
                                    <input type="text" autoCapitalize="off" autoComplete="off" autoCorrect="off" className="input_common" data-testid="Input" id="name" name="name" placeholder="https://opensea.io/collection/treasures-of-the-sea" required="" />
                                </div> */}
                                <div className="logo-textt mt-4">
                                    <label >Description</label>
                                    <span>0 of 1000 characters used.</span>
                                    <textarea id="description" 
                                    rows="4" 
                                    name="Description"
                                    value={formData.Description}
                                    onChange={handleInputChange}
                                    className="textarea_comment"></textarea>
                                </div>
                                {/* <div className="logo-textt mt-4">
                                    <label >Category</label>
                                    <span>Adding a category will help make your item discoverable on OpenSea.</span>
                                    <button className="category-button" type="button" aria-expanded="false">Add category</button>
                                </div> */}
                                {/* <div className="logo-textt mt-4">
                                    <label >Links</label>
                                    <div className="links_Design mt-2">
                                        <div className="common_design_links">
                                            <svg fill="#8A939B" viewBox="0 0 20 16"><path d="M17.569.5H2.43C1.39.5.548 1.344.548 2.375l-.01 11.25A1.89 1.89 0 002.43 15.5H17.57a1.89 1.89 0 001.892-1.875V2.375A1.89 1.89 0 0017.57.5zm-4.73 13.125H2.43v-3.75h10.408v3.75zm0-4.688H2.43v-3.75h10.408v3.75zm4.73 4.688h-3.785V5.187h3.785v8.438z"></path></svg>
                                            <input aria-invalid="false"  id="externalUrl" placeholder="yoursite.io" />
                                        </div>
                                        <div className="common_design_links">
                                            <svg fill="#8A939B" viewBox="0 0 22 16"><path d="M8.11.5c-.28.002-2.574.067-4.996 1.873 0 0-2.584 4.665-2.584 10.408 0 0 1.507 2.593 5.473 2.719 0 0 .664-.792 1.202-1.477-2.278-.685-3.14-2.108-3.14-2.108s.18.126.502.307c.018 0 .035.019.07.036.055.035.108.054.162.09.448.252.896.45 1.31.611.736.307 1.615.576 2.639.774 1.346.252 2.925.342 4.646.019a12.954 12.954 0 002.603-.774 10.118 10.118 0 002.062-1.063s-.896 1.458-3.247 2.125c.538.666 1.185 1.439 1.185 1.439 3.965-.126 5.473-2.72 5.473-2.7 0-5.746-2.584-10.409-2.584-10.409C16.32.446 13.861.5 13.861.5l-.251.288c3.05.918 4.468 2.27 4.468 2.27a14.856 14.856 0 00-5.4-1.711 15.048 15.048 0 00-3.626.036c-.107 0-.197.019-.306.035-.628.072-2.153.288-4.073 1.135-.663.288-1.059.505-1.059.505S5.088 1.635 8.317.717L8.137.5h-.028zm-.457 6.645c1.022 0 1.849.882 1.83 1.981 0 1.1-.808 1.982-1.83 1.982-1.005 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981zm6.55 0c1.004 0 1.83.882 1.83 1.981 0 1.1-.809 1.982-1.83 1.982-1.006 0-1.83-.883-1.83-1.982s.806-1.981 1.83-1.981z"></path></svg>
                                            <input aria-invalid="false"  id="externalUrl" placeholder="https://discord.gg/abcdef" />
                                        </div>
                                        <div className="common_design_links">
                                            <svg fill="#8A939B" viewBox="0 0 24 24"><path d="M15.75 2H8.25C4.79875 2 2 4.79875 2 8.25V15.75C2 19.2012 4.79875 22 8.25 22H15.75C19.2012 22 22 19.2012 22 15.75V8.25C22 4.79875 19.2012 2 15.75 2ZM20.125 15.75C20.125 18.1625 18.1625 20.125 15.75 20.125H8.25C5.8375 20.125 3.875 18.1625 3.875 15.75V8.25C3.875 5.8375 5.8375 3.875 8.25 3.875H15.75C18.1625 3.875 20.125 5.8375 20.125 8.25V15.75Z"></path><path d="M12 7C9.23875 7 7 9.23875 7 12C7 14.7613 9.23875 17 12 17C14.7613 17 17 14.7613 17 12C17 9.23875 14.7613 7 12 7ZM12 15.125C10.2775 15.125 8.875 13.7225 8.875 12C8.875 10.2763 10.2775 8.875 12 8.875C13.7225 8.875 15.125 10.2763 15.125 12C15.125 13.7225 13.7225 15.125 12 15.125Z"></path><path d="M17.375 7.29124C17.743 7.29124 18.0413 6.99295 18.0413 6.62499C18.0413 6.25703 17.743 5.95874 17.375 5.95874C17.0071 5.95874 16.7088 6.25703 16.7088 6.62499C16.7088 6.99295 17.0071 7.29124 17.375 7.29124Z"></path></svg>
                                            <input aria-invalid="false"  id="externalUrl" placeholder="https://www.instagram.com/YourInstagramHandle" />
                                        </div>
                                        <div className="common_design_links">
                                            <svg fill="#8A939B" viewBox="0 0 18 15"><path d="M17.83 14.847h-7.003c.088-.186.129-.34.225-.444.37-.388.781-.744 1.143-1.131.113-.122.218-.324.218-.485.016-2.56.008-5.113.008-7.674 0-.04-.008-.073-.032-.21-.089.218-.153.355-.21.493-1.191 3.029-2.39 6.066-3.582 9.095-.072.178-.096.372-.386.372-.282.008-.362-.153-.45-.355a4583.69 4583.69 0 01-4.163-9.112c-.072-.154-.144-.307-.281-.445v.3c0 2.124-.016 4.248.008 6.38 0 .243.12.526.273.728.532.694 1.103 1.357 1.65 2.036.09.113.137.258.234.444H.122c.088-.186.136-.348.233-.46.523-.647 1.046-1.293 1.585-1.931.234-.283.338-.582.338-.954-.016-2.544 0-5.089-.024-7.633 0-.267-.129-.574-.29-.792-.426-.582-.91-1.107-1.352-1.664C.507 1.268.452 1.098.33.872h2.182c.87 0 1.747.008 2.616-.008.282-.008.418.089.531.347 1.192 2.658 2.407 5.3 3.614 7.949.049.105.105.21.186.371.563-1.405 1.11-2.754 1.65-4.103.571-1.422 1.127-2.844 1.706-4.257.049-.121.193-.291.29-.291C14.667.864 16.22.872 17.878.872c-.112.194-.16.34-.257.444-.37.372-.773.711-1.12 1.099-.144.161-.265.412-.265.622-.016 3.215-.016 6.43 0 9.653 0 .21.121.46.266.622.338.388.724.719 1.079 1.09.08.106.136.251.25.445z"></path></svg>
                                            <input aria-invalid="false"  id="externalUrl" placeholder="https://www.medium.com/@YourMediumHandle" />
                                        </div>
                                        <div className="common_design_links">
                                            <svg fill="#8A939B" viewBox="0 0 24 16"><path clipRule="evenodd" d="M18.28 15.456c.317.168.725.21 1.09.107.363-.104.631-.337.712-.62.854-3.013 2.928-10.64 3.706-13.38.06-.207-.04-.421-.256-.56A1 1 0 0022.748.9C18.625 2.045 5.921 5.62.729 7.06c-.329.092-.543.33-.532.59.012.262.246.488.583.564 2.329.522 5.385 1.25 5.385 1.25s1.428 3.234 2.173 4.88c.093.206.309.369.593.425.283.055.586-.003.798-.153l3.046-2.157s3.513 1.933 5.506 2.997zM7.45 9.054L9.1 13.14l.367-2.587 10.02-6.778c.106-.072.12-.193.032-.278-.088-.085-.249-.104-.37-.047L7.45 9.054z" fillRule="evenodd"></path></svg>
                                            <input aria-invalid="false"  id="externalUrl" placeholder="https://t.me/abcdef" />
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="logo-textt mt-4">
                                    <label >Creator Earnings</label>
                                    <span>Collect a fee when a user re-sells an item you originally created. This is deducted from the final sale price and paid monthly to a payout address of your choosing.</span>
                                    <a href="#">Learn more about creator earnings.</a>
                                    <span>Percentage fee</span>
                                    <input type="text" autoCapitalize="off" autoComplete="off" autoCorrect="off" className="input_common" data-testid="Input" id="name" name="name" placeholder="e.g. 2.5" required="" />
                                </div> */}
                                {/* <div className="logo-textt mt-4">
                                    <label >Blockchain</label>
                                    <div className="manage d-flex align-items-center">
                                        <p>
                                            Select the blockchain where you'd like new items from this collection to be added by default.
                                        </p>
                                        <a href="#"><i className="fa-solid fa-info"></i></a>
                                    </div>
                                <div className="d-flex align-items-center rinkeyby">
                                    <img src="https://testnets.opensea.io/static/images/logos/ethereum.png" alt="" />
                                    <input aria-invalid="false" id="defaultChain" name="defaultChain" readOnly="" aria-haspopup="true" className="input-common" />
                                    <i className="fa-solid fa-angle-down"></i>
                                </div>
                                </div> */}
                                {/* <div className="logo-textt mt-4">
                                    <label >Payment tokens</label>
                                    <div className="manage d-flex align-items-center">
                                        <p>
                                            These tokens can be used to buy and sell your items.
                                        </p>
                                        <a href="#"><i className="fa-solid fa-info"></i></a>
                                    </div>
                                    <div className="payment_token">
                                        <div className="payment_portion">
                                                <div className="payment-img">
                                                    <img src="https://storage.opensea.io/files/6f8e2979d428180222796ff4a33ab929.svg" alt="" />
                                                </div>
                                                <div className="payment-content">
                                                    <span>ETH</span>
                                                    <span>Rinkeby</span>
                                                </div>
                                        </div>
                                        <div className="payment_portion">
                                                <div className="payment-img">
                                                    <img src="https://storage.opensea.io/files/accae6b6fb3888cbff27a013729c22dc.svg" alt="" />
                                                </div>
                                                <div className="payment-content">
                                                    <span>ETH</span>
                                                    <span>Rinkeby</span>
                                                </div>
                                        </div>
                                        <div className="payment_portion">
                                                <div className="payment-content">
                                                    <span>Rinkeby</span>
                                                </div>
                                        </div>
                                        <div className="payment_portion">
                                                <div className="payment-content">
                                                    <span>Rinkeby</span>
                                                </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="d-flex align-items-center rinkeyby">
                                    <input aria-invalid="false" id="defaultChain" name="defaultChain" readOnly="" aria-haspopup="true" className="input-common" />
                                    <i className="fa-solid fa-angle-down"></i>
                                </div>
                                <div className="logo-textt mt-4">
                                    <label >Display theme</label>
                                    <span>Change how your items are shown.</span>
                                    <div className="display-button">
                                        <button type="button">
                                            <div className="card-div">
                                                <img alt="" src="https://testnets.opensea.io/static/images/card-display-padded.svg" />
                                                <header className="">Padded</header>
                                                <div className="card-content">Recommended for assets with transparent background</div>
                                            </div>
                                        </button>
                                        <button type="button">
                                            <div className="card-div">
                                                <img alt="" src="https://testnets.opensea.io/static/images/card-display-padded.svg" />
                                                <header className="">Padded</header>
                                                <div className="card-content">Recommended for assets with transparent background</div>
                                            </div>
                                        </button>
                                        <button type="button">
                                            <div className="card-div">
                                                <img alt="" src="https://testnets.opensea.io/static/images/card-display-padded.svg" />
                                                <header className="">Padded</header>
                                                <div className="card-content">Recommended for assets with transparent background</div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className="logo-textt mt-4 d-flex align-items-center justify-content-between">
                                    <div>
                                        <label >Explicit & sensitive content</label>
                                        <div className="manage d-flex align-items-center">
                                            <p>
                                                Set this collection as explicit and sensitive content
                                            </p>
                                            <a href="#"><i className="fa-solid fa-info"></i></a>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked />
                                        </div>
                                    </div>
                                </div> */}
                                <div className="create-button">
                                    <button type="submit">Create</button>
                                </div>
                            </form>
                        </div>
                        <div className="col-2"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreateCollectionModal;