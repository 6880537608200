import React from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { styles } from "./styles.css";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";
import image from "../../assets/cover-img4.jpg"
import starIco from "../../assets/2.png"
import { imgBaseUrl } from "../../services/api";

const TopSellingNfts = ({ latestNft }) => {     
    latestNft = latestNft.slice(0,6);
    return (
        <>
            <div className="topselling-nfts">
                <div className="container">
                    <div className="row">
                        {latestNft.map((nft) => (
                            <div className="col-md-4" key={'ts'+nft.tokenId+1}>
                                <div className="topselling-nftitem">
                                    <Link to={`/nft/${nft.tokenKey}`} className="tsn-link">
                                        <h5>
                                            {nft.name.slice(0, 14)}
                                            {nft.name.length > 14 ? (
                                                <small>...</small>
                                            ):(
                                                <small></small>
                                            )}
                                        </h5>
                                        <img src={nft.imagelistLocal?.image_0} alt="" />
                                    </Link>
                                </div>
                            </div>
                        ))}
         
                    </div>
                </div>
            </div>
        </>
    );
};

export default TopSellingNfts;
