import { ActionTypes } from "../constants/action-types";

const intialState = {
  nft: [],
  fnft: [],
  usdRate: '',
  balance: '0',
  account: '',
  marketplaceContract: null,
  marketLazyContract: null,
  marketLazyContractV2: null,
  fractionalizerContract: null,
  fractionsImplContract:null,
  fractionalizeNFTContract:null,
  marketplaceContractGasLess: null,

};

export const nftReducer = (state = intialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.SET_NFT:
      return { ...state, nft: payload };
    case ActionTypes.SET_LazyNFT:
      return { ...state, lazynft: payload };
      case ActionTypes.SET_LazyNFTV2:
      return { ...state, lazynftV2: payload };
    case ActionTypes.SET_FNFT:
      return { ...state, fnft: payload };
    case ActionTypes.SET_BALANCE:
      return { ...state, balance: payload };
    case ActionTypes.SET_USD_RATE:
    return { ...state, usdRate: payload };
    case ActionTypes.SET_ACCOUNT:
      return { ...state, account: payload };
    
      
    case ActionTypes.SET_MARKET_CONTRACT:
      return { ...state, marketplaceContract: payload };

    case ActionTypes.SET_MARKET_CONTRACT_GAS_LESS:
      return { ...state, marketLazyContract: payload };
    case ActionTypes.SET_MARKET_CONTRACT_GAS_LESSV2:
      return { ...state, marketLazyContractV2: payload };

    case ActionTypes.SET_FRACTIONALIZER_CONTRACT:
      return { ...state, fractionalizerContract: payload };
    case ActionTypes.SET_FRACTIONSIMPL_CONTRACT:
      return { ...state, fractionsImplContract: payload };
    case ActionTypes.SET_FRACTIONALIZENFT_CONTRACT:
      return { ...state, FractionalizeNFTContract: payload };
    default:
      return state;
  }
};

export const selectedNftReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SELECTED_NFT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_NFT:
      return {};
    default:
      return state;
  }
};
export const selectedLazyNftReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SET_LazyNFT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_LazyNFT:
      return {};
    default:
      return state;
  }
};
export const selectedLazyNftReducerV2 = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SET_LazyNFTV2:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_LazyNFTV2:
      return {};
    default:
      return state;
  }
};

const intialStateAccountInfo = {
  isConnected: false
};

export const accountReducer = (state = intialStateAccountInfo, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SET_IS_CONNECTED:
      return { ...state, isConnected: payload };
    default:
      return state;
  }
};

export const selectedFNftReducer = (state = {}, { type, payload }) => {
  console.log(type);
  switch (type) {
    case ActionTypes.SELECTED_FNFT:
      return { ...state, ...payload };
    case ActionTypes.REMOVE_SELECTED_FNFT:
      return {};
    default:
      return state;
  }
};

