import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Button from '@material-ui/core/Button';
import SearchGlobal from './../Search/index';
import { useStyles } from './styles.js';
import { cssStyles } from './styles.css';
import logo from '../../assets/theBITLabs-Logo/metawirks.png';
import img from '../../assets/logo/logo.png';
import bglight from '../../assets/footer-img/bg-light.jpg';
import { api, defaultAccount } from "../../services/api";

const Footer = (props) => {
    const classes = useStyles();
    const account = useSelector((state) => state.allNft.account);
    const [allCatagories, setAllCatagories] = useState([]);
    const dispatch = useDispatch();
    const [isConnected, setIsConnectedAccount] = useState(props.isConnected);

    useEffect(() =>{
        const initConnetion = async () => {
            let isCon = JSON.parse(sessionStorage.getItem('isConnected'));
            if(!isCon){
              let test = await props.checkNetworkConnection();
              isCon = JSON.parse(sessionStorage.getItem('isConnected'));
              setIsConnectedAccount(isCon);
            }else{
              setIsConnectedAccount(isCon);
              await props.getAbiData();
            }
            
        }
        initConnetion();

    }, []);

    useEffect(() => {
        
        const init = async () => {
            
            try {
                if (allCatagories.length === 0) {
                    const responses = await api
                        .get(`/getCategories/`)
                        .catch((err) => {
                            console.log("Err: ", err);
                        });;
                    let allCat = Object.keys(responses.data).map((key) => responses.data[key])
                    setAllCatagories(allCat);
                }
            } catch (error) {
                console.error("Error,single: ", error);
            }

        };
        init();
    }, [dispatch]);

    return (
        <React.Fragment>
            <div className={classes.footer}>
                {/* <footer className="footer-section">
                <div className="container">
                <div className="footer-content pt-5 pb-5">
                        <div className="row">
                            <div className="col-xl-4 col-lg-4 mb-50">
                                <div className="footer-widget">
                                    <div className="footer-logo">
                                        <img src={logo} className="img-fluid" alt="logo" />
                                    </div>
                                    <div className="footer-text">
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30"></div>
                            <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                                <div className="footer-widget">
                                    <div className="footer-widget-heading" style={{ textAlign: 'left' }}>
                                        <h3>Useful Links</h3>
                                    </div>
                                    <ul className={classes.footerLink}>
                                        <li><a href="#">Home</a></li>
                                        <li><a href="#">about</a></li>
                                        <li><a href="#">Terms</a></li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="footer-cta pt-5 pb-5">
                        <div className="row">
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta">
                                    <i className="fas fa-map-marker-alt"></i>
                                    <div className="cta-text">
                                        <h4>Find us</h4>
                                        <span>Prime Tech Solution Ltd, Karwan Bazar, Dhaka.</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta">
                                    <i className="fas fa-phone"></i>
                                    <div className="cta-text">
                                        <h4>Call us</h4>
                                        <span>0180000000</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-md-4 mb-30">
                                <div className="single-cta">
                                    <i className="far fa-envelope-open"></i>
                                    <div className="cta-text">
                                        <h4>Mail us</h4>
                                        <span>info@primetechbd.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 text-center text-lg-left">
                                <div className="copyright-text">
                                    <p>Copyright &copy; 2021, All Right Reserved <a style={{color: '#000'}} href="#">theBITLabs</a></p>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </footer> */}
                {/* <footer className="footer-section">
        <div className="container">
            <div className="footer-cta pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-map-marker-alt"></i>
                            <div className="cta-text">
                                <h4>Find us</h4>
                                <span>
                                Prime Tech Solutions Ltd.<br />
                                13, Kazi Nazrul Islam Avenue,<br />
                                T. K. Bhaban (7th Floor).<br />
                                Dhaka -1215, Bangladesh.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="fas fa-phone"></i>
                            <div className="cta-text">
                                <h4>Call us</h4>
                                <span> +8802-8189807</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-4 mb-30">
                        <div className="single-cta">
                            <i className="far fa-envelope-open"></i>
                            <div className="cta-text">
                                <h4>Mail us</h4>
                                <span>info@primetechbd.com</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-content pt-5 pb-5">
                <div className="row">
                    <div className="col-xl-4 col-lg-4 mb-50">
                        <div className="footer-widget">
                            <div className="footer-logo">
                                <a href="/"><img src={logo} className="img-fluid" alt="logo" /></a>
                            </div>
                            <div className="footer-text">
                                <p></p>
                            </div>
                          
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Useful Links</h3>
                            </div>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/underconstruction">about</a></li>
                                <li><a href="/underconstruction">services</a></li>
                                <li><a href="/underconstruction">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                        <div className="footer-widget">
                            <div className="footer-widget-heading">
                                <h3>Subscribe</h3>
                            </div>
                            <div className="footer-text mb-25">
                                <p>Don’t miss to subscribe to our new feeds, kindly fill the form below.</p>
                            </div>
                            <div className="subscribe-form">
                                <form action="/underconstruction">
                                    <input type="text" placeholder="Email Address" />
                                    <button>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="copyright-area">
            <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                        <div className="copyright-text">
                            <p>Copyright &copy; 2021, All Right Reserved <a href="/">theBITLabs</a></p>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div className="footer-menu">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/underconstruction">Terms</a></li>
                                <li><a href="/underconstruction">Privacy</a></li>
                                <li><a href="/underconstruction">Policy</a></li>
                                <li><a href="/underconstruction">Contact</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer> */}
                <footer className="footer-light">
                    <div className="footer-top" style={{ backgroundImage: `url(${bglight})` }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-1 col-sm-6 col-12"></div>
                                <div className="col-md-2 col-sm-6 col-12">
                                    <div className="widget">
                                        <h5>Marketplace</h5>
                                        <ul>
                                            <li><a href="/all-nft">All NFTs</a></li>
                                            <li><a href="/all-fnft">All FNFTs</a></li>
                                            {Object.keys(allCatagories).map((key) => (
                                                <li><a href={`/nft-by-category/${allCatagories[key].categoryID}`}>{allCatagories[key].category}</a></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-6 col-12">
                                    <div className="widget">
                                        <h5>Resources</h5>
                                        <ul>
                                            {/* <li><a href="/helpcenter">Help Center</a></li> */}
                                            <li><a href="/faq">FAQs</a></li>
                                            <li><a href="/underconstruction">Blog</a></li>
                                            <li><a href="/underconstruction">Platform Status</a></li>
                                            {/* <li><a href="/underconstruction">Docs</a></li>
                                            <li><a href="/underconstruction">Newsletter</a></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-6 col-12">
                                    <div className="widget">
                                        <h5>Community</h5>
                                        <ul>
                                            <li><a href="/underconstruction">Twitter</a></li>
                                            <li><a href="/underconstruction">Instagram</a></li>
                                            <li><a href="/underconstruction">Discord</a></li>
                                            <li><a href="/underconstruction">Youtube</a></li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-6 col-12">
                                    <div className="widget">
                                        <h5>Company</h5>
                                        <ul>
                                            <li><a href="/About">About</a></li>
                                            <li><a href="/underconstruction">Partnerships</a></li>
                                            <li><a href="/Careers">Careers</a></li>
                                        </ul>
                                    </div>
                                </div>
                                {isConnected ? (
                                    <div className="col-md-2 col-sm-6 col-12">
                                        <div className="widget">
                                            {/* <h5>Language</h5>
                                        <select className="selectpicker" data-width="fit">
                                            <option data-content='<span className="flag-icon flag-icon-us"></span> English'>English
                                            </option>
                                            
                                        </select> */}

                                            <h5>My Account</h5>
                                            <ul>
                                                <li><a href="/edit-profile">Profile</a></li>
                                                <li><a href="/mynft">My NFTs</a></li>
                                                <li><a href="/myfnft">My Fractional NFTs</a></li>
                                                <li><a href="/generator-home">Generate NFT</a></li>
                                                <li><a href="/create-Support-ticket">Support</a></li>
                                            </ul>

                                        </div>
                                    </div>
                                ) : (
                                    <span></span>
                                )}
                                <div className="col-md-1 col-sm-6 col-12"></div>
                            </div>
                        </div>
                    </div>
                    <div className="subfooter">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="de-flex">
                                        <div className="de-flex-col"><span><img alt="" className="f-logo d-1"
                                            src={logo} /><span className="copy">Powered by <a href="http://thebitlabs.com/">TheBITLabs</a></span></span>
                                        </div>
                                        <div className="de-flex-col">
                                            <div className="social-icons">
                                                <span>
                                                    <Link to={{ pathname: "/terms" }}>Terms of services</Link>
                                                </span>
                                                {/* <span>
                                                    <i className="fab fa-twitter fa-lg"></i>
                                                </span>
                                                <span>
                                                    <i className="fab fa-linkedin fa-lg"></i>
                                                </span>
                                                <span>
                                                    <i className="fab fa-pinterest fa-lg"></i>
                                                </span>
                                                <span>
                                                    <i className="fa fa-rss fa-lg"></i>
                                                </span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default Footer;
