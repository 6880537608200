import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import SearchGlobal from '../../components/Search/index';
import nodatafound from '../../assets/nodatafound.png';
import FNftCard from "../../components/FNftCard";

import { useStyles } from "./styles.js";
import { Styles } from "./styles.css";


// import veterans from "../../assets/arts/Sparse-Ahmed-Mostafa-vetarans-2.jpg";
// import lionKing from "../../assets/arts/suresh-pydikondala-lion.jpg";
// import dreaming from "../../assets/arts/phuongvp-maybe-i-m-dreaming-by-pvpgk-deggyli.jpg";
// import modeling3d from "../../assets/arts/alan-linssen-alanlinssen-kitbashkitrender2.jpg";
// import woman from "../../assets/arts/ashline-sketch-brushes-3-2.jpg";
// import stones from "../../assets/arts/rentao_-22-10-.jpg";
// import wale from "../../assets/arts/luzhan-liu-1-1500.jpg";
// import comic from "../../assets/arts/daniel-taylor-black-and-white-2019-2.jpg";
// import galerie from "../../assets/galerie.svg";





const AllFNft = () => {

  let fnftItem = useSelector((state) => state.allNft.fnft);
  console.log('sdsdfsdsdfd');
  console.log(fnftItem);

  const [userImage, setUserImage] = useState("");

  const account = sessionStorage.getItem('myAccount');
  console.log(account);



  let [allFNftData, setAllFNftData] = useState(fnftItem);


  // setAllFNftData(fnftItem);
  let [allFNftFresh, setAllFNftFresh] = useState([]);
  let [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [howManyLoad, sethowManyLoad] = useState(6);

  let isLoadMoreBtnShow = true;
  // if(fnftItem.length > 0){
  //   setAllFNftData(fnftItem);
  // }
  useEffect(() => {
    
    setAllFNftFresh(fnftItem);
    setAllFNftData(fnftItem.slice(0, howManyLoad));
  }, [fnftItem]);

  // allFNftData = allFNftData.slice(0, howManyLoad);




  if (fnftItem.length <= howManyLoad) {
    isLoadMoreBtnShow = false;
  }

  async function loadMoreNft() {
    if (allFNftFresh.length > howManyLoad) {
      sethowManyLoad(howManyLoad + 3);
    }
  }

  const [sortBy, setSortBy] = useState('');
  async function sortByChange(event) {
    setSortBy(event.target.value);

    if (event.target.value === "price-asc") {
      let allNft = allFNftData.sort((a, b) => parseFloat(a.price) - parseFloat(b.price));
      setAllFNftData(allNft);
      console.log(allNft);
    } else if (event.target.value === "price-desc") {
      let allNft = allFNftData.sort((a, b) => parseFloat(b.price) - parseFloat(a.price));
      setAllFNftData(allNft);
      console.log(allNft);
    }
  };

  const [name, setName] = useState("");
  const handleSubmit = (evt) => {
    setIsLoading(true);
    evt.preventDefault();
    if (name != '') {
      let allNft = allFNftFresh.filter(item => item.name.toLowerCase().includes(name.toLowerCase()));
      setAllFNftData(allNft);
      console.log(allNft);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }





  return (
    <section className="explore-section">
      <div className={classes.allNftPage}>

        <div className="filter-sec">
          <div className="page-header">
            <div className="container">
              <div className="row">
                <div className="banner__Explore">
                  <h1>Explore FNFT<small>s</small></h1>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row" style={{ alignItems: 'center', paddingBottom: "10px", borderBottom: '1px solid rgb(218 218 218)' }}>
              <div className="col-md-3">
                <select value={sortBy} onChange={sortByChange} className="form-select" aria-label="Default select example">
                  <option value="all" defaultValue="all">Sort By</option>
                  <option value="price-asc">Price Asc.</option>
                  <option value="price-desc">Price Desc.</option>
                </select>
              </div>
              <div className="col-md-6">
                {/* <div style={{ width: '100%' }}>
                <div>
                  <div>
                    <form onSubmit={handleSubmit} className={classes.search}>
                      <input value={name} onChange={e => setName(e.target.value)} type="text" className={classes.searchTerm} placeholder="Search By Title" />
                      <button type="submit" className={classes.searchButton}>
                        <svg style={{ height: '17px' }} className="svg-icon search-icon" aria-labelledby="title desc" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.9 19.7"><title id="title">Search Icon</title><desc id="desc">A magnifying glass icon.</desc><g className="search-path" fill="none" stroke="#fff"><path strokeLinecap="square" d="M18.5 18.3l-5.4-5.4" /><circle cx="8" cy="8" r="7" /></g></svg>
                      </button>
                    </form>

                  </div>
                </div>
              </div> */}
              </div>
              <div className="col-md-3">
                <h3 className="counting">Total items: {allFNftData?.length}</h3>
              </div>
            </div>
            {/* <hr style={{margin: "0", marginTop: '10px'}} /> */}
          </div>
        </div>



        {Object.keys(allFNftData)?.length > 0 ? (

          <div className="container">

            <div className="row explore-content-section">
              {allFNftData?.map((nft) => (
                <FNftCard item key={'allf' + nft.tokenId} {...nft}></FNftCard>
              ))}

              {isLoadMoreBtnShow ? (
                <div className="col-lg-12">
                  <span className="btn-main lead m-auto" onClick={loadMoreNft}>Load More</span>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </div>


        ) : (
          <section className="explore-section">
            <div className="container">
              <img src={nodatafound} />
            </div>
          </section>
        )}


      </div>
    </section>
  );
};

export default AllFNft;


