import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({

  headerSearch: {
    margin: '20px 0'
  },
  logo: {
    width: "auto",
    height: '70px'
  },
  account: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
  },
  walletIcon: {
    marginRight: "0.4rem",
  },
  userIco: {
    maxHeight: '30px'
  },
  listStyle: {
    listStyle: 'none',
    padding: '0',
    display: 'flex',
    marginLeft: '10px',
    position: 'relative',
    marginBottom: '0',
    alignItems: 'center'
  },
  dropDown: {
    padding: '10px',
    listStyle: 'none',
    position: 'absolute',
    display: 'none',
    width: '100px',
    left: '0',
    textAlign: 'left',
    background: '#c7cef7',

    '& a': {
      color: "#000"
    },
  },
  active: {
    display: 'block'
  }
});

export { useStyles };