import { combineReducers } from "redux";
import { nftReducer, selectedNftReducer,selectedLazyNftReducer,selectedLazyNftReducerV2, selectedFNftReducer, accountReducer } from "./nftReducer";

const reducers = combineReducers({
  allNft: nftReducer,
  nft: selectedNftReducer,
  lazynft:selectedLazyNftReducer,
  lazynftV2:selectedLazyNftReducerV2,
  fnft: selectedFNftReducer,
  accountInfo: accountReducer
});

export default reducers;
