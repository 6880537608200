import React, {useEffect, useState} from "react";
import { api } from "../services/api";
const ProfileImageGet = ({ address, userIco }) => {
    
    let [profileImage, setProfileImage] = useState('');
    useEffect(() => {
        
        const init = async () => {
            setProfileImage('')
          const acc = address;
          try {
            const response = await api
            .get(`/saveUser/${acc}`)
            .catch((err) => {
                console.log("Err: ", err);
            });
    
            if(response.statusText = "OK"){
                if(response.data != null){
                    let data = response.data;
                    if(data.image != ''){
                        setProfileImage(data.image);
                    }
                    
                }
            }
            
            
          } catch (error) {
            
          }
        };
        init();
        
      }, []);
    
    
    return (
        <>
            {profileImage && (
                <img src={profileImage} alt="User Icon yes" style={{height: '30px'}} />
            )}
            {!profileImage && (
                <img src={userIco} alt="User Icon error" style={{height: '30px'}} />
            )}
        </>
    );
};

export default ProfileImageGet;