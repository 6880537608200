import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Card from "../../components/Card";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStyles } from "./styles.js";
import nodatafound from '../../assets/nodatafound.png';
import loaderPlaceholder from "../../assets/placeholder-loading.gif";
import FNftCard from "../../components/FNftCard";
import { cssStyles } from "./styles.css";

const MyFNft = () => {
  const classes = useStyles();
  let [isLoading, setIsLoading] = useState(true);
  const account = sessionStorage.getItem('myAccount');
  console.log(account);
  let fnftItem = useSelector((state) => state.allNft.fnft);
  console.log("nftItem");
  console.log(fnftItem);
 
  

  return (
    
    <div className={classes.homepage}>
      {Object.keys(fnftItem).length > 0 ? (
        <section className="allNfts">
          
          <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="section-header" style={{marginBottom: '20px'}}>
                        <h3>⚡️ My Fractional NFTs</h3>
                    </div>
                </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Tabs>
                  <TabList>
                    <Tab>All Collections</Tab>
                    {/* <Tab>Brought</Tab> */}
                  </TabList>

                  {/* all */}
                  
                  <TabPanel>
                  <div className="row">
                  {fnftItem.map((nft) => (
                        nft.owner==account ?
                        <FNftCard item key={"all"+nft.tokenId} {...nft}></FNftCard>
                        :
                        <span></span>
                        
                      ))}
                    {/* <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {fnftItem.map((nft) => (
                        nft.owner==account ?
                        <Grid item key={nft.tokenId}>
                          <Card {...nft} />
                        </Grid>
                        :
                        <span></span>
                        
                      ))}
                    </Grid> */}
                    </div>
                  </TabPanel>
                  

                  {/* brought */}
                  {/* <TabPanel>
                  <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {fnftItem.map((nft) => (
                        <FNftCard item key={"buy"+nft.tokenId} {...nft}></FNftCard>
                      ))}
                    </Grid>
                  </TabPanel> */}
                </Tabs>
              </div>
            </div>
          </div>
          
          
        </section>
      ):(
        isLoading ? (
          <div className="clearfix image-loader" style={{textAlign:'center', margin: '30px'}}>
            <img style={{marginBottom:'10px'}} src={loaderPlaceholder} />
            <img style={{marginBottom:'10px'}} src={loaderPlaceholder} />
          </div>
          ):(
          <section className="explore-section">
            <div className="container">
              <img src={nodatafound} />
            </div>
          </section> 
        )
      )}

       
    </div>
  );
};

export default MyFNft;
