import React from "react";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { styles } from './styles.css';


// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';

const Faq = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <h1>FAQ's</h1>
                    <div className="faq-div">
                        <h4><u>Non Fungible Tokens</u></h4>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is an NFT?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>An NFT is a digital asset stored on the blockchain. These assets hold information, like descriptions, properties, and media files. NFTs are unique, one-of-a-kind, and non-interchangeable.</p>
                                    <p>The history of every NFT is recorded on the blockchain, meaning buyers can prove their ownership and creators can receive royalties every time their work is re-sold. NFTs can be traded on marketplaces, proudly displayed in online galleries or used to access exclusive content and real-life experiences.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What does it cost to partner with MetaWirks?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>MetaWirks doesn't charge for partnerships or promotion. </p>
                                    <p>We only take our usual 2.5% on every MetaWirks transaction for both primary and secondary sales - amongst the lowest in the industry.</p>
                                    <p>Our free API serves a large number of requests per week, powering NFTs displayed on mobile wallets, games, art platforms, and even other marketplaces.</p>
                                    <p>Currently, we’re most interested in promoting projects that have multi-tiered sales (i.e. 1-of-1s, 1-of-manys, etc), have interesting & unique artwork, include robust digital unlockable content experiences, and have already been marketed in well-trafficked channels. If you fit this description, please fill in our partnership request form here.</p>

                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                    <div className="faq-div">
                        <h4><u>Fractional Ownership of NFTs</u></h4>
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What does Collective Sale mean and what are its advantages?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Collective Sale allows a group of people to trustlessly buy fractions of an NFT before it is even fractionalized. It is a much easier and faster way to monetize your NFT.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Who can buy fractions of my NFT?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Anyone can participate in this process.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What is a Target Price?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>A Target Price is the initial reserve price for the NFT. In other words, it is the price you want your NFT to be sold for.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    How does the sale process work?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Once your NFT has been made available for purchase, anyone can participate in the process no matter how much of the NFT they are looking to acquire. As soon as the Target Price is reached, a countdown will start, allowing other people to participate in the purchase too. At the end of the countdown, the NFT is fractionalized and all the participants in the Collective Sale receive a proportional number of Fractions according to their contribution. Immediately thereafter, the NFT is made available to be repurchased in the MetaWirks marketplace via auction.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is it possible to partially accept an offer?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Yes, it is. As the seller, even if the Target Price hasn’t been reached yet, you can accept the amount accumulated so far. In this case, you would receive the funds, as well as the remaining Fractions of the NFT.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Is it possible to edit/cancel my sale?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>You can only edit your Target Price or cancel your sale before someone participates in the purchase of your NFT. As soon as someone wants to buy any portion of your NFT and joins the purchasing process, you will no longer be able to edit your sale.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Can a buyer edit/cancel their participation?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Buyers have the option to remove their participation until the countdown starts.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        What happens when the NFT is sold?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>Once the sale is concluded, the NFT is Fractionalized via Auction with a Reserve Price five times higher than the final value it was sold for.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        Which fees does MetaWirks charge for Collective Sale?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>MetaWirks charges a platform fee of 5% on the final value of the NFT. If the offer is partially accepted, a penalty fee of 5% will also be charged on the remaining fractions of the buyer.</p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            
        </div>
    )
}
export default Faq;