import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import Card from "../../components/Card";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useStyles } from "./styles.js";
import nodatafound from '../../assets/nodatafound.png';
import loaderPlaceholder from "../../assets/placeholder-loading.gif";
import { api, defaultAccount, usdRate } from "../../services/api";
import getWeb3 from "../../utils/getWeb3";
import { cssStyles } from "./styles.css";

const MyNft = () => {
  
  const classes = useStyles();
  let [isLoading, setIsLoading] = useState(true);
  let [nftItem, setNftItem] = useState([]);
  const acc = sessionStorage.getItem('myAccount');

  let [account, setAccount] = useState(acc);

  
  console.log(account);
  // let nftItem = useSelector((state) => state.allNft.nft);



  console.log("nftItem");
  console.log(nftItem);
  // if(nftItem.length > 0){
  //   setIsLoading(false);
  // }
  useEffect(() => {
    debugger;
  const initMyNft = async () => {
    debugger;
    try {
        // const web3 = await getWeb3();
        // let accounts = await web3.eth.getAccounts();
        // let myaccount = accounts[0];
        // setAccount(myaccount);

      const responses = await api
        .get(`/tokenAddress/` + account)
        .catch((err) => {
          console.log("Err: ", err);
        });
      console.log('sadsadsfzfsdfsfz');
      console.log(responses);
      setNftItem(responses.data);

    } catch (error) {

    }
  };
  initMyNft();
  
}, []);  


  return (

    <div className={classes.homepage}>
      {Object.keys(nftItem).length > 0 ? (
        <section className="allNfts">

          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="section-header" style={{ marginBottom: '20px' }}>
                  <h3>⚡️ My NFTs</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Tabs>
                  <TabList>
                    <Tab>All Collections</Tab>
                    <Tab>For Sale</Tab>
                    <Tab>Bought</Tab>
                  </TabList>

                  {/* all */}
                  <TabPanel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {nftItem.map((nft) => (
                          <Grid item key={"nftall"+nft.tokenId}>
                            <Card {...nft} />
                          </Grid>

                      ))}
                    </Grid>
                  </TabPanel>

                  {/* for sale */}
                  <TabPanel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {nftItem.map((nft) => (
                        nft.isForSale ?
                          <Grid item key={"nfts"+nft.tokenId}>
                            <Card {...nft} />
                          </Grid>
                          :
                          <span></span>

                      ))}
                    </Grid>
                  </TabPanel>

                  {/* brought */}
                  <TabPanel>
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={2}
                    >
                      {nftItem.map((nft) => (
                        nft.isSold && !nft.isForSale ?
                          <Grid item key={"nftb"+nft.tokenId}>
                            <Card {...nft} />
                          </Grid>
                          :
                          <span></span>

                      ))}
                    </Grid>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>


        </section>
      ) : (
        isLoading ? (
          <div className="clearfix image-loader" style={{textAlign:'center', margin: '30px'}}>
            <img style={{marginBottom:'10px'}} src={loaderPlaceholder} />
            <img style={{marginBottom:'10px'}} src={loaderPlaceholder} />
          </div>
        ) : (
          <section className="explore-section">
            <div className="container">
              <img src={nodatafound} />
            </div>
          </section>
        )
      )}


    </div>
  );
};

export default MyNft;
