import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import CancelOutlinedIcon  from "@material-ui/icons/CancelOutlined";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Alert from '@material-ui/lab/Alert';

import DropZone from "../../../components/DropZone";
import { api } from "../../../services/api";
import { useStyles } from "./styles.js";
import { mystyle } from "./styles.css";
import Loader from "../../../components/Loader/Loader";
import ticketIco from '../../../assets/ticket.png';


import { useDispatch, useSelector } from "react-redux";
import getWeb3 from "../../../utils/getWeb3";
import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
} from "../../../redux/actions/nftActions";
const CreateSupport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  const connectedAccount = useSelector((state) => state.allNft.account);
  let [account, setMyAccount] = useState(connectedAccount);
  const [message, setMessage] = useState("");
  const [isLoaderShow, setIsLoaderShow] = useState(false);
  const [classOfStatus, setClassOfStatus] = useState("");
  const [userImage, setUserImage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  let [supportTiketList, setSupportTiketList] = useState([]);
  let [isProfielCreated, setIsProfielCreated] = useState(false);


  const [formData, setFormData] = useState({
    title: "",
    subTitle: "",
    email: "",
    userAddress: "",
    subject: "",
    description: ""
  });


  useEffect(() => {
      
    const init = async () => {
      try {
        const web3 = await getWeb3();
        let accounts = await web3.eth.getAccounts();
            setMyAccount(accounts[0]);

        const mySupportTicketList = await api
          .get(`/supportTickets/`+accounts[0])
          .catch((err) => {
            console.log("Err: ", err);
          });

        const userProfileData = await api
          .get(`/saveUser/${accounts[0]}`)
          .catch((err) => {
            console.log("Err: ", err);
        });
        console.log("userProfileData");
        console.log(userProfileData);
        if(userProfileData != undefined){
          if(userProfileData.data != ''){
            setIsProfielCreated(true);
          }
        }
        if(mySupportTicketList != undefined){
            setSupportTiketList(mySupportTicketList.data);
        }
        

        
      } catch (error) {
        
      }
    };
    init();
    
  }, [dispatch, supportTiketList, isProfielCreated]);

  function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  async function submitSupportTicket(event) {
    event.preventDefault();
    setIsLoaderShow(true);
    const { title, subTitle, email, subject, description } = formData;

    // console.log("bio: " + bio);
    // console.log("fbLink: " + fbLink);
    // console.log("twitterLink: " + twitterLink);
    // console.log("instagramLink: " + instagramLink);
    const data = new FormData();
    data.append("title", title);
    data.append("subTitle", subTitle);
    data.append("email", email);
    
    data.append("userAddress", account);
    data.append("subject", subject);
    data.append("description", description);
    
    if(selectedFile){
      data.append("img", selectedFile);
    }
    

    try {
    
      const response = await api.post("/createSupportTicket", data, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      });
    
      console.log(response);
      if(response.status){
        setIsLoaderShow(false);
        if(response.statusText = "Created"){
          let message = "Created successfully!";
          setMessage(message);
          setClassOfStatus("success");
        }else{
          let message = "Error to created profile!";
          setMessage(message);
          setClassOfStatus("error");

        }
        window.location.reload();
      }
    } catch (error) {
      setIsLoaderShow(false);
      console.log(error);
      // error.response.data
    }
  }



  return (
    <div className={classes.pageCreateNft}>
      <div className="container" style={{marginTop: "20px"}}>
        
        {isLoaderShow ? <Loader/> : <span></span> }
        <div className="main-body" style={{ margin: '50px 0' }}>
        {isProfielCreated ?
          <div className="row">
            <div className="col-md-8">
              {message != "" ?  <Alert severity={classOfStatus}>{message}</Alert> : <span></span> }
              <form style={{marginTop: '0'}} onSubmit={submitSupportTicket}>
                <div className="card">
                  <div className="card-body myProfile">
                    
                    <div className="row mb-3">
                      <div className="col-sm-9 text-secondary">
                        <div className="mb-20">
                          <h6 className="mb-10">Title :</h6>
                          <input type="text"  onChange={handleInputChange} name="title" required className="form-control" value={formData.title} />
                        </div>
                        <div className="mb-20">
                          <h6 className="mb-10">Sub Title: </h6>
                          <input type="text" name="subTitle" className="form-control" required value={formData.subTitle} onChange={handleInputChange} />
                        </div>
                        <div className="mb-20">
                            <h6 className="mb-10">Email</h6>
                            <input className="form-control" type="email" name="email" value={formData.email} onChange={handleInputChange} />
                        </div>
                        <div className="mb-20">
                            <h6 className="mb-10">Subject</h6>
                            <input className="form-control" name="subject" value={formData.subject} onChange={handleInputChange} />
                        </div>
                        <div className="mb-20">
                            <h6 className="mb-10">Upload File</h6>
                            <input type="file" name="file" onChange={(e) => setSelectedFile(e.target.files[0])} />
                        </div>
                        <div className="mb-20">
                            <h6 className="mb-10">Description</h6>
                            <textarea rows={6} className="form-control" name="description" value={formData.description} onChange={handleInputChange}></textarea>
                        </div>
                        <div className="mb-20">
                            <input type="submit" className="btn btn-primary px-4" value="Save Changes" />
                        </div>
                      </div>
                      {/* <div className="col-sm-3 text-secondary">
                        <div style={{marginBottom: '15px', marginTop: '24px'}}>
                          {userImage ? <DropZone required name="Change Image" onFileUploaded={setSelectedFile} /> : <DropZone required name="Upload Profile Image" onFileUploaded={setSelectedFile} /> }
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="col-md-4">
                <h4>My Support List</h4>
                {supportTiketList.map((Item) => (
                    <Link  key={"support"+Item.ticketId} to={`/support/${Item.ticketId}`}>
                        <div className="card-list">
                            <div className="row">
                                <div className="col-9">
                                    <div style={{display:"flex", alignItems: 'center'}}>
                                        <img width={40} src={ticketIco} />
                                        <div style={{marginLeft: '5px'}}>
                                            <h6 style={{marginBottom: "5px", lineHeight: '12px'}}>{Item.title}</h6>
                                            <small>{Item.issueDate.slice(0, 15)}</small>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-3" style={{textAlign:'center'}}>
                                    <div className="alert alert-success" style={{padding: '6px'}}>{Item.status}</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
          </div>
        : 
        <div className="row">
          <div className="col-sm-3"></div>
          <div className="col-sm-6">
            <div className="gotoProfile">
              <h4 className="alert alert-warning">You Need to create profile to get support</h4>
              <Link className="btn btn-info" to="/edit-profile">Create Profile</Link>
            </div>
          </div>
          <div className="col-sm-3"></div>
        </div> 
        }
        </div>
      </div>
      
    </div>
  );
};

export default CreateSupport;
