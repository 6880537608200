import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { ethers } from 'ethers'
import Web3Modal from 'web3modal'
import { useStyles } from "./styles.js";
import { cssStyles } from "./styles.css";
import Web3 from "web3";
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import IconButton from '@material-ui/core/IconButton';
import DropZone from "../../components/DropZone";

import { api, defaultNetworkCurrencySymbolIcon } from "../../services/api";
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Loader from "../../components/Loader/Loader";
import PreViewNft from "../../components/PreviewNft/index";
import { InputGroup } from "react-bootstrap";
import successIco from "../../assets/success.png";
import loaderMazic from '../../assets/loader-mazic.gif';
import scannerImg from '../../assets/scanning_landcape.gif';
import { EditorState } from 'draft-js';
import { convertToHTML } from 'draft-convert';
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Resizer from "react-image-file-resizer";
import $ from 'jquery';
import getWeb3 from "../../utils/getWeb3";
import { property } from "lodash";
import Select from 'react-select';
import { uuid } from 'uuidv4';
import Slider from '@material-ui/core/Slider';
import Swal from 'sweetalert2';
import ArtMarketplaceWithRoyalityV2 from "../../contracts/ArtMarketplaceWithRoyalityV2.json";
import LazyNFT from "../../contracts/LazyNFT.json";
const { LazyMinter } = require("../../lib");

const CreateNFT = () => {
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const classes = useStyles();
  const history = useHistory();
  const [tokenid, setTokenid] = useState([]);
  const [allCatagories, setAllCatagories] = useState([]);

  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );

  const accountGet = sessionStorage.getItem('myAccount');
  let [account, setAccount] = useState(accountGet);
  let artMarketplaceContractGet = useSelector(
    (state) => state.allNft.marketplaceContract
  );
  let [artMarketplaceContract, setArtMarketplaceContract] = useState(artMarketplaceContractGet);

  let marketLazyContractGet = useSelector(
    (state) => state.allNft.marketLazyContract
  );

  let [marketLazyContract, setMarketLazyContract] = useState(marketLazyContractGet);




  const [selectedFile, setSelectedFile] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isAdultContent, setIsAdultContent] = useState(false);

  
  const [isload, setIsload] = useState(false);
  const [categoryID, setCategoryID] = useState('0');
  const [collectionSlug, setCollectionSlug] = useState('');

  const [processType, setProcessType] = useState('');
  const [isShowCreateFrom, setIsShowCreateFrom] = useState(false);
  const [isCollectable, setIsCollectable] = useState(false);
  const [myAllCollection, setMyAllCollections] = useState([]);
  const [propertyName, setPropertyName] = useState('');
  const [propertyValue, setPropertyValue] = useState('');
  const [propertyList, setPropertyList] = useState({});
  const [royalityPercentage, setRoyalityPercentage] = useState(0);



  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    address: "",
    categoryID: "0"
  });
  const [convertedContent, setConvertedContent] = useState(null);

  useEffect(() => {
    debugger;

    const initAccountAndNetwork = async () => {
      debugger;

      const web3 = await getWeb3();
      let myaccount = await web3.eth.getAccounts();
      setAccount(myaccount[0]);
      console.log("myaccount", myaccount);
      const networkId = await web3.eth.net.getId();
      const marketplaceContractData = new web3.eth.Contract(
        ArtMarketplaceWithRoyalityV2.abi,
        ArtMarketplaceWithRoyalityV2.networks[networkId].address
      );
      setArtMarketplaceContract(marketplaceContractData);
      debugger;

      const totalSupply = await marketplaceContractData.methods.GetSaleItem().call();

      console.log('totalSupply New', totalSupply);


      const marketLazyContractData = new web3.eth.Contract(
        LazyNFT.abi,
        LazyNFT.networks[networkId].address
      );
      setMarketLazyContract(marketLazyContractData);
      setIsload(false);
    }
    initAccountAndNetwork();
  }, []);

  useEffect(() => {

    const init = async () => {
      try {
        if (allCatagories.length === 0) {
          const responses = await api
            .get(`/getCategories/`)
            .catch((err) => {
              console.log("Err: ", err);
            });
          let allCat = Object.keys(responses.data).map((key) => responses.data[key]);

          let allCatData = [];
          if (Object.keys(allCat).length) {
            Object.keys(allCat).map((key) => (
              allCatData.push({ value: allCat[key].categoryID, label: allCat[key].category })
            ));
          }
          setAllCatagories(allCatData);

        }

        let myacc = account;
        if (account != '') {
          myacc = account;
        } else {
          const web3 = await getWeb3();
          let accounts = await web3.eth.getAccounts();
          myacc = accounts[0];
        }

        const collections = await api
          .get(`/getUserwiseCollection/${myacc}`)
          .catch((err) => {
            console.log("Err: ", err);
          });
        let allCollection = Object.keys(collections.data).map((key) => collections.data[key]);
        console.log(allCollection);
        let collectionOptions = [];
        if (Object.keys(collections.data).length) {
          Object.keys(allCollection).map((key) => (
            collectionOptions.push({ value: allCollection[key].slag, label: allCollection[key].collectionName })
          ));
        }
        setMyAllCollections(collectionOptions);
      } catch (error) {
        setIsload(false);
        console.error("Error,single: ", error);
      }

      const web3 = await getWeb3();
      const networkId = await web3.eth.net.getId();

      const marketplaceContractData = new web3.eth.Contract(
        ArtMarketplaceWithRoyalityV2.abi,
        ArtMarketplaceWithRoyalityV2.networks[networkId].address
      );
      const totalItemsForSale = await marketplaceContractData.methods
      .GetSaleItem()
      .call();
      console.log("totalItemsForSale");
      console.log(totalItemsForSale);
    }

    init();

  }, []);

  async function handleInputChange(event) {
    let { name, value } = event.target;
    // if(name === 'image'){
    //   value = event.target.files[0];
    // }
    setFormData({ ...formData, [name]: value });
  }

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }


  const handleRoyalityChange = (event, newValue) => {
    setRoyalityPercentage(newValue);
  };

  const handleChangeCategory = (selectedOption) => {
    setCategoryID(selectedOption.value);
  };

  const handleChangeCollection = (selectedOption) => {

    setCollectionSlug(selectedOption.value);
  };



  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }

  async function createNFTData(event) {

    setIsload(true);
    event.preventDefault();
    const { title, description, price } = formData;
    // const web3 = await getWeb3();
    // let accounts = await web3.eth.getAccounts();

    const web3 = await getWeb3();
    let myaccount = await web3.eth.getAccounts();
    const networkId = await web3.eth.net.getId();
    let myacc = myaccount[0];

    console.log("title: " + title);
    //  let description = convertedContent;

    const data = new FormData();
    data.append("name", title);
    data.append("description", description);
    data.append("saleId", null);
    data.append("price", Web3.utils.toWei(String(price), "ether"));
    data.append("seller", myacc);
    data.append("owner", myacc);
    data.append("creator", myacc);
    data.append("isForSale", true);
    data.append("isSold", false);
    data.append("categoryID", categoryID);
    data.append("collectionName", collectionSlug);
    data.append("properties", JSON.stringify(propertyList));


    //data.append("processType", processType);


    if (selectedFile) {
      data.append('img', selectedFile);
      console.log("slectedFile: ", selectedFile);
    }
    try {


      const allSupply = await api
        .get(`/tokens/`)
        .catch((err) => {
          console.log("Err: ", err);
        });

      let total = Object.keys(allSupply.data).length;

      let tokenIDUUID = uuid();

      data.append("tokenId", tokenIDUUID);
      data.append("royalityPercentage", royalityPercentage);
      setTokenid(tokenIDUUID);


      if (processType == "gasless") {
        data.append("isGasless", 'yes');
        const marketLazyContractData = new web3.eth.Contract(
          LazyNFT.abi,
          LazyNFT.networks[networkId].address
        );
        continueWithGassLess(data, tokenIDUUID, marketLazyContractData);
      } else if (processType == "traditional") {
        data.append("isGasless", 'no');
        const response = await api.post("/tokens", data, {
          headers: {
            "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
          },
        });
        const marketplaceContractData = new web3.eth.Contract(
          ArtMarketplaceWithRoyalityV2.abi,
          ArtMarketplaceWithRoyalityV2.networks[networkId].address
        );
        mint(response.data.message[0], description, response.data.message[1], title, Web3.utils.toWei(String(price), "ether"), response.data.tokenId, myacc, tokenIDUUID, marketplaceContractData);
      }

    } catch (error) {
      console.log(error);
      // error.response.data

    }
  }



  async function continueWithGassLess(data, tokenIDUUID, marketLazyContractData) {
    const { title, description, price } = formData;
    const response = await api.post("/tokens", data, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
      },
    });
    debugger;
    let message = {
      tokenId: response.data.tokenId,
      price: Web3.utils.toWei(String(price), "ether"),
      uri: response.data.message[0]
    }
    debugger;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner(account);


    const contractsign = await marketLazyContractData.methods.getLazyContractAddress().call();


    const lazyMinter = new LazyMinter({
      contract: marketLazyContractData,
      signer: signer,
    });
    let minPrice = Web3.utils.toWei(String(price), "ether");
    const voucher = await lazyMinter.createVoucher(response.data.tokenId, response.data.message[0], minPrice)
    debugger;
    let datademo = { tokenId: tokenIDUUID, voucher: voucher, signature: voucher.signature };
    const voucherStatusupdate = await api.post("/voucherUpdate", datademo, {
      headers: {
        "Content-Type": `application/json; boundary=${data._boundary}`,
      },
    });

    let updateMintingStatus = { tokenId: response.data.tokenId };
    const updateStatusAfterMinting = await api.post("/updateStatusAfterMinting", updateMintingStatus, {
      headers: {
        "Content-Type": `application/json; boundary=${data._boundary}`,
      },
    });
    console.log("updateStatusAfterMinting", updateStatusAfterMinting);
    setIsload(false);

    Swal.fire({
      title: '<strong>Gasless NFT successfully Created!</strong>',
      icon: 'success',
      showCloseButton: false,
      showCancelButton: false
    }).then((result) => {
      history.push('/mynft');
      window.location.reload();
    });


  }


  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setFileUrl(URL.createObjectURL(event.target.files[0]));
    setIsSelected(true);
    checkAdultContent(event.target.files[0]);
    // try {
    //   

    //     const file = event.target.files[0];
    //     const image = await resizeFile(file);
    //   

    //     setSelectedFile(image);
    //     setFileUrl(URL.createObjectURL(event.target.files[0]));
    //     setIsSelected(true);
    //     console.log(image);
    //   } catch (err) {
    //     console.log(err);
    //   }
  };

  const checkAdultContent = async (imageFile) => {
   
      Swal.fire({
        title: `<strong style="display: block; text-align: center"><img style="max-width: 100%; max-height: 200px; margin: 0 auto" src=${scannerImg} /></strong>`,
        html: '<h4 class="alert alert-info">Please wait.. we are testing adult content in your nft!</h4>',
        showCloseButton: false,
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: false
      });  

      const data = new FormData();
      data.append("image", imageFile);

      const response = await api.post("/nsfw", data, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
      });
      console.log(response);
      if(response != null){
        setTimeout(function(){ 
          Swal.close(); 
          if(response.data.length){
            let pornAttr = 0;
            let hentaiAttr = 0;
            for(let i = 0; i < response.data.length; i++){
              if(response.data[i].className == "Porn"){
                pornAttr = response.data[i].probability;
              }else if(response.data[i].className = "Hentai"){
                hentaiAttr = response.data[i].probability;
              }
            }
            let persentCalculate = (pornAttr * 100);
            let hentaiAttrPercent = (hentaiAttr * 100);
            debugger;

            if(persentCalculate > 10 || hentaiAttrPercent > 80){
              setIsAdultContent(true);
              Swal.fire({
                title: `Attention Please`,
                icon: 'warning',
                html: '<h5 class="alert alert-warning">Adult content detected in your nft</h5>',
                showCloseButton: false,
                allowOutsideClick: false,
                showCancelButton: false
              });  
            }else{
              setIsAdultContent(false);
              Swal.fire({
                title: `Great, No adult content detected!`,
                icon: 'success',
                showCloseButton: false,
                allowOutsideClick: false,
                showCancelButton: false
              });  
            }
          }
        }, 
        4000);
      }
    
  }

  const removeImage = () => {
    setIsSelected(false);
    setFileUrl('');
  }

  const tabActiveDeactive = (value) => {
    $('.ctab-heading').removeClass('active');
    $('.tabDetails-item').removeClass('active');

    if (value == "NFT") {
      $('.Ntab-heading').addClass('active');
      $('.NtabDetails-item').addClass('active');
    } else if (value == "FNFT") {
      $('.Ftab-heading').addClass('active');
      $('.FtabDetails-item').addClass('active');
    }
  }

  const processTypeChange = (type) => {

    $('.type-bx').removeClass('active');
    if (type == "gasless") {
      $('.gasless').addClass('active');
    } else if (type == "traditional") {
      $('.traditional').addClass('active');
    }
    setProcessType(type);
  }

  const continueProcess = () => {
    setIsShowCreateFrom(true);

  }

  const backtoProcess = () => {
    setIsShowCreateFrom(false);
    setProcessType("");
  }






  async function mint(tokenMetadataURL, description, images, name, price, tokenId, myacc, tokenIDUUID, marketplaceContractData) {
    
    try {
      console.log(tokenMetadataURL);
      console.log(description);
      console.log(images);
      console.log(name);
      console.log(price);
      console.log(tokenId);
      // const web3 = await getWeb3();
      // let myaccount = await web3.eth.getAccounts();
      // console.log("myaccount", myaccount);
      // const networkId = await web3.eth.net.getId();

      let royality_percentage = royalityPercentage;
      const receipt = await marketplaceContractData.methods
        .CreatemintWithRoyality(tokenMetadataURL, description, images, name, price, tokenId, royality_percentage)
        .send({ from: myacc, gas: 1200000 });
      console.log(receipt);
      console.log(receipt.events.Transfer.returnValues.tokenId);

      var receiptData = {
        tokenId: receipt.events.Transfer.returnValues.tokenId,
        blockhash: receipt.blockHash,
        from: receipt.from,
        to: receipt.to,
        transactionHash: receipt.transactionHash,
        action: "Minted"

      }
      const transactionHistory = await api.post("/nfttransactionhistory", receiptData, {
        headers: {
          "Content-Type": `application/json; boundary=${receiptData._boundary}`,
        },
      });
      console.log("transaction", transactionHistory);
      let data = { tokenId: tokenId };
      const updateStatusAfterMinting = await api.post("/updateStatusAfterMinting", data, {
        headers: {
          "Content-Type": `application/json; boundary=${data._boundary}`,
        },
      });
      console.log("updateStatusAfterMinting", updateStatusAfterMinting);


      setIsload(false);
      Swal.fire({
        title: '<strong>Mint successfully completed!</strong>',
        icon: 'success',
        showCloseButton: false,
        showCancelButton: false
      }).then((result) => {
        history.push('/mynft');
        window.location.reload();
      });
      // window.location.reload();

    } catch (error) {

      // alert("Error while minting!");
      console.log(error);

      Swal.fire({
        title: '<strong>Error while minting</strong>',
        icon: 'warning',
        showCloseButton: false,
        showCancelButton: false
      });

      setIsload(false);
    }
  }

  function changeDescription(data) {
    console.log(data);
  }

  function changeCollectable(event) {

    let isChecked = event.target.checked;
    setIsCollectable(isChecked);
  }
  function openPopertyDiv() {
    $('.properties-div').toggleClass('active');
    $('.toggle-div').toggleClass('active');
  }
  function propertyNameHandeler(event) {
    let { value } = event.target;
    setPropertyName(value);
  }
  function propertyValHandeler(event) {
    let { value } = event.target;
    setPropertyValue(value);
  }
  function addPrpperty() {
    if (propertyName != '' && propertyValue != '') {
      let obj = {};
      obj[propertyName] = propertyValue;
      let newPoperty = { ...propertyList, ...obj };
      setPropertyList(newPoperty);
      console.log(propertyList);
      setPropertyName('');
      setPropertyValue('');
    }
  }



  return (
    <div className={classes.pageCreateNft}>
      {isload ? <Loader /> : <span></span>}
      <div className="container">
        <div className="row">
          <div className="col-md-1"></div>
          <div className="col-md-7">
            <div className="customTab clearfix">
              <div className="customTab-heading">
                <span className="ctab-heading Ntab-heading active" onClick={() => tabActiveDeactive('NFT')}>
                  <img src={successIco} /> NFT
                </span>
                <span className="ctab-heading Ftab-heading" onClick={() => tabActiveDeactive('FNFT')}>
                  <Link to="/make-fnft"><img src={successIco} /> FNFT </Link>
                </span>
              </div>
              <div className="tab-details">
                <div className="tabDetails-item NtabDetails-item active">
                  {!isShowCreateFrom ?
                    <div className="process">
                      <h2>Gasless or Traditional Minting?</h2>
                      <div className="processType">
                        <div className="type">
                          <div className="gasless type-bx" onClick={() => processTypeChange('gasless')}>
                            <svg style={{ margin: "0 auto" }} width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.5 48.5H8.5M41.5 48.5H38.5M8.5 48.5V10C8.5 7.79086 10.2909 6 12.5 6H34.5C36.7091 6 38.5 7.79086 38.5 10V48.5M8.5 48.5H38.5" stroke="white" strokeWidth="2" strokeLinecap="round" />
                              <path d="M14.5 22V15.5C14.5 14.3954 15.3954 13.5 16.5 13.5H30.5C31.6046 13.5 32.5 14.3954 32.5 15.5V22C32.5 23.1046 31.6046 24 30.5 24H16.5C15.3954 24 14.5 23.1046 14.5 22Z" stroke="white" strokeWidth="2" />
                              <path d="M38.5 33C38.5 40.5 49 41.5 49 33C49 27 49 25 49 18.5C49 15.5 48 15.5 43.5 12" stroke="white" strokeWidth="2" strokeLinecap="round" />
                              <line x1="10.0858" y1="45.5858" x2="46.0858" y2="9.58579" stroke="white" strokeWidth="4" />
                              <rect x="2.5" y="2" width="50" height="50" rx="25" stroke="white" strokeWidth="2" />
                            </svg>
                            <h3>Gasless</h3>
                            <p>(No transaction needed)</p>
                          </div>
                        </div>
                        <div className="type">
                          <div className="traditional type-bx" onClick={() => processTypeChange('traditional')}>
                            <svg style={{ margin: "0 auto" }} width="55" height="54" viewBox="0 0 55 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5.5 48.5H8.5M41.5 48.5H38.5M8.5 48.5V10C8.5 7.79086 10.2909 6 12.5 6H34.5C36.7091 6 38.5 7.79086 38.5 10V48.5M8.5 48.5H38.5" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" />
                              <path d="M14.5 22V15.5C14.5 14.3954 15.3954 13.5 16.5 13.5H30.5C31.6046 13.5 32.5 14.3954 32.5 15.5V22C32.5 23.1046 31.6046 24 30.5 24H16.5C15.3954 24 14.5 23.1046 14.5 22Z" stroke="#2D2D2D" strokeWidth="2" />
                              <path d="M38.5 33C38.5 40.5 49 41.5 49 33C49 27 49 25 49 18.5C49 15.5 48 15.5 43.5 12" stroke="#2D2D2D" strokeWidth="2" strokeLinecap="round" />
                            </svg>
                            <h3>Traditional</h3>
                            <p>(Transaction needed)</p>
                          </div>
                        </div>
                      </div>
                      <div className="continue-btn">
                        <button onClick={continueProcess} className="default-btn" disabled={processType == ""} type="button">Continue &#8594;</button>
                      </div>
                    </div>
                    : <span></span>}
                  {isShowCreateFrom ?
                    <form className="form-style-9" onSubmit={createNFTData}>
                      <button onClick={backtoProcess} type="button" className="backto-process"> &#8592; Back to minting option</button>
                      <h3>Create {processType} Nft</h3>
                      <ul>
                        <li>
                          <div className={classes.dropzone}>
                            {/* <DropZone name="NFT image" onFileUploaded={setSelectedFile} /> */}
                            {!isSelected ? (
                              <div>
                                <input
                                  type="file"
                                  onChange={changeHandler}
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  name="file"
                                  id="contained-button-file"
                                />
                                <label htmlFor="contained-button-file" className="button-file">
                                  <span>JPG, JPEG, PNG, GIF, WEBP Max 100mb.</span>
                                  <p className="button-file-upload" style={{ marginBottom: '0', cursor: 'pointer' }}>
                                    Choose File
                                  </p>
                                </label>
                              </div>
                            ) : (
                              <div className="uploaded-image">
                                <span onClick={removeImage} className="cross" >&times;</span>
                                <img style={{ height: '200px', width: '100%' }} src={fileUrl} />
                              </div>
                            )}
                          </div>
                        </li>
                        <li>
                          <input
                            name="title"
                            required
                            value={formData.title}
                            onChange={handleInputChange}
                            type="text" className="field-style" placeholder="Title" />
                        </li>
                        <li>
                          <textarea
                            id="outlined-multiline-static"
                            rows="4"
                            label="Description"
                            name="description"
                            required
                            value={formData.description}
                            onChange={handleInputChange} className="field-style" placeholder="Description"></textarea>
                        </li>
                        <li>
                          <div className="input-group mb-3">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="basic-addon1">ETH</span>
                            </div>
                            <input label="price" name="price" value={formData.price} onChange={handleInputChange} type="number" className="field-style priceinput" placeholder="Price" aria-label="Price" aria-describedby="basic-addon1" />
                          </div>

                        </li>
                        {/* <li>
                        <label className="labelClass">Description</label>
                        <Editor editorClassName="editor-class" editorState={editorState} onEditorStateChange={handleEditorChange} />
                      </li> */}

                        <li>
                          {/* <label className="labelClass">Category</label> */}
                          {/* <select
                        className="field-style"
                        name="categoryID"
                        onChange={handleChangeCategory}
                      >
                        <option value="0">Select Category</option>

                        {Object.keys(allCatagories).map((key) => (
                          <option key={'cat' + key} value={allCatagories[key].categoryID}>{allCatagories[key].category}</option>
                        ))}
                      </select> */}
                          <Select
                            options={allCatagories}
                            onChange={handleChangeCategory}
                            placeholder="Select Category"
                          />

                        </li>

                        <li>
                          {/* <label className="collectableCheck">
                        <input
                          name="collectable"
                          type="checkbox"
                          onChange={changeCollectable} /> Is collectable?
                      </label>
                      { isCollectable ? (
                        
                      ):("")} */}
                          <Select
                            options={myAllCollection}
                            onChange={handleChangeCollection}
                            placeholder="Select Collection"
                          />
                          {/* <select
                          className="field-style"
                          name="categoryID"
                          onChange={handleChangeCollection}
                        >
                          <option value="0">Select Collection</option>

                          {Object.keys(myAllCollection).map((key) => (
                            <option key={'collec-' + key} value={myAllCollection[key].slag}>{myAllCollection[key].collectionName}</option>
                          ))}
                        </select> */}
                        </li>
                        <li>
                          <div className="details-toggle">
                            <div className="toggle-div" onClick={openPopertyDiv}>
                              Properties
                              <div className="opener">
                                <span></span>
                                <span></span>
                                <span></span>
                              </div>
                            </div>
                            <div className="properties-div">
                              <div className="properties-list clearfix">
                                {Object.keys(propertyList).map((key) => (
                                  <div key={'poperty-' + key} className="properties-li-item">
                                    <div className="properties-li-item-inner">
                                      <span>{key}</span>
                                      <p>{propertyList[key]}</p>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <input type="text" onChange={propertyNameHandeler} value={propertyName} name="propertyname" placeholder="Name" className="property-name" />
                              <input style={{ margin: '0 5px' }} type="text" onChange={propertyValHandeler} value={propertyValue} name="propertyValue" placeholder="Value" className="property-name" />
                              <button type="button" className="btn" onClick={addPrpperty}>+ Add Property</button>
                            </div>
                          </div>
                        </li>
                        <li style={{ paddingTop: '10px' }}>
                          <label style={{ color: '#909090' }}>Royalty</label>
                          <div style={{ padding: '0 6px' }}>
                            <Slider
                              aria-label="Temperature"
                              defaultValue={0}
                              valueLabelDisplay="auto"
                              marks
                              step={1}
                              min={0}
                              max={10}
                              color="secondary"
                              onChange={handleRoyalityChange}
                            />

                            <div className="clearfix">
                              <span className="minVal">0%</span>
                              <span className="maxVal">10%</span>
                            </div>
                            {royalityPercentage > 0 ? (
                              <div className="royality-percentage" style={{ maxWidth: '170px', margin: '0 auto' }}>
                                <div className="alert alert-success" style={{ textAlign: 'center' }}>
                                  Selected Royality <br />
                                  <strong style={{ fontSize: '20px' }}>{royalityPercentage} %</strong>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>

                        </li>
                        <li>
                            {!isAdultContent ? (
                              <button className="default-btn" type="submit">Submit</button>
                            ) : (
                              ''
                            )}
                          
                        </li>
                      </ul>
                    </form>
                    : <span></span>}
                </div>
                {/* <div className="tabDetails-item FtabDetails-item">
                  <form className="form-style-9" onSubmit={createNFTData}>
                    <h3>Create Fractional NFT</h3>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="previewSec">
              <h3>Preview NFT</h3>
              <PreViewNft owner={account} image={fileUrl} name={formData.title} price={formData.price}></PreViewNft>
            </div>
          </div>
          <div className="col-md-1"></div>
        </div>
      </div>
    </div>
  );
};

export default CreateNFT;
