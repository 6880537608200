import React from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";

import { Card as MuiCard } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import SvgIcon from "@material-ui/core/SvgIcon";
import Divider from "@material-ui/core/Divider";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { useDispatch, useSelector } from "react-redux";
import {imgBaseUrl } from "../../services/api";

import { useStyles } from "./styles.js";
import { ReactComponent as EthereumLogo } from "../../assets/ethereum_logo.svg";

const Card = ({ tokenId, name, image, price, owner, isForSale, imagelist, tokenKey, imagelistLocal }) => {
  const classes = useStyles();
  let usdRate = useSelector((state) => state.allNft.usdRate);

  let more;
  if(name.length > 14){
    more = '...';
  }
  
  return (
    <Link className={classes.linkClass} to={`/nft/${tokenKey}`}>
      <MuiCard className={classes.root}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={name}
            height="240"
            image={imagelistLocal?.image_3}
            title={name}
            style={{height: '200px'}}
          />
          <CardContent className={classes.content}>
            <div className={classes.title}>
              <Typography
                className={"MuiTypography--heading"}
                variant={"h5"}
                gutterBottom
              >
                {name.slice(0, 14)} <span>{more}</span>
              </Typography>
              {isForSale ? (
                <Chip
                  size="small"
                  disabled={true}
                  label="Selling"
                  className={classes.badge}
                />
              ) : (
                <span></span>
              )}
              
            </div>
            <Typography variant="h6" className={classes.price}>
              <SvgIcon
                component={EthereumLogo}
                viewBox="0 0 400 426.6"
                titleAccess="ETH"
              />
              <span> {Web3.utils.fromWei(String(price), "ether")} </span>
              {/* <span>{Web3.utils.fromWei(String(price), "ether")}</span> */}
            </Typography>
            <small style={{display: 'block', fontSize:'12px', marginTop:'5px'}}> {'$'+ (Web3.utils.fromWei(String(price), "ether") * usdRate).toFixed(3)}</small>
            
          </CardContent>
        </CardActionArea>
      </MuiCard>
    </Link>
  );
};

export default Card;
