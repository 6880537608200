import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from '@material-ui/core/Button';

import getWeb3 from "../../utils/getWeb3";
import { api } from "../../services/api";

import ArtMarketplace from "../../contracts/ArtMarketplace.json";
import Loader from "../../components/Loader/Loader";
import SimpleSlider from "../../components/Slider/Slider";
import {
  setNft,
  setAccount,
  setTokenContract,
  setMarketContract,
} from "../../redux/actions/nftActions";
import Card from "../../components/Card";
import NftCard from "../../components/NftCard";
import FNftCard from "../../components/FNftCard";
import LatestNft from "../../components/LatestNft/LatestNft";
import Featured from "../../components/Featured/index";
import TopSellingNfts from "../../components/TopSellingNfts/index";

import { useStyles } from "./styles.js";
import { Styles } from "./styles.css";
import authorShape2 from "../../assets/explore/author-shape2.png";
import Bgshape from "../../components/BGShape/index";
import Bgshape2 from "../../components/BGShape2/index"

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

// import veterans from "../../assets/arts/Sparse-Ahmed-Mostafa-vetarans-2.jpg";
// import lionKing from "../../assets/arts/suresh-pydikondala-lion.jpg";
// import dreaming from "../../assets/arts/phuongvp-maybe-i-m-dreaming-by-pvpgk-deggyli.jpg";
// import modeling3d from "../../assets/arts/alan-linssen-alanlinssen-kitbashkitrender2.jpg";
// import woman from "../../assets/arts/ashline-sketch-brushes-3-2.jpg";
// import stones from "../../assets/arts/rentao_-22-10-.jpg";
// import wale from "../../assets/arts/luzhan-liu-1-1500.jpg";
// import comic from "../../assets/arts/daniel-taylor-black-and-white-2019-2.jpg";
// import galerie from "../../assets/galerie.svg";
import loaderPlaceholder from "../../assets/placeholder-loading.gif";
import exploIco from "../../assets/3.png";

import AllCollection from "../../components/AllCollections/AllCollection";



const Home = () => {
  const account = useSelector((state) => state.allNft.account);
  let nftItem = useSelector((state) => state.allNft.nft);
  let fnftItem = useSelector((state) => state.allNft.fnft);
  
  const classes = useStyles();
  const [howManyLoad, sethowManyLoad] = useState(6);
  let isLoadMoreBtnShow = true;
  
  let latestNft = nftItem.sort(function(a, b){return b.tokenId - a.tokenId;});

  let allNft = nftItem.slice(0, howManyLoad);
  if(nftItem.length <= howManyLoad){
    isLoadMoreBtnShow = false;
  }

  async function loadMoreNft(){
    if(nftItem.reverse().length > howManyLoad){
      sethowManyLoad(howManyLoad + 8);
    }
  }

  console.log("nftItem");
  console.log(nftItem);

  

  return (
    
    <div className={classes.homepage}>
      
      {Object.keys(nftItem).length === 0 ? 
      <div className="clearfix image-loader" style={{textAlign:'center', margin: '30px'}}>
        <img style={{marginBottom:'10px'}} src={loaderPlaceholder} />
        <img style={{marginBottom:'10px'}} src={loaderPlaceholder} />
      </div>
      : <span></span> }
      
      {/* {Object.keys(nftItem).length > 0 ? ( */}
        <div>
          {Object.keys(nftItem).length > 0 ? (
            <div className="row">
              <div className="col-md-5">
                <Featured latestNft={nftItem}></Featured>
              </div>
              <div className="col-md-7">
                <TopSellingNfts latestNft={nftItem}></TopSellingNfts>
              </div>
            </div>
          ):(
            <span></span>
          )}
          
          {Object.keys(nftItem).length > 0 ? (
            <SimpleSlider nftData={nftItem}></SimpleSlider>
          ):(
            <span></span>
          )}
          {Object.keys(latestNft).length > 0 ? (
            <div className="latestNftData"> 
              <LatestNft latestNft={latestNft} title="Latest NFT" coloumn="col-md-3"></LatestNft>
              <Bgshape/>
            </div>
          ):(
            <span></span>
          )}
        </div>
        <section className="latest-collection">
          <div className="container" style={{marginBottom: "20px"}}>
            <div className="row">
                <div className="col-12">
                  <div className="section-title" style={{marginLeft: '10px'}}>
                    <div className="title-img">
                        <img src={exploIco} />
                    </div>
                    <div className="title" >
                        <h2> Collections</h2>
                    </div>
                </div>
                </div>
            </div>
          </div>
          <AllCollection howManyLoad={6}></AllCollection>
        </section>
      {/* ):(
        <span></span>
      )} */}
      {/* {Object.keys(nftItem).length > 0 ? ( */}
         <section className="explore-section">
           <div className="overlay"></div>
           <div style={{position:"relative", zIndex: "10"}}>
              <div className="container">
                  <div className="row">
                      <div className="col-12">
                        <div className="section-title" style={{marginLeft: '10px'}}>
                          <div className="title-img">
                              <img src={exploIco} />
                          </div>
                          <div className="title" >
                              <h2> EXPLORE MORE</h2>
                          </div>
                      </div>
                      </div>
                  </div>
                  <Tabs>
                        <TabList>
                          {Object.keys(allNft).length > 0 ? (
                            <Tab>NFT</Tab>
                          ):("")}
                            {Object.keys(fnftItem).length > 0 ? (
                            <Tab>FNFT</Tab>
                          ):("")}
                        </TabList>
                        {Object.keys(allNft).length > 0 ? (
                          <TabPanel>
                            <div className="row explore-content-section">
                            
                              {allNft.map((nft) => (
                                  <NftCard item key={nft.tokenId} {...nft}></NftCard>
                              ))}

                              {isLoadMoreBtnShow ? (
                                <div className="col-lg-12" style={{ textAlign:'center' }}>
                                  <span className="default-btn lead m-auto" onClick={loadMoreNft}>Load More</span>
                                </div>
                              ):(
                                <span></span>
                              )}
                              
                            </div>
                          </TabPanel>
                        ):("")}

                        {Object.keys(fnftItem).length > 0 ? (
                          <TabPanel>
                            <div className="row explore-content-section">
                              
                              {fnftItem.map((nft) => (
                                  <FNftCard item key={nft.tokenId} {...nft}></FNftCard>
                              ))}

                              {isLoadMoreBtnShow ? (
                                <div className="col-lg-12" style={{ textAlign:'center' }}>
                                  <span className="default-btn lead m-auto" onClick={loadMoreNft}>Load More</span>
                                </div>
                              ):(
                                <span></span>
                              )}
                              
                            </div>
                          </TabPanel>
                          ):("")}
                      </Tabs>
                    
              </div>
              <div className="author-area-shape-two">
                <img src={authorShape2} alt="Images" />
              </div>
              <Bgshape/>
            </div>
          </section>
       
        {/* ):(
          <span></span>
        )} */}
    </div>
  );
};

export default Home;


